/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import {collection, doc, onSnapshot, updateDoc, writeBatch} from 'firebase/firestore'
import ReactPaginate from 'react-paginate'
import './pagination.css'
import {Helmet} from 'react-helmet'
import {ViewTests} from './components/ViewTests'
import {TestListLoading} from './components/loading/TestListLoading'
import {useAuth} from '../../modules/auth'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import {TestListToolbar} from './components/header/TestListToolbar'
import {Overlay, Popover} from 'react-bootstrap'

type Props = {
  className: string
  tests: any[]
}

const TestManagement: React.FC<Props> = ({tests}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [testID, setTestID] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [searchLength, setSearchLength] = useState(false)
  const {currentUser} = useAuth()
  const [selectedNumber, setSelectedNumber] = useState('50')
  let ITEMS_PER_PAGE = parseInt(selectedNumber)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [show1, setShow1] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const overlayRef = useRef<HTMLDivElement>(null)
  const [personnels, setPersonnels] = useState<any[]>([])
  const target = useRef(null)

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    if (activeFrameworks.length > 0) {
      const filteredTests = tests.map(updateTestStatus).filter((test) => {
        const testFrameworks = test.framework.split(',').map((fw) => fw.trim())

        return activeFrameworks.some((activeFramework) =>
          testFrameworks.some((testFramework) => testFramework === activeFramework)
        )
      })

      setFilteredItems(filteredTests)
    } else {
      setFilteredItems(tests.map(updateTestStatus))
    }
  }, [tests, activeFrameworks])

  useEffect(() => {
    if (id) {
      handleOpenModal(id)
    }
  }, [id])

  // useEffect(() => {
  //   setFilteredItems(tests)
  // }, [tests])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node) && show1) {
        setShow1(false)
      }
    }

    if (show1) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [show1])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  const updateTestStatus = (test: any) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (test.next_review_date) {
      const reviewDate = new Date(test.next_review_date)
      reviewDate.setHours(0, 0, 0, 0)

      if (reviewDate <= today && test.status !== 'Fail') {
        const tenantId = currentUser?.tenantId || ''
        if (tenantId) {
          const tenantRef = doc(db, 'tenants', tenantId)
          const testRef = doc(tenantRef, 'tests', test.id)
          updateDoc(testRef, {status: 'Fail'})
        }
        return {...test, status: 'Fail'}
      }
    }
    return test
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  // useEffect(() => {
  //   const tenantId = currentUser?.tenantId || ''
  //   if (!tenantId) return

  //   const tenantRef = doc(db, 'tenants', tenantId)
  //   const testsCollectionRef = collection(tenantRef, 'active_frameworks')

  //   const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
  //     const frameworksData = snapshot.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //       frameworks: doc.data().frameworks,
  //     }))
  //     const frameworks = frameworksData[0]?.frameworks || []
  //     setActiveFrameworks(frameworks)
  //   })

  //   return () => unsubscribe()
  // }, [currentUser])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (searchTerm === '') {
      setSearchLength(false)
    } else {
      const filteredItems = tests.filter((user) => user.name.toLowerCase().includes(searchTerm))
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const pageCount = Math.ceil(tests.length / ITEMS_PER_PAGE)

  const handlePageClick = ({selected}: {selected: number}) => {
    setCurrentPage(selected)
  }

  const offset = currentPage * ITEMS_PER_PAGE

  const paginatedData = filteredItems
    .slice()
    .sort((a, b) => {
      if (!a || !b || !a.name || !b.name) return 0
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    .slice(offset, offset + ITEMS_PER_PAGE)

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    setTestID(id)
  }

  const handleCloseModal = () => {
    console.log('Close Modal')
    setIsModalOpen(false)
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked
    setSelectAll(isChecked)
    setCheckedItems(isChecked ? tests.map((item) => item.id) : [])
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => {
      const newCheckedItems = checked ? [...prev, value] : prev.filter((item) => item !== value)

      // Update selectAll state based on whether all items are checked
      setSelectAll(newCheckedItems.length === tests.length)

      return newCheckedItems
    })
  }

  const handleAssignOwner = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!selectedOwner) {
      return
    }

    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) return

    const tenantRef = doc(db, 'tenants', tenantId)
    const batch = writeBatch(db)

    for (const testId of checkedItems) {
      const testRef = doc(tenantRef, 'tests', testId)
      batch.update(testRef, {owner: selectedOwner})
    }

    try {
      await batch.commit()
      // alert(`Successfully assigned ${selectedOwner} to ${checkedItems.length} test(s)`)
      Swal.fire({
        icon: 'success',
        title: 'Updated owner',
        text: ` "Successfully assigned ${selectedOwner} to ${checkedItems.length} test(s)`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
      setShow1(false)
      setCheckedItems([])
      setSelectAll(false)
      setSelectedOwner('')
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to assign owner. Please try again.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  function formatDate(dateString) {
    if (!dateString) return '--'

    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.toLocaleString('default', {month: 'long'})
    const year = date.getFullYear()

    return `${day} ${month}, ${year}`
  }

  const handleResetClick = () => {
    setSelectedOwner('')
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Tests</title>
      </Helmet>

      <div className='card-header border-0 pt-6 h-100 mb-1'>
        <div className='card-title d-flex justify-content-between align-items-center'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-3' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-12'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='card-toolbar'>
          {checkedItems.length > 0 ? (
            <>
              {checkedItems.length > 0 ? (
                <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                  <span>
                    <p className='pt-4'>{checkedItems.length} Selected</p>
                  </span>
                </div>
              ) : null}
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                  <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                </div>

                <div className='mb-1' style={{cursor: 'pointer'}}>
                  <button
                    className='btn btn-sm fs-6'
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    ref={target}
                    onClick={() => setShow1(!show1)}
                    style={{
                      backgroundColor: '#1CBB8D',
                      color: '#fff',

                      height: '40px',
                      borderRadius: '4px',
                      left: '615px',
                      top: '20px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    {' '}
                    Assign Owner
                  </button>
                </div>
                <Overlay
                  show={show1}
                  target={target.current}
                  placement='bottom'
                  container={document.body}
                  containerPadding={10}
                >
                  {(props) => (
                    <Popover id='popover-contained' {...props}>
                      <div ref={overlayRef as React.RefObject<HTMLDivElement>}>
                        {/* <Popover.Header as='h3' className='fw-bold fs-5'>
                      Assign Owner
                    </Popover.Header> */}
                        <Popover.Body onClick={(e) => e.stopPropagation()}>
                          <form onSubmit={handleAssignOwner}>
                            <div className='mb-3'>
                              {/* <label htmlFor='owner'>Owner</label> */}
                              <div className='mb-3 d-flex align-items-center'>
                                <select
                                  id='owner'
                                  className='form-select form-control'
                                  aria-label='Select Owner'
                                  value={selectedOwner}
                                  onChange={(e) => setSelectedOwner(e.target.value)}
                                >
                                  <option value='' disabled selected>
                                    Select Owner
                                  </option>
                                  {personnels
                                    .filter((user) => user.role === 'Admin')
                                    .map((personnel) => (
                                      <option key={personnel.id} value={personnel.full_name}>
                                        {personnel.full_name}
                                      </option>
                                    ))}
                                </select>
                                {selectedOwner && (
                                  <button
                                    className='btn btn-link p-0'
                                    onClick={() => setSelectedOwner('')}
                                  >
                                    <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                                      &times;
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                            <button
                              type='button'
                              onClick={handleResetClick}
                              className='btn btn-sm fs-6'
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#f1fdf9')
                              }
                              style={{
                                backgroundColor: '#f1fdf9',
                                color: '#45dbaf',
                                width: '80px',
                                // height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                                marginRight: '12px',
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type='submit'
                              className='btn btn-sm fs-6'
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                width: '80px',
                                // height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              Apply
                            </button>
                          </form>
                        </Popover.Body>
                      </div>
                    </Popover>
                  )}
                </Overlay>
              </div>
            </>
          ) : (
            <>
              <TestListToolbar />
            </>
          )}
        </div>

        {/* end::Group actions */}
      </div>

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>

                <th className='min-w-300px'>NAME</th>
                <th className='min-w-50px'>OWNER</th>
                {/* <th className='min-w-100px'>STATUS</th> */}
                <th className='min-w-100px'>TYPE</th>
                <th className='min-w-100px'>CATEGORY</th>
                <th className='min-w-100px'>NEXT REVIEW</th>
                <th className='min-w-50px'>TEST INTERVAL</th>

                <th className='min-w-50px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <ViewTests isOpen={isModalOpen} onClose={handleCloseModal} id={testID} />

            {paginatedData && paginatedData.length > 0 ? (
              paginatedData.map((testsData) => {
                return (
                  <tbody>
                    <tr key={testsData.id}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value={testsData.id}
                            checked={selectAll || checkedItems.includes(testsData.id)}
                            onChange={handleCheckChange}
                          />
                        </div>
                      </td>

                      <td>
                        {/* <a
                          // key={index}
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block fs-6'
                          onClick={() => handleOpenModal(testsData.id)}
                        >
                          {testsData.name}
                        </a> */}
                        <div className='d-flex flex-column'>
                          <a
                            href='#'
                            onClick={() => handleOpenModal(testsData.id)}
                            className='text-dark fw-bold text-hover-primary d-block fs-6 mb-1'
                          >
                            {' '}
                            {testsData.name}
                          </a>
                          <span>
                            <a
                              href='#'
                              className='badge fs-7'
                              style={{
                                color: testsData.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                                background: testsData.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                              }}
                            >
                              {' '}
                              {testsData.status === 'Pass' ? 'Passing' : 'Failing'}
                            </a>

                            <span className='fs-7 fw-semibold mx-2'>
                              {testsData.framework
                                .split(', ')
                                .filter((framework) =>
                                  activeFrameworks.some((item) => framework.startsWith(item))
                                )
                                .map((framework, index, array) => (
                                  <React.Fragment key={index}>
                                    {framework}
                                    {index < array.length - 1 && ', '}
                                  </React.Fragment>
                                ))}
                            </span>

                            {/* {showAllFrameworks && activeFrameworks.length > 1 && (
                              <>
                                {activeFrameworks.slice(1).map((framework, index) => (
                                  <>
                                
                                    {'.'}{' '}
                                    <span key={index} className='mx-1 fs-7 fw-semibold'>
                                   
                                      {framework.startsWith('ISO')
                                        ? 'ISO 27001:2022'
                                        : framework.startsWith('SOC')
                                        ? 'SOC 2'
                                        : framework}
                                    </span>
                                  
                                  </>
                                ))}
                              </>
                            )} */}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {testsData.owner ? (
                            <>
                              <div className='symbol symbol-35px me-5'>
                                {testsData.owner ? (
                                  avatarUrl ? (
                                    <img alt='logo' loading='lazy' src={avatarUrl} />
                                  ) : (
                                    <div className='symbol symbol-25px'>
                                      <span
                                        className='symbol-label fs-7 fw-bold'
                                        style={{backgroundColor: '#DAF9EF'}}
                                      >
                                        {testsData.owner
                                          ? (() => {
                                              const nameParts = testsData.owner.split(' ')
                                              if (nameParts.length > 1) {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[1]
                                                  .charAt(0)
                                                  .toUpperCase()}`
                                              } else {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[0]
                                                  .charAt(1)
                                                  .toUpperCase()}`
                                              }
                                            })()
                                          : ''}
                                      </span>
                                    </div>
                                  )
                                ) : (
                                  <>-</>
                                )}
                              </div>
                              {/* <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                  {testsData.owner}
                                </a>
                              </div> */}
                            </>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </td>
                      {/* <td>
                        <span
                          className='badge px-2 rounded-pill'
                          style={{border: '2px solid #E1E1E1'}}
                        >
                          <span
                            className={`badge border rounded-circle mx-1 ${
                              testsData.status === 'Pass' ? 'bg-primary' : 'bg-danger'
                            }`}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '3px',
                            }}
                          >
                            {' '}
                          </span>
                          <span className='text-center mx-1'>
                            {testsData.status === 'Pass' ? 'OK' : 'Failing'}
                          </span>
                        </span>
                      </td> */}

                      <td>
                        <a
                          href='#'
                          className='badge fs-7 text-dark text-bold m-1'
                          style={{background: '#F6F6F6'}}
                        >
                          {testsData.type}
                        </a>
                      </td>

                      <td>
                        <div className='fs-6 fw-semibold'>{testsData.category}</div>
                      </td>

                      {/* <td>
                        <div className='d-flex flex-stack '>
                          <span
                            className='badge px-3 rounded-pill'
                            style={{border: '2px solid #E1E1E1'}}
                          >
                            <span className='ml-5 fs-7'>
                              {testsData.type === 'Document' ? (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='14'
                                  height='14'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#5A5A5A'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-paperclip '
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5' />
                                </svg>
                              ) : (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='14'
                                  height='14'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#5A5A5A'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-brand-zapier '
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M3 12h6' />
                                  <path d='M21 12h-6' />
                                  <path d='M12 3v6' />
                                  <path d='M12 15v6' />
                                  <path d='M5.636 5.636l4.243 4.243' />
                                  <path d='M18.364 18.364l-4.243 -4.243' />
                                  <path d='M18.364 5.636l-4.243 4.243' />
                                  <path d='M9.879 14.121l-4.243 4.243' />
                                </svg>
                              )}{' '}
                              <span className='fs-7 fw-semibold'>{testsData.type}</span>
                            </span>
                          </span>
                        </div>
                      </td> */}

                      {/* <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack'>
                            <span className='fs-7 fw-semibold'>
                              {activeFrameworks.length > 0 && (
                                <span
                                  className='badge px-3 rounded-pill mb-1'
                                  style={{border: '2px solid #E1E1E1'}}
                                >
                                  {activeFrameworks[0].startsWith('ISO')
                                    ? 'ISO 27001:2022'
                                    : activeFrameworks[0].startsWith('SOC')
                                    ? 'SOC 2'
                                    : activeFrameworks[0]}
                                </span>
                              )}
                              {activeFrameworks.length > 1 && !showAllFrameworks && (
                                <button
                                  className='btn btn-link p-0'
                                  onClick={() => setShowAllFrameworks(true)}
                                >
                                  <span
                                  className='badge px-3 rounded-pill'
                                  style={{border: '2px solid #E1E1E1'}}
                                >
                                    +{activeFrameworks.length - 1}
                                  </span>
                                </button>
                              )}
                            </span>
                          </div>
                          {showAllFrameworks && activeFrameworks.length > 1 && (
                            <>
                              {activeFrameworks.slice(1).map((framework, index) => (
                                <div key={index} className='d-flex flex-stack mb-2'>
                                  <span className='me-2 fs-7 fw-semibold'>
                                  <span
                                  className='badge px-3 rounded-pill'
                                  style={{border: '2px solid #E1E1E1'}}
                                >
                                      {framework.startsWith('ISO')
                                        ? 'ISO 27001:2022'
                                        : framework.startsWith('SOC')
                                        ? 'SOC 2'
                                        : framework}
                                    </span>
                                  </span>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </td> */}
                      <td>
                        {' '}
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2 fw-semibold fs-6'>
                            {testsData.next_review_date ? (
                              formatDate(testsData.next_review_date)
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <a href='#' className='text-dark fw-semibold ml-5 fs-6'>
                              {testsData.timeframe ? testsData.timeframe : '--'}
                            </a>
                          </div>
                        </div>
                      </td>
                      {/* <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <a href='#' className='text-dark fw-bold text-hover-primary ml-5 fs-6'>
                              {formatTimestamp(testsData.last_updated)}
                            </a>
                          </div>
                        </div>
                      </td> */}

                      {/* <td>
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={(e) => getAssetID(testsData.id)}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_edit_tests'
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </button>
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={(e) => deleteHandler(testsData.id)}
                        >
                          <KTIcon iconName='trash' className='fs-3' />
                        </button>
                      </td> */}

                      <td className=''>
                        <div className='dropdown mx-2'>
                          <button
                            className='btn btn-secondary border-0 p-0 m-2'
                            type='button'
                            id='dropdownMenu2'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <img
                              src='/media/buttons/Options.svg'
                              alt='Options'
                              onClick={toggleDropdown}
                            />
                          </button>
                          <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                            <li>
                              <button
                                className='dropdown-item text-muted text-hover-primary '
                                type='button'
                                onClick={() => handleOpenModal(testsData.id)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                  <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                </svg>{' '}
                                View
                              </button>
                            </li>

                            <li>
                              <button
                                className='dropdown-item text-muted text-hover-primary'
                                type='button'
                                // onClick={handleOffboard(item)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                  <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                  <path d='M16 5l3 3' />
                                </svg>{' '}
                                Edit
                              </button>
                            </li>

                            {/* <li>
                              <button
                                className='dropdown-item'
                                type='button'
                                style={{color: '#F15363'}}
                                onClick={(e) => handleDeleteClick(item)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#F15363'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M4 7l16 0' />
                                  <path d='M10 11l0 6' />
                                  <path d='M14 11l0 6' />
                                  <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                  <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                </svg>{' '}
                                Remove
                              </button>
                            </li> */}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )
              })
            ) : (
              <>
                <tbody>
                  <tr>
                    {searchLength ? (
                      <td colSpan={10} className='text-center'>
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No tests found</p>
                          </div>
                          <p className=''>
                            We couldn't find any tests that match your search. Maybe try a different
                            search!
                          </p>
                        </div>
                      </td>
                    ) : (
                      <td colSpan={10} className='text-center'>
                        {!showEmptyState ? (
                          // <td colSpan={10}>
                          <div
                            className='d-flex justify-content-center align-items-center text-center'
                            style={{height: '500px'}}
                          >
                            <TestListLoading />
                          </div>
                        ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className=' fs-5'>
                              <p style={{fontWeight: 'bold'}}>No filter results</p>
                            </div>
                            <p>Maybe try a different filter or reset filters</p>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                </tbody>
              </>
            )}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {tests.length >= 15 && (
        <div className='d-flex justify-content-between'>
          <div
            id=''
            className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
          >
            <div>
              <select
                name='kt_permissions_table_length'
                aria-controls='kt_permissions_table'
                className='form-select  mx-8'
                id='dt-length-1'
                value={selectedNumber}
                onChange={(e) => setSelectedNumber(e.target.value)}
              >
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
              {/* <label for='dt-length-1'></label> */}
            </div>
          </div>

          <div className='d-flex justify-content-end my-10'>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      )}
    </>
  )
}

export {TestManagement}
