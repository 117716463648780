/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {addDoc, collection, doc, getDocs, onSnapshot, serverTimestamp} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {RiskPageTitle} from '../../../../../_metronic/layout/core/RiskPageData'
import {useAuth} from '../../../../modules/auth'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {}

interface RiskTemplate {
  id: string
  riskSource: string
  category: string[]
  CIA: string[]
  recommendedControls: string[]
  // Add other properties as needed
}

const RiskLibrary: React.FC<Props> = () => {
  const {currentUser} = useAuth()
  const [riskTemplates, setRiskTemplates] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('library')
  const location = useLocation()
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const {id} = useParams()
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [totalFilterSelected, setTotalFilterSelected] = useState(0)
  const overlayRef = useRef<HTMLElement>(null)
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const navigate = useNavigate()
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [tenantRisks, setTenantRisks] = useState<any[]>([])
  const [loadingStates, setLoadingStates] = useState<{[key: string]: boolean}>({})

  console.log(tenantRisks)

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('library')) {
      setActiveTab('library')
    } else {
      setActiveTab('risk-management')
    }
  }, [id])

  useEffect(() => {
    getPolicy()
    fetchTenantRisks()
  }, [])

  useEffect(() => {
    setFilteredItems(riskTemplates)
  }, [riskTemplates])

  useEffect(() => {
    if (!isFiltered) {
      setFilteredItems(riskTemplates)
    }
  }, [riskTemplates, isFiltered])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  const getPolicy = () => {
    try {
      const collectionRef = collection(db, 'risk_templates')

      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          const policies = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))

          setRiskTemplates(policies)
          setLoading(false) // Set loading to false when data is received
        },
        (error) => {
          console.error('Error getting policies:', error)
          setLoading(false) // Set loading to false in case of error
        }
      )

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error setting up policy listener:', error)
      setLoading(false)
    }
  }

  const addRiskToTenant = async (risk: any) => {
    try {
      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const policyRef = collection(tenantRef, 'risks')

      const docRef = await addDoc(policyRef, {
        ...risk,
        inherent_impact: 1,
        inherent_likelihood: 1,
        residual_impact: 1,
        residual_likelihood: 1,
        owner: '',
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        description: '',
        risk_status: 'Incomplete',
      })

      console.log('Risk added successfully!', risk)
      return docRef.id
    } catch (error) {
      console.error('Error adding risk:', error)
      return null
    }
  }

  const handleAddRisk = async (risk: any) => {
    setLoadingStates((prev) => ({...prev, [risk.id]: true}))
    const newRiskId = await addRiskToTenant(risk)
    setLoadingStates((prev) => ({...prev, [risk.id]: false}))
    if (newRiskId) {
      navigate(`/risk-management/${newRiskId}`, {
        state: {
          showToast: true,
          toastMessage: `Successfully added a risk.`,
        },
      })
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    let itemsToFilter = riskTemplates

    if (searchTerm === '') {
      setFilteredItems(itemsToFilter)
      setSearchLength(false)
    } else {
      const filtered = itemsToFilter.filter((risk) =>
        risk.riskSource.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filtered)
      setSearchLength(filtered.length === 0)
    }
  }
  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleClick = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const fetchTenantRisks = async () => {
    try {
      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const risksSnapshot = await getDocs(collection(tenantRef, 'risks'))
      const risks = risksSnapshot.docs.map((doc) => doc.data())
      setTenantRisks(risks)
    } catch (error) {
      console.error('Error fetching tenant risks:', error)
    }
  }

  // Add this new function
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node) && show) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [show])

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked)
    if (e.target.checked) {
      setCheckedItems(
        filteredItems
          // .filter((user) => user.tenantId === currentUser?.tenantId)
          .map((item) => item.id)
      )
    } else {
      setCheckedItems([])
    }
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)))
  }

  const addMultipleRisksToTenant = async (risks: any[]) => {
    const addedRiskIds: string[] = []

    for (const risk of risks) {
      try {
        const newRiskId = await addRiskToTenant(risk)
        if (newRiskId) {
          addedRiskIds.push(newRiskId)
        }
      } catch (error) {
        console.error('Error adding risk:', error)
      }
    }

    return addedRiskIds
  }

  const handleAddSelectedRisks = async () => {
    const selectedRisks = filteredItems.filter((risk) => checkedItems.includes(risk.id))
    const addedRiskIds = await addMultipleRisksToTenant(selectedRisks)

    if (addedRiskIds.length > 0) {
      // Clear the selection
      setCheckedItems([])
      setSelectAll(false)

      navigate('/risk-management', {
        state: {
          showToast: true,
          toastMessage: `Successfully added ${addedRiskIds.length} risks.`,
        },
      })
    }
  }

  // const handleApplyFilter = (e: React.FormEvent) => {
  //   e.preventDefault()
  //   if (selectedCategory) {
  //     const filtered = riskTemplates.filter((risk: RiskTemplate) =>
  //       risk.category.includes(selectedCategory)
  //     )
  //     setFilteredItems(filtered)
  //     setIsFiltered(true)
  //     setShow(false)
  //     setTotalFilterSelected(1)
  //   }
  // }

  const handleResetFilter = () => {
    setSelectedCategory('All')
    setFilteredItems(riskTemplates)
    setIsFiltered(false)
    setShow(false)
    setTotalFilterSelected(0)
  }

  const handleApplyFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value
    if (category === 'All') {
      handleResetFilter()
    } else {
      const filtered = riskTemplates.filter((risk: RiskTemplate) =>
        risk.category.includes(category)
      )
      setFilteredItems(filtered)
      setIsFiltered(true)
      setShow(false)
      setTotalFilterSelected(1)
    }
  }

  return (
    <>
      <RiskPageTitle
        icon='personnelIcon.svg'
        title='Risk Management'
        desc={'Identify, assess, and mitigate risks to ensure compliance'}
        // breadcrumbs={usersBreadcrumbs}
      >
        Risk Management
      </RiskPageTitle>

      <div className='card card-custom '>
        <div className='card-header border-0 pt-6 h-100'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control  w-250px ps-14'
                placeholder='Search..'
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>

            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {checkedItems.length > 0 ? (
              <>
                {checkedItems.length > 0 ? (
                  <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                    <span>
                      <p className='pt-4'>{checkedItems.length} Selected</p>
                    </span>
                  </div>
                ) : null}
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                    <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                  </div>
                  {/* <div className='mb-1' style={{cursor: 'pointer'}}>
                  <img
                    src='/media/buttons/AssignOwner.svg'
                    alt='Assign Owner'
                    style={{width: '145px'}}
                    // onClick={handleAssignClick}
                    ref={targett}
                    onClick={() => setShow1(!show1)}
                  />
                </div> */}

                  <button
                    className='btn btn-sm fs-6'
                    onClick={handleAddSelectedRisks}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    // disabled={activities.length === 0}
                    style={{
                      backgroundColor: '#1CBB8D',
                      color: '#fff',
                      width: '165px',
                      height: '40px',
                      borderRadius: '4px',
                      left: '615px',
                      top: '20px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    Add Selected Risks
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* <form onSubmit={handleApplyFilter}> */}

                <div className='d-flex align-items-center justify-content-end '>
                  <span className='fs-6 fw-semibold me-2'>Category: </span>
                  <select
                    id='category'
                    className='form-select w-50'
                    aria-label='Select category'
                    value={selectedCategory}
                    // style={{width:'70%'}}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value)
                      if (e.target.value === 'All') {
                        handleResetFilter()
                      } else {
                        handleApplyFilter(e)
                      }
                    }}
                  >
                    <option value='' disabled selected>
                      Select Category
                    </option>
                    <option onClick={handleResetFilter}>All</option>
                    <option value='Access control'>Access control</option>
                    <option value='Artificial intelligence'>Artificial intelligence</option>
                    <option value='Asset management'>Asset management</option>
                    <option value='Business continuity and disaster recovery'>
                      Business continuity and disaster recovery
                    </option>
                    <option value='Communications security'>Communications security</option>
                    <option value='Compliance'>Compliance</option>
                    <option value='Cryptography'>Cryptography</option>
                    <option value='Environmental, social, and governance'>
                      Environmental, social, and governance
                    </option>
                    <option value='Fraud'>Fraud</option>
                    <option value='People operations'>People operations</option>
                    <option value='Incident response manegement'>
                      Incident response management
                    </option>
                    <option value='Information security policies'>
                      Information security policies
                    </option>
                    <option value='Operations security'>Operations security</option>
                    <option value='Information security operations'>
                      Information security operations
                    </option>
                    <option value='Physical and environmental security'>
                      Physical and environmental security
                    </option>
                    <option value='Privacy'>Privacy</option>
                    <option value='Vendor relationships'>Vendor relationships</option>
                    <option value='Software development and acquisition'>
                      Software development and acquisition
                    </option>
                    <option value='Trustworthiness'>Trustworthiness</option>
                  </select>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='card-body py-1'>{/* <VendorListSearchComponent /> */}</div>
        <div className='table-responsive ps-9'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted fs-6'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>

                <th className='min-w-400px'>SCENARIO</th>
                <th className='min-w-200px'>CATEGORY</th>
                <th className='min-w-200px'>MAPPED CONTROLS</th>
                <th className='min-w-150px'>ACTION</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredItems && filteredItems.length > 0 ? (
                filteredItems
                  .sort((a, b) => a.riskSource.localeCompare(b.riskSource))
                  .map((risk) => (
                    <tr key={risk.id}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value={risk.id}
                            checked={selectAll || checkedItems.includes(risk.id)}
                            onChange={handleCheckChange}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center fw-bold fs-6 py-2'>
                          {risk.riskSource}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-semibold text-hover-primary fs-6'>
                              {risk.category.join(', ')}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <div className='fw-semibold fs-6'>
                              {risk.recommendedControls.length} Controls{' '}
                              <OverlayTrigger
                                placement='right'
                                overlay={
                                  <Tooltip id='tooltip-top'>
                                    <ol>
                                      {risk.recommendedControls.map((control, index) => (
                                        <li key={index}>{control}</li>
                                      ))}
                                    </ol>
                                  </Tooltip>
                                }
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#5A5A5A'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                                  <path d='M12 9h.01' />
                                  <path d='M11 12h1v4h1' />
                                </svg>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {tenantRisks.some((tenantRisk) => tenantRisk.id === risk.id) ? (
                            <button
                              className='btn btn-sm '
                              onClick={() => handleAddRisk(risk)}
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              disabled
                              style={{
                                backgroundColor: '#E1E1E1',
                                color: '#909090',
                                width: '90px',
                                height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              Added
                            </button>
                          ) : (
                            <>
                              {loadingStates[risk.id] ? (
                                <button
                                  className='btn btn-sm'
                                  onClick={() => handleAddRisk(risk)}
                                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                    (e.currentTarget.style.backgroundColor = '#148363')
                                  }
                                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                  }
                                  // disabled={activities.length === 0}
                                  style={{
                                    backgroundColor: '#1CBB8D',
                                    color: '#fff',
                                    width: '120px',
                                    height: '40px',
                                    borderRadius: '4px',
                                    left: '615px',
                                    top: '20px',
                                    transition: 'background-color 0.3s',
                                  }}
                                >
                                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                                  Add Risk
                                </button>
                              ) : (
                                <button
                                  className='btn btn-sm'
                                  onClick={() => handleAddRisk(risk)}
                                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                    (e.currentTarget.style.backgroundColor = '#148363')
                                  }
                                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                  }
                                  // disabled={activities.length === 0}
                                  style={{
                                    backgroundColor: '#1CBB8D',
                                    color: '#fff',
                                    width: '90px',
                                    height: '40px',
                                    borderRadius: '4px',
                                    left: '615px',
                                    top: '20px',
                                    transition: 'background-color 0.3s',
                                  }}
                                >
                                  Add Risk
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <>
                  <tr style={{height: '100%'}}>
                    {searchLength ? (
                      <td colSpan={10}>
                        <div
                          className='d-flex justify-content-center align-items-center text-center'
                          style={{height: '100%'}}
                        >
                          <div className='m-20'>
                            <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No risk scenario found!</p>
                            </div>
                            <p>
                              We couldn't find any risk scenario that match your search. Maybe try a
                              different search!
                            </p>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td colSpan={10}>
                        <div
                          className='d-flex justify-content-center align-items-center text-center'
                          style={{height: '100%'}}
                        >
                          <div className='m-20'>
                            <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                            <div className='m-4 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No Risks Scenario</p>
                            </div>
                            <p> We couldn't find any risk scenario!</p>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
      {/* begin::Table container */}

      {/* begin::Body */}
    </>
  )
}

export {RiskLibrary}
