import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {doc, updateDoc} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {toast} from 'react-toastify'

interface DeactivatingProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const Deactivate: React.FC<DeactivatingProps> = ({isOpen, onClose, id}) => {
  const [showModal, setShowModal] = useState(false)
  const {currentUser} = useAuth()
  const [deactivationDetail, setDeactivationDetail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setShowModal(false)
    onClose()
  }

  const handleChange = (e) => {
    setDeactivationDetail(e.target.value)
  }

  const handleDeactivateActivate = async () => {
    try {
      setIsLoading(true)

      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
      await updateDoc(testDocRef, {
        control_evidence_status: 'Deactivated',
        deactivation_control: deactivationDetail,
      })
      handleClose()
      setDeactivationDetail('')
      setIsLoading(false)
      toast.success('Control deactivated successfully!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    } catch (error) {
      toast.error('Failed to deactivate control', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          // border: '1px solid #e0e0e0',
          color: 'black',
        },

        autoClose: 3000,
      })
    }
  }

  return (
    <Modal show={showModal} onHide={handleClose} backdropClassName='bg-opacity-1' centered>
      <Modal.Header closeButton>
        <Modal.Title>Deactivate control</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fs-5 fw-bold mb-2'>
          Why do you want to deactivate monitoring for this control?{' '}
        </div>
        <div className='fs-6' style={{color: '#5A5A5A'}}>
          Disabling monitoring will stop SecureSlate's automatic oversight of these controls and
          remove them from your list of failing controls. Only disable monitoring if you have other
          measures in place or if this control is not relevant to your organization.
          <p className='mt-2'>Please note that this action can be reversed.</p>
        </div>
        <div className='fv-row mb-2 mt-7'>
          <label className=' fw-bold fs-6 mb-2 required'>Reason for deactivate this control</label>
          <textarea
            placeholder='This is not applicable to us because...'
            className='form-control mb-3 mb-lg-0 w-100'
            name='deactivationDetail'
            value={deactivationDetail}
            onChange={handleChange}
            autoComplete='off'
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='p-0 px-6 py-4'>
        <button type='button' className='btn btn-light fs-6 mx-4' onClick={handleClose}>
          Cancel
        </button>
        {/* <div  style={{cursor: 'pointer'}}> */}
        {isLoading ? (
          <button
            className='btn btn-sm fs-6'
            // onClick={handleDeactivateActivate}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            disabled
            // disabled={activities.length === 0}
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',

              transition: 'background-color 0.3s',
            }}
          >
            Deactivating... <span className='spinner-border spinner-border-sm align-middle'></span>
          </button>
        ) : (
          <button
            className='btn btn-sm fs-6'
            onClick={handleDeactivateActivate}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            disabled={deactivationDetail === ''}
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',

              transition: 'background-color 0.3s',
            }}
          >
            Deactivate
          </button>
        )}

        {/* </div> */}
      </Modal.Footer>
    </Modal>
  )
}

export default Deactivate
