/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import React from 'react'
import {useAuth} from '../../../modules/auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import {Link} from 'react-router-dom'
import AddTask from './submodal/AddTask'
import Swal from 'sweetalert2'
import AddFramework from './submodal/AddFramework'
import AddAutomated from './submodal/AddAutomated'
import AddEvidence from './submodal/AddEvidence'
import {useNavigate} from 'react-router-dom'
import AddRiskScenario from './submodal/AddRiskScenario'

interface ViewControlProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const ViewControl: React.FC<ViewControlProps> = ({isOpen, onClose, id}) => {
  console.log('controls component rendered with id:', id)
  const [personnels, setPersonnels] = useState<any[]>([])
  const [controls, setControls] = useState([] as any[])
  const {currentUser} = useAuth()
  const [showAllFrameworks, setShowAllFrameworks] = useState(false)
  const [tests, setTests] = useState<any[]>([])
  const [specificTests, setSpecificTests] = useState<any[]>([])
  const [specificdocuments, setSpecificdocuments] = useState<any[]>([])
  const [policies, setPolicies] = useState([] as any[])
  const [newOwner, setNewOwner] = useState('')
  const [newNote, setNewnotes] = useState<any>([])
  const [activeTab, setActiveTab] = useState('overview')
  const [tasks, setTasks] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalRisk, setIsModalRisk] = useState(false)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [noteInput, setNoteInput] = useState('')
  const [isAdding, setIsAdding] = useState(false)
  const [editingNoteIndex, setEditingNoteIndex] = useState(null)
  const [editedNoteContent, setEditedNoteContent] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showAutomated, setShowAutomated] = useState(false)
  const [showEvidence, setShowEvidence] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getActivities()
  }, [])

  useEffect(() => {
    getControl()
  }, [id])

  useEffect(() => {
    const testIdss = controls.filter((doc) => doc.id === id).flatMap((item) => item.test_id)

    const testControl = tests.filter((item) => testIdss.includes(item.id))
    console.log(testControl)
    setSpecificTests(testControl)
  }, [id, controls, tests])

  specificTests.forEach((test) => {
    console.log(test.name)
  })

  useEffect(() => {
    const docIdss = controls.filter((doc) => doc.id === id).flatMap((item) => item.document_id)

    const docControl = tests.filter((item) => docIdss.includes(item.id))

    setSpecificdocuments(docControl)
  }, [id, controls, tests])

  specificdocuments.forEach((test) => {
    console.log(test.name)
  })

  useEffect(() => {
    const control = controls.find((c) => c.id === id)
    if (control) {
      updateControlStatus(control)
    }
  }, [controls, id, currentUser])

  const updateControlStatus = async (control) => {
    if (!currentUser || !currentUser.tenantId) return
    if (control.control_evidence_status === 'Deactivate') {
      console.log('Control status is Deactivate. No updates will be made.')
      return
    }
    const tenantId = currentUser.tenantId
    const testDocRef = doc(db, 'tenants', tenantId, 'controls', control.id)
    try {
      let controlTest = control.control_test || '0/0'
      let controlDocument = control.control_document || '0/0'

      const [currentCount, totalCount] = controlTest.split('/').map(Number)
      const [currentCount1, totalCount1] = controlDocument.split('/').map(Number)

      const newStatus =
        currentCount === totalCount && currentCount1 === totalCount1 ? 'OK' : 'Unhealthy'

      await updateDoc(testDocRef, {
        control_evidence_status: newStatus,
      })

      if (newStatus === 'OK') {
        const currentDate = new Date()
        const todaysDate = `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()}`

        const activitiesRef = collection(db, 'tenants', tenantId, 'activities')
        await addDoc(activitiesRef, {
          message: `Control ${control.control_title || control.id} status updated to OK`,
          timestamp: serverTimestamp(),
          tenantId: currentUser.tenantId,
          uid: currentUser.uid,
          username: currentUser.userName,
          action: 'Control status updated',
          target: 'Controls',
          email: currentUser.email,
          date: todaysDate,
        })
      }
    } catch (error) {
      console.error('Error updating control status: ', error)
    }
  }

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTasks(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    const updateDocument = async () => {
      if (!currentUser) return
      if (id) {
        const tenantId = currentUser.tenantId || ''
        if (!tenantId) {
          return
        }

        const controlDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
        const controlDocSnapshot = await getDoc(controlDocRef)

        if (controlDocSnapshot.exists()) {
          const controlData = controlDocSnapshot.data()

          const documentIds = controlData.document_id || []
          let passCount = 0
          const totalCount = documentIds.length

          console.log(totalCount)
          for (const testId of documentIds) {
            try {
              const testDocRef = doc(db, `tenants/${tenantId}/tests/${testId}`)
              const testDocSnapshot = await getDoc(testDocRef)

              if (testDocSnapshot.exists()) {
                const testData = testDocSnapshot.data()
                if (testData.status === 'Pass') {
                  passCount++
                }
              }
            } catch (error) {
              console.error('Error fetching test document: ', error)
            }
          }

          const updatedControlDocument = `${passCount}/${totalCount}`
          console.log(updatedControlDocument)
          try {
            await updateDoc(controlDocRef, {
              control_document: updatedControlDocument,
            })
            console.log('Control document updated successfully')
          } catch (error) {
            console.error('Error updating control document: ', error)
          }
        } else {
          console.log('Control document does not exist')
        }
      }
    }

    updateDocument()
  }, [specificdocuments, currentUser, id])

  useEffect(() => {
    const updateTest = async () => {
      if (!currentUser) return
      if (id) {
        const tenantId = currentUser.tenantId || ''
        if (!tenantId) {
          return
        }
        const controlDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
        const controlDocSnapshot = await getDoc(controlDocRef)

        if (controlDocSnapshot.exists()) {
          const controlData = controlDocSnapshot.data()
          const testIds = controlData.test_id || []
          let passCount = 0
          const totalCount = testIds.length

          for (const testId of testIds) {
            try {
              const testDocRef = doc(db, `tenants/${tenantId}/tests/${testId}`)
              const testDocSnapshot = await getDoc(testDocRef)

              if (testDocSnapshot.exists()) {
                const testData = testDocSnapshot.data()
                if (testData.status === 'Pass') {
                  passCount++
                }
              }
            } catch (error) {
              console.error('Error fetching test document: ', error)
            }
          }

          // Update the control_test field in the control document
          const updatedControlTest = `${passCount}/${totalCount}`
          try {
            await updateDoc(controlDocRef, {
              control_test: updatedControlTest,
            })
            console.log('Control test updated successfully')
          } catch (error) {
            console.error('Error updating control document: ', error)
          }
        } else {
          console.log('Control document does not exist')
        }
      }
    }

    updateTest()
  }, [specificTests, currentUser, id])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'Policies')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          policy: doc.data().policy,
        }))

        const filteredEvidences = controlsData.filter((policyData) =>
          specificTests.filter((test) => test.policies === policyData.policy)
        )
        setPolicies(filteredEvidences)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }, [])

  const getControl = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            name: doc.data().control_title,
          }))
          .filter((data) => data.id === id)
        setControls(controlsData)
      })

      const testsCollectionRef = collection(tenantRef, 'tests')

      const unsubscribee = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTests(testsData)
      })

      return [unsubscribe, unsubscribee]
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  function processControlValues(inputString: string, inputString2: string): string {
    // Concatenate the input strings with a '+' separator
    const concatenatedString = inputString + '+' + inputString2
    console.log(concatenatedString)

    // Split the concatenated string by '+' to separate the two parts
    const parts = concatenatedString.split('+')

    let numerator = 0
    let denominator = 0

    // Loop through each part
    parts.forEach((part) => {
      // Split the part by '/' to separate the numerator and the denominator
      const splitPart = part.split('/')
      // Convert the numerator and the denominator to integers
      const num = parseInt(splitPart[0], 10)
      const den = parseInt(splitPart[1], 10)

      // Check if the conversion was successful
      if (isNaN(num) || isNaN(den)) {
        console.error('Invalid input format')
        return
      }

      // Add the numerator and denominator to the total
      numerator += num
      denominator += den
    })

    if (denominator === 0) {
      return '0/0'
    }

    // Return the result as a string in the format "numerator/denominator"
    const processedValue = `${numerator}/${denominator}`
    console.log(processedValue)
    return processedValue
  }

  const getBadgeClass = (testPassing) => {
    const [numerator, denominator] = testPassing.split('/').map(Number)

    if (numerator === 0 && denominator === 0 && controls[0].author === 'Custom Control') {
      return 'bg-danger'
    }

    if (numerator === 0 && denominator === 0) {
      // Special case for 0/0
      return 'bg-primary'
    } else if (numerator === 0) {
      // If the numerator is 0 but denominator is not
      return 'bg-danger'
    } else if (numerator === denominator) {
      // Numerator equals denominator and not 0/0
      return 'bg-primary'
    } else {
      // Some tests failed
      return 'bg-danger'
    }
  }
  const getStatusMessage = (testPassing) => {
    const [numerator, denominator] = testPassing.split('/').map(Number)
    if (testPassing === '0/0' && numerator === '0') {
      return 'Evidence Missing'
    } else if (numerator === denominator) {
      return 'OK'
    } else {
      return 'Unhealthy'
    }
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab)
    // Additional logic if needed when a tab is clicked
  }

  const handleboard = () => {
    setShowModal(true)
  }

  const handleAutomated = () => {
    setShowAutomated(true)
  }

  const handleCloseAutomated = () => {
    setShowAutomated(false)
  }

  const handleEvidence = () => {
    setShowEvidence(true)
  }

  const handleCloseEvidence = () => {
    setShowEvidence(false)
  }

  const handleOwner = async (event) => {
    const selectedOwner = event.target.value
    console.log(selectedOwner)
    setNewOwner(selectedOwner)

    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)
      await updateDoc(testDocRef, {
        control_owner: selectedOwner,
      })

      setNewOwner('')
      console.log('Owner assigned successfully')
    } catch (error) {
      console.error('Error assigning owner: ', error)
    }
  }

  const handleNotes = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!noteInput.trim()) {
      console.log('Note is empty')
      return
    }

    const currentDate = new Date()

    const day = currentDate.getDate()
    const month = currentDate.toLocaleString('default', {month: 'long'})
    const year = currentDate.getFullYear()
    const formattedDate = `${day} ${month}, ${year}`

    let hours = currentDate.getHours()
    let minutes = currentDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'

    // Round minutes to nearest 12
    minutes = Math.round(minutes / 12) * 12
    if (minutes === 60) {
      minutes = 0
      hours += 1
    }

    hours = hours % 12
    hours = hours ? hours : 12

    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      // Create a new note object
      const newNoteObject = {
        addedUser: currentUser?.userName || 'Unknown User',
        addedDate: formattedDate,
        addedTime: formattedTime,
        note: noteInput.trim(),
      }
      setIsAdding(true)
      // Add the new note to the array
      const updatedNotes = [newNoteObject, ...currentNotes]

      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      setNoteInput('')
      setNewnotes(updatedNotes)
      setIsAdding(false)
    } catch (error) {
      console.error('Error adding note: ', error)
      setIsAdding(false)
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleOpenRisk = () => {
    setIsModalRisk(true)
  }

  const handleCloseRisk = () => {
    setIsModalRisk(false)
  }

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  const handleRemoveTask = async (taskId) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Remove Selected Items',
        text: 'Are you sure you want to remove the selected items? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await deleteDoc(taskRef)
        getActivities() // Refresh the tasks list
      }
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Selected items have been removed.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleDeleteNote = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      const updatedNotes = currentNotes.filter((_, i) => i !== index)

      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      // setNotes(updatedNotes)
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const handleDeleteTest = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentdocument = docSnap.data()?.document_id || []

      const updatedRisks = currentdocument.filter((_, i) => i !== index)

      await updateDoc(testDocRef, {
        document_id: updatedRisks,
      })

      // setNotes(updatedNotes)
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const handleDeletePlatform = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentdocument = docSnap.data()?.test_id || []

      const updatedRisks = currentdocument.filter((_, i) => i !== index)

      await updateDoc(testDocRef, {
        test_id: updatedRisks,
      })

      // setNotes(updatedNotes)
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const handleEditNote = (index, currentContent) => {
    setEditingNoteIndex(index)
    setEditedNoteContent(currentContent)
  }

  const handleSaveEditedNote = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/controls/${id}`)

      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      const updatedNotes = currentNotes.map((note, index) =>
        index === editingNoteIndex ? {...note, note: editedNoteContent} : note
      )

      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      setEditingNoteIndex(null)
      setEditedNoteContent('')
    } catch (error) {
      console.error('Error editing note: ', error)
    }
  }

  const handleRiskClick = (riskId) => {
    navigate(`/risk-management?id=${riskId}`)
  }

  const handleDeleteControl = async (risk) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${risk.riskSource}`,
        text: `Are you sure you want to remove ${risk.riskSource} control from risk scenario? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const riskRef = doc(tenantRef, 'risks', risk.riskId)
        const controlRef = doc(tenantRef, 'controls', id)

        // 1. Update the risk document
        const riskDoc = await getDoc(riskRef)
        if (riskDoc.exists()) {
          const riskData = riskDoc.data()
          const updatedAddedControls = riskData.added_controls.filter(
            (control) => control.id !== id
          )
          await updateDoc(riskRef, {
            added_controls: updatedAddedControls,
          })
        }

        // 2. Update the control document
        const controlDoc = await getDoc(controlRef)
        if (controlDoc.exists()) {
          const controlData = controlDoc.data()
          const updatedRiskScenarios = controlData.risk_scenario.filter(
            (scenario) => scenario.riskId !== risk.riskId
          )
          await updateDoc(controlRef, {
            risk_scenario: updatedRiskScenarios,
          })
        }

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `"${risk.riskSource}" has been removed from the risk scenario.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })

        // 3. Update local state if necessary
        setControls((prevControls) => {
          const updatedControl = {...prevControls[0]}
          updatedControl.risk_scenario = updatedControl.risk_scenario.filter(
            (scenario) => scenario.riskId !== risk.riskId
          )
          return [updatedControl]
        })
      }
    } catch (error) {
      console.error('Error removing risk from control:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the risk from the control.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <>
      <AddTask isOpen={isModalOpen} onClose={handleCloseModal} control_id={id} />
      <AddRiskScenario isOpen={isModalRisk} onCancel={handleCloseRisk} controlId={id} />
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className='border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='d-flex justify-content-between w-100'>
            {controls
              .filter((control) => control.id === id)
              .map((control) => (
                <span className='modal-title mt-2 fs-4 fw-bold mx-2' key={control.id}>
                  {control.control_title}
                </span>
              ))}
            <div className='d-flex align-items-center'>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary'
                style={{
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#1CBB8D'
                    }
                  }
                }}
                onMouseLeave={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#5A5A5A'
                    }
                  }
                }}
              >
                <a href={`/controls/details/${id}`} target='_blank' rel='noopener noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-external-link'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                    <path d='M11 13l9 -9' />
                    <path d='M15 4h5v5' />
                  </svg>
                </a>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary'
                aria-label='Close'
                onClick={onClose}
                style={{
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#1CBB8D'
                    }
                  }
                }}
                onMouseLeave={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#5A5A5A'
                    }
                  }
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5E6274'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x '
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <AddFramework id={id || ''} isOpen={showModal} onClose={handleCloseModal} />
        <AddAutomated id={id || ''} isOpen={showAutomated} onClose={handleCloseAutomated} />
        <AddEvidence id={id || ''} isOpen={showEvidence} onClose={handleCloseEvidence} />

        <div
          className='px-7 pt-6 scroll-y overflow-auto '
          tabIndex={-1}
          style={{height: 'calc(100vh - 40px)'}}
        >
          {controls
            .filter((control) => control.id === id)
            .map((control) => (
              <div key={control.id}>
                <div className='modal-body '>
                  <div
                    className='d-flex flex-column'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    {/* <div className='fv-row mb-4 text-gray-800 fs-6 '>
                      {control.control_description}
                      
                    </div> */}
                    <div
                      className='fv-row mb-4 text-gray-800 fs-6'
                      dangerouslySetInnerHTML={{__html: control.control_description}}
                    />

                    {/* {!control.author ? (
                      <div className='row mb-5'>
                        <div className='col-3'>
                          <label className='text-gray-800 fw-semibold me-2 fs-6 '>Control ID</label>
                        </div>
                        <div className='col-9'>
                          <span className='fw-normal fw-bold fs-6'>{control.control_id}</span>
                        </div>
                      </div>
                    ) : ( */}
                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-semibold me-2 fs-6 '>Author</label>
                      </div>
                      <div className='col-9'>
                        <span className='fw-normal fw-semibold fs-6'>
                          {control.author ? control.author : 'SecureSlate'}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-semibold me-2 fs-6'>Framework</label>
                      </div>
                      <div className='col-9'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div className='d-flex flex-wrap align-items-center mb-4'>
                            <div className=''>
                              {control.control_framework_mappings &&
                              typeof control.control_framework_mappings === 'string' ? (
                                <span
                                  className='badge px-3 rounded-pill me-1 fs-7'
                                  style={{border: '2px solid #E1E1E1'}}
                                >
                                  {control.control_framework_mappings.split(',')[0].trim()}{' '}
                                </span>
                              ) : (
                                '--'
                              )}
                            </div>
                            {control.control_framework_mappings &&
                              typeof control.control_framework_mappings === 'string' &&
                              control.control_framework_mappings.split(',').length > 1 &&
                              !showAllFrameworks && (
                                <button
                                  className='btn btn-link p-0 '
                                  onClick={() => setShowAllFrameworks(true)}
                                >
                                  <span
                                    className='badge px-3 rounded-pill fs-7'
                                    style={{border: '2px solid #E1E1E1'}}
                                  >
                                    +{control.control_framework_mappings.split(',').length - 1}
                                  </span>
                                </button>
                              )}

                            {showAllFrameworks &&
                              control.control_framework_mappings &&
                              typeof control.control_framework_mappings === 'string' && (
                                <>
                                  {control.control_framework_mappings
                                    .split(',')
                                    .slice(1)
                                    .map((framework, index) => (
                                      <span
                                        key={index}
                                        className='badge px-3 rounded-pill me-1 fs-7 mb-1'
                                        style={{border: '2px solid #E1E1E1'}}
                                      >
                                        {framework.trim()}
                                      </span>
                                    ))}
                                </>
                              )}
                          </div>

                          {control.author === 'Custom Control' && (
                            <button
                              className='btn btn-sm p-0 fs-6 mb-4'
                              style={{color: '#1CBB8D'}}
                              onClick={handleboard}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-edit me-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                <path d='M16 5l3 3' />
                              </svg>
                              edit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='row mb-4'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-semibold me-2 fs-6'>Status</label>
                      </div>
                      <div className='col-9'>
                        <span className='fw-normal fw-bold fs-6'>
                          {/* <span className='badge border p-2 rounded-pill'>
                            <span
                              className={`badge border rounded-circle mx-2 ${getBadgeClass(
                                processControlValues(control.control_document, control.control_test)
                              )}`}
                              style={{
                                width: '12px',
                                height: '12px',
                                padding: '3px',
                              }}
                            >
                              {' '}
                            </span>
                            <span className='text-center'>{control.control_evidence_status}</span>
                          </span> */}
                          {control.control_evidence_status === 'Deactivate' ? (
                            <a
                              href='#'
                              className='badge fs-7 text-dark text-bold'
                              style={{background: '#F6F6F6'}}
                            >
                              {control.control_evidence_status}
                            </a>
                          ) : (
                            <a
                              className={`badge fs-7 ${
                                control.control_evidence_status === 'Ok' ||
                                control.control_evidence_status === 'OK'
                                  ? 'badge-primary'
                                  : 'badge-danger'
                              }`}
                              style={{
                                color:
                                  control.control_evidence_status === 'Ok' ||
                                  control.control_evidence_status === 'OK'
                                    ? '#1CBB8D'
                                    : '#EE3043',
                                background:
                                  control.control_evidence_status === 'Ok' ||
                                  control.control_evidence_status === 'OK'
                                    ? '#E8F8F4'
                                    : '#FFF5F8',
                              }}
                              href='#'
                            >
                              {control.control_evidence_status}
                            </a>
                          )}{' '}
                          <span className='mx-1'> </span>
                          <span className='fs-6'>
                            {' '}
                            {processControlValues(
                              control.control_document,
                              control.control_test
                            )}{' '}
                            Test Passing
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-3 d-flex align-items-center'>
                        <label className='fs-6 text-gray-800 fw-semibold me-4'>Owner</label>
                      </div>
                      <div className='col-9'>
                        <span className='fw-normal'>
                          {' '}
                          <select
                            name='group'
                            className='form-select w-40 py-2 px-4'
                            data-control='select2'
                            data-placeholder='Select Group'
                            value={newOwner || control.control_owner}
                            onChange={handleOwner}
                          >
                            <option value='' defaultChecked>
                              Assign Owner
                            </option>
                            {personnels
                              .filter((user) => user.role === 'Admin')
                              .map((group) => (
                                <option key={group.id} value={group.full_name}>
                                  {group.full_name}
                                </option>
                              ))}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
                {/* <div className='separator border-3 my-8'></div> */}

                <div className='px-2  mt-4 mb-12'>
                  <div className='d-flex  h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
                      <li className='nav-item'>
                        <Link
                          to={`/controls`}
                          className={`nav-link me-6 ${
                            activeTab === 'overview' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('overview')}
                        >
                          Tests
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link
                          to={'/controls'}
                          className={`nav-link me-6 ${
                            activeTab === 'risks' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('risks')}
                        >
                          Risks
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link
                          to={'/controls'}
                          className={`nav-link me-6 ${
                            activeTab === 'notes' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('notes')}
                        >
                          Notes
                        </Link>
                      </li>
                      {/* <li className='nav-item'>
                        <Link
                          to={'/controls'}
                          className={`nav-link me-6 ${
                            activeTab === 'tasks' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('tasks')}
                        >
                          Tasks
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>

                {activeTab === 'overview' && (
                  <div className='modal-footer mb-4'>
                    <div className=' w-100'>
                      <div className='d-flex justify-content-between align-items-center fw-bold fs-6 mb-6'>
                        <span>
                          Platform{' '}
                          <span className='text-gray-800 fw-semibold fs-8'>
                            (
                            {control.control_test === '0/0'
                              ? ' N/A '
                              : `${control.control_test} OK`}
                            )
                          </span>
                        </span>
                        {control.author === 'Custom Control' && (
                          <button
                            className='btn btn-sm fs-6'
                            onClick={handleAutomated}
                            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#148363')
                            }
                            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#1CBB8D')
                            }
                            // disabled={activities.length === 0}
                            style={{
                              backgroundColor: '#1CBB8D',
                              color: '#fff',
                              width: '90px',
                              height: '40px',
                              borderRadius: '4px',

                              transition: 'background-color 0.3s',
                            }}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M12 5l0 14' />
                              <path d='M5 12l14 0' />
                            </svg>
                            Add
                          </button>
                        )}
                      </div>

                      {control.control_test === '0/0' ? (
                        <>
                          <div className={`card mb-5 mb-xl-8 `}>
                            <div className='card-header border-0 pt-6 mb-5'>
                              <div className='d-flex align-items-center justify-content-center w-100 fw-bold'>
                                No platform tests required for this control
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {specificTests.map((test, index) => (
                            // <a
                            //     href={`https://app.getsecureslate.com/tests?id=${test.id}`}
                            //   target='_blank'
                            //   rel='noreferrer'
                            // >

                            <div className={`card mb-5 mb-xl-8 `} key={index}>
                              <div className='card-header border-0 pt-6 mb-5'>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a
                                      href={`/tests?id=${test.id}`}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      <span className='card-label text-dark text-hover-primary fw-bold fs-6'>
                                        {test.name}
                                      </span>
                                    </a>
                                    <span className='text-gray-800 fw-semibold fs-8 d-none d-md-block my-2'>
                                      Owner:{' '}
                                      <div className='symbol symbol-20px me-1 rounded-circle'>
                                        {test.owner ? (
                                          <div className='symbol symbol-25px'>
                                            <span
                                              className='symbol-label fs-7 fw-bold'
                                              style={{backgroundColor: '#DAF9EF'}}
                                            >
                                              {test.owner
                                                ? (() => {
                                                    const nameParts = test.owner.split(' ')
                                                    if (nameParts.length > 1) {
                                                      return `${nameParts[0]
                                                        .charAt(0)
                                                        .toUpperCase()}${nameParts[1]
                                                        .charAt(0)
                                                        .toUpperCase()}`
                                                    } else {
                                                      return `${nameParts[0]
                                                        .charAt(0)
                                                        .toUpperCase()}${nameParts[0]
                                                        .charAt(1)
                                                        .toUpperCase()}`
                                                    }
                                                  })()
                                                : ''}
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>
                                      <span className='fw-semibold mx-1'>
                                        {test.owner ? test.owner : 'Not assigned'} |{' '}
                                      </span>
                                      {/* )} */}
                                      {/* <span className='badge border p-2 rounded-pill '>
                                          <span
                                            className={`badge border rounded-circle mx-1 ${
                                              test.status === 'Pass' ? 'bg-primary' : 'bg-danger'
                                            }`}
                                            style={{
                                              width: '12px',
                                              height: '12px',
                                              padding: '3px',
                                            }}
                                          >
                                            {' '}
                                          </span>
                                          {test.status === 'Pass' ? 'OK' : 'Failing'}
                                        </span> */}
                                      <span
                                        className='badge fs-7'
                                        style={{
                                          color: test.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                                          background:
                                            test.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                                        }}
                                      >
                                        {' '}
                                        {test.status === 'Pass' ? 'Passing' : 'Failing'}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                  {' '}
                                  {control.author !== 'Custom Control' ? (
                                    <a
                                      href={`/tests?id=${test.id}`}
                                      target='_blank'
                                      rel='noreferrer'
                                      className='mt-4'
                                      style={{textDecoration: 'none', color: 'inherit'}}
                                    >
                                      <img
                                        src={toAbsoluteUrl(`/media/buttons/Chevron Button.svg`)}
                                        alt=''
                                      />
                                    </a>
                                  ) : (
                                    <div className='dropdown'>
                                      <button
                                        className='btn btn-secondary border-0 p-0 m-2'
                                        type='button'
                                        id='dropdownMenu2'
                                        data-bs-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <img
                                          src='/media/buttons/Options.svg'
                                          alt='Options'
                                          onClick={toggleDropdown}
                                        />
                                      </button>
                                      <ul
                                        className='dropdown-menu dropdown-menu-end'
                                        aria-labelledby='dropdownMenu2'
                                      >
                                        <li>
                                          <a
                                            className='dropdown-item text-muted text-hover-primary '
                                            type='button'
                                            // onClick={() => handleEditNote(index, note.note)}
                                            href={`/tests?id=${test.id}`}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-2 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                              <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                            </svg>
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <button
                                            className='dropdown-item text-danger'
                                            type='button'
                                            onClick={() => handleDeletePlatform(index)}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M4 7l16 0' />
                                              <path d='M10 11l0 6' />
                                              <path d='M14 11l0 6' />
                                              <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                              <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                            </svg>
                                            Delete
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      <div className='separator my-5'></div>

                      <div className='d-flex justify-content-between align-items-center fw-bold fs-6 mb-6'>
                        <span>
                          Document{' '}
                          <span className='text-gray-800 fw-semibold fs-8'>
                            (
                            {control.control_document === '0/0'
                              ? ' N/A '
                              : `${control.control_document} OK`}{' '}
                            )
                          </span>
                        </span>
                        {control.author === 'Custom Control' && (
                          <button
                            className='btn btn-sm fs-6'
                            onClick={handleEvidence}
                            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#148363')
                            }
                            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                              (e.currentTarget.style.backgroundColor = '#1CBB8D')
                            }
                            // disabled={activities.length === 0}
                            style={{
                              backgroundColor: '#1CBB8D',
                              color: '#fff',
                              width: '90px',
                              height: '40px',
                              borderRadius: '4px',

                              transition: 'background-color 0.3s',
                            }}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M12 5l0 14' />
                              <path d='M5 12l14 0' />
                            </svg>
                            Add
                          </button>
                        )}
                      </div>
                      {control.control_document === '0/0' ? (
                        <>
                          <div className={`card mb-5 mb-xl-8 `}>
                            <div className='card-header border-0 pt-6 mb-5'>
                              <div className='d-flex align-items-center justify-content-center w-100 fw-bold'>
                                No document tests required for this control
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {specificdocuments.map((test, index) => (
                            // <a
                            //   href={`https://app.gtsecureslate.com/tests?id=${test.id}`}
                            //   target='_blank'
                            //   rel='noreferrer'
                            //   key={index}
                            // >

                            <div className={`card mb-5 mb-xl-8`}>
                              <div className='card-header border-0 pt-6 mb-5'>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a
                                      href={`https://app.getsecureslate.com/tests?id=${test.id}`}
                                      target='_blank'
                                      rel='noreferrer'
                                      key={index}
                                    >
                                      <span className='card-label text-dark text-hover-primary fw-bold fs-6'>
                                        {test.name}
                                      </span>
                                    </a>
                                    <span className='text-gray-800 fw-semibold fs-8 d-none d-md-block my-2'>
                                      Owner:{' '}
                                      {test.owner ? (
                                        <div className='symbol symbol-25px'>
                                          <span
                                            className='symbol-label fs-7 fw-bold'
                                            style={{backgroundColor: '#DAF9EF'}}
                                          >
                                            {test.owner
                                              ? (() => {
                                                  const nameParts = test.owner.split(' ')
                                                  if (nameParts.length > 1) {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[1]
                                                      .charAt(0)
                                                      .toUpperCase()}`
                                                  } else {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[0]
                                                      .charAt(1)
                                                      .toUpperCase()}`
                                                  }
                                                })()
                                              : ''}
                                          </span>
                                        </div>
                                      ) : null}
                                      <span> {test.owner ? test.owner : 'Not assigned'} </span>|{' '}
                                      {/* <span className='badge border p-2 rounded-pill '>
                                          <span
                                            className={`badge border rounded-circle mx-1 ${
                                              test.status === 'Pass' ? 'bg-primary' : 'bg-danger'
                                            }`}
                                            style={{
                                              width: '12px',
                                              height: '12px',
                                              padding: '3px',
                                            }}
                                          >
                                            {' '}
                                          </span>
                                          <span className='text-center'>
                                            {test.status === 'Pass' ? 'OK' : 'Failing'}
                                          </span>
                                        </span> */}
                                      <span
                                        className='badge fs-7'
                                        style={{
                                          color: test.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                                          background:
                                            test.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                                        }}
                                      >
                                        {' '}
                                        {test.status === 'Pass' ? 'Passing' : 'Failing'}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                  {control.author !== 'Custom Control' ? (
                                    <a
                                      href={`/tests?id=${test.id}`}
                                      target='_blank'
                                      rel='noreferrer'
                                      className='mt-4'
                                      style={{textDecoration: 'none', color: 'inherit'}}
                                    >
                                      <img
                                        src={toAbsoluteUrl(`/media/buttons/Chevron Button.svg`)}
                                        alt=''
                                      />
                                    </a>
                                  ) : (
                                    <div className='dropdown'>
                                      <button
                                        className='btn btn-secondary border-0 p-0 m-2'
                                        type='button'
                                        id='dropdownMenu2'
                                        data-bs-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <img
                                          src='/media/buttons/Options.svg'
                                          alt='Options'
                                          onClick={toggleDropdown}
                                        />
                                      </button>
                                      <ul
                                        className='dropdown-menu dropdown-menu-end'
                                        aria-labelledby='dropdownMenu2'
                                      >
                                        <li>
                                          <a
                                            className='dropdown-item text-muted text-hover-primary '
                                            type='button'
                                            // onClick={() => handleEditNote(index, note.note)}
                                            href={`/tests?id=${test.id}`}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-2 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                              <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                            </svg>
                                            View
                                          </a>
                                        </li>
                                        <li>
                                          <button
                                            className='dropdown-item text-danger'
                                            type='button'
                                            onClick={() => handleDeleteTest(index)}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M4 7l16 0' />
                                              <path d='M10 11l0 6' />
                                              <path d='M14 11l0 6' />
                                              <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                              <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                            </svg>
                                            Delete
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}

                {activeTab === 'notes' && (
                  <>
                    <div className='mb-8'>
                      {controls && controls.length > 0 ? (
                        controls
                          // .filter((control) => control.id === id)
                          .map((control) =>
                            control.notes && control.notes.length > 0 ? (
                              control.notes.map((note, index) => (
                                <div className='mt-4 mb-8' key={index}>
                                  <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-40px me-3'>
                                        {note.addedUser ? (
                                          <span
                                            className='symbol-label fs-7 fw-bold'
                                            style={{
                                              backgroundColor: '#DAF9EF',
                                              borderRadius: '50%',
                                            }}
                                          >
                                            {note.addedUser
                                              ? (() => {
                                                  const nameParts = note.addedUser.split(' ')
                                                  if (nameParts.length > 1) {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[1]
                                                      .charAt(0)
                                                      .toUpperCase()}`
                                                  } else {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[0]
                                                      .charAt(1)
                                                      .toUpperCase()}`
                                                  }
                                                })()
                                              : ''}
                                          </span>
                                        ) : (
                                          <span>-</span>
                                        )}
                                      </div>

                                      <div className='d-flex flex-column'>
                                        <span className='text-dark fw-bold fs-6'>
                                          {note.addedUser}
                                        </span>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                          {note.addedDate} {note.addedTime}
                                        </span>
                                      </div>
                                    </div>

                                    <div className='dropdown'>
                                      <button
                                        className='btn btn-secondary border-0 p-0 m-2'
                                        type='button'
                                        id='dropdownMenu2'
                                        data-bs-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <img
                                          src='/media/buttons/Options.svg'
                                          alt='Options'
                                          onClick={toggleDropdown}
                                        />
                                      </button>
                                      <ul
                                        className='dropdown-menu dropdown-menu-end'
                                        aria-labelledby='dropdownMenu2'
                                      >
                                        <li>
                                          <button
                                            className='dropdown-item text-muted text-hover-primary '
                                            type='button'
                                            onClick={() => handleEditNote(index, note.note)}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-2 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                              <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                              <path d='M16 5l3 3' />
                                            </svg>
                                            Edit
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className='dropdown-item text-danger'
                                            type='button'
                                            onClick={() => handleDeleteNote(index)}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M4 7l16 0' />
                                              <path d='M10 11l0 6' />
                                              <path d='M14 11l0 6' />
                                              <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                              <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                            </svg>
                                            Delete
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  {editingNoteIndex === index ? (
                                    <div>
                                      <textarea
                                        value={editedNoteContent}
                                        onChange={(e) => setEditedNoteContent(e.target.value)}
                                        className='form-control'
                                      />
                                      <button
                                        onClick={handleSaveEditedNote}
                                        className='btn btn-sm mt-2 fs-6'
                                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                          (e.currentTarget.style.backgroundColor = '#148363')
                                        }
                                        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                          (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                        }
                                        // disabled={activities.length === 0}
                                        style={{
                                          backgroundColor: '#1CBB8D',
                                          color: '#fff',
                                          width: '80px',
                                          height: '40px',
                                          borderRadius: '4px',
                                          marginLeft: '4px',
                                          transition: 'background-color 0.3s',
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  ) : (
                                    <div className='card p-4 mt-2' style={{background: '#F6F6F6'}}>
                                      <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                                        {note.note}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ))
                            ) : (
                              <div
                                className='d-flex justify-content-center align-items-center text-center'
                                style={{height: '100%'}}
                              >
                                <div className=''>
                                  <img
                                    src='/media/buttons/emptystate.svg'
                                    alt=''
                                    className='img-fluid'
                                    style={{maxWidth: '100px'}}
                                  />

                                  <div className=' fs-6'>
                                    <p style={{fontWeight: 'bold'}}>No notes added yet!!</p>
                                  </div>
                                  <p className='fs-7'>
                                    Maybe try adding new note for this control{' '}
                                  </p>
                                </div>
                              </div>
                            )
                          )
                      ) : (
                        <div>No controls available</div>
                      )}
                    </div>
                  </>
                )}

                {activeTab === 'risks' && (
                  <>
                    {' '}
                    <div className='my-4 d-flex justify-content-between'>
                      <div className=''>
                        <div className='fs-6 fw-bold mb-2'>Risk Scenarios</div>
                        <span>
                          {control?.risk_scenario ? control?.risk_scenario.length : 0} scenarios
                          mapped to this control
                        </span>
                      </div>
                      <button
                        className='btn btn-sm fs-6'
                        onClick={handleOpenRisk}
                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#148363')
                        }
                        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#1CBB8D')
                        }
                        style={{
                          backgroundColor: '#1CBB8D',
                          color: '#fff',
                          width: '130px',
                          height: '40px',
                          borderRadius: '4px',
                          marginRight: '0px',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-plus me-1 mb-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M12 5l0 14' />
                          <path d='M5 12l14 0' />
                        </svg>
                        Add Risks
                      </button>
                    </div>
                    <div className='card mt-4'>
                      <div
                        className='table-responsive'
                        style={{minHeight: '100px', maxHeight: '500px', overflowY: 'auto'}}
                      >
                        {control?.risk_scenario && control?.risk_scenario.length > 0 ? (
                          <table className='table table-row-dashed table-row-gray-300 align-middle gs-6 gy-6'>
                            {/* begin::Table head */}
                            <thead>
                              <tr
                                className='fw-bold'
                                style={{background: '#F6F6F6', color: '#5A5A5A'}}
                              >
                                <th className='min-w-200px pe-6'>Scenario</th>
                                <th className='min-w-100px'>Status</th>
                                <th className='min-w-50px'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {control?.risk_scenario.map((control) => (
                                <tr key={control.riskId} className=' px-6'>
                                  <td
                                    className='fs-6 fw-semibold text-hover-primary py-8'
                                    style={{borderBottom: '1px dotted #C5C5C5', cursor: 'pointer'}}
                                    onClick={() => handleRiskClick(control.riskId)}
                                  >
                                    {control.riskSource}
                                  </td>
                                  <td>
                                    {' '}
                                    <span
                                      className={`badge fs-7 ${
                                        control.status === 'Incomplete'
                                          ? 'badge-danger'
                                          : control.status === 'Needs approval'
                                          ? 'badge-warning'
                                          : 'badge-primary'
                                      }`}
                                      style={{
                                        color:
                                          control.status === 'Incomplete'
                                            ? '#EE3043'
                                            : control.status === 'Needs approval'
                                            ? '#FFA800'
                                            : '#1CBB8D',
                                        background:
                                          control.status === 'Incomplete'
                                            ? '#FFF5F8'
                                            : control.status === 'Needs approval'
                                            ? '#FFF8DD'
                                            : '#E8F8F4',
                                      }}
                                    >
                                      {control.status}
                                    </span>
                                  </td>
                                  <td>
                                    <div
                                      className='btn btn-icon btn-sm btn-active-light-primary'
                                      onClick={() => handleDeleteControl(control)}
                                      style={{
                                        transition: 'all 0.2s ease',
                                        // border:'2px solid #C5C5C5'
                                      }}
                                      onMouseEnter={(e) => {
                                        if (e.currentTarget) {
                                          e.currentTarget.style.backgroundColor = '#fff'
                                          const svgElement = e.currentTarget.querySelector('svg')
                                          if (svgElement) {
                                            svgElement.style.stroke = '#1CBB8D'
                                          }
                                        }
                                      }}
                                      onMouseLeave={(e) => {
                                        if (e.currentTarget) {
                                          e.currentTarget.style.backgroundColor = '#fff'
                                          const svgElement = e.currentTarget.querySelector('svg')
                                          if (svgElement) {
                                            svgElement.style.stroke = '#5A5A5A'
                                          }
                                        }
                                      }}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#5E6274'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-x '
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M18 6l-12 12' />
                                        <path d='M6 6l12 12' />
                                      </svg>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <>
                            <div
                              className='card-body'
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#000'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-adjustments-horizontal'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                                <path d='M4 6l8 0' />
                                <path d='M16 6l4 0' />
                                <path d='M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                                <path d='M4 12l2 0' />
                                <path d='M10 12l10 0' />
                                <path d='M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
                                <path d='M4 18l11 0' />
                                <path d='M19 18l1 0' />
                              </svg>
                              <br />
                              <span className='fs-6 fw-semibold'>No risks added</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {activeTab === 'tasks' && (
                  <>
                    <div className='card px-6 mt-4'>
                      <div className='my-4 d-flex justify-content-between'>
                        <div className='my-4'>
                          <div className='fs-6 fw-bold mb-2'>Task List</div>
                          <span>Assign tasks that might fix this control to team members.</span>
                        </div>
                        <button
                          className='btn btn-sm my-4'
                          onClick={handleOpenModal}
                          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                            (e.currentTarget.style.backgroundColor = '#ECECEC')
                          }
                          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                            (e.currentTarget.style.backgroundColor = '#fff')
                          }
                          // disabled={activities.length === 0}
                          style={{
                            backgroundColor: '#fff',
                            color: '#000',
                            width: '120px',
                            height: '40px',
                            borderRadius: '4px',
                            border: '2px solid #C5C5C5',
                            transition: 'background-color 0.3s',
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-plus mx-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M12 5l0 14' />
                            <path d='M5 12l14 0' />
                          </svg>
                          Add Task
                        </button>
                      </div>
                      <div
                        className='table-responsive'
                        style={{minHeight: '300px', maxHeight: '500px', overflowY: 'auto'}}
                      >
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          {/* begin::Table head */}
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='w-25px'>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-9-check'
                                    // checked={isChecked}
                                    // onChange={handleCheckboxChange}
                                  />
                                </div>
                              </th>
                              <th className='min-w-200px'>Task</th>
                              <th className='min-w-50px'>Priority</th>
                              <th className='min-w-100px'>Assigned To</th>
                              {/* <th className='min-w-100px'>Due date</th> */}
                              <th className='min-w-50px text-end'></th>
                            </tr>
                          </thead>
                          {/* end::Table head */}
                          {/* begin::Table body */}
                          <tbody>
                            {tasks.map((task) => (
                              <tr key={task.id}>
                                <td>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                      className='form-check-input widget-9-check'
                                      type='checkbox'
                                      checked={task.checked}
                                      // onChange={() => handleTaskCheckboxChange(task.id)}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                    {task.taskName}
                                  </div>
                                </td>
                                {/* <td className="text-end">
                    <div className="d-flex flex-column w-100 me-2">
                      <div className="d-flex flex-stack mb-2">
                        
                        <span className={`badge badge-light-success`}>{task.priority}</span>
                      </div>
                    </div>
                  </td> */}
                                <td>
                                  <span className='badge border p-2 rounded-pill fs-6 fw-semibold'>
                                    <span
                                      className={`badge border rounded-circle mx-2  ${
                                        task.priority === 'High'
                                          ? 'bg-danger'
                                          : task.priority === 'Medium'
                                          ? 'bg-warning'
                                          : 'bg-primary'
                                      }
                           `}
                                      style={{
                                        width: '12px',
                                        height: '12px',
                                        padding: '3px',
                                      }}
                                    >
                                      {' '}
                                    </span>

                                    {task.priority}
                                  </span>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    {task.owner ? (
                                      <>
                                        <div className='symbol symbol-35px me-3'>
                                          <span
                                            className='symbol-label fs-7 fw-bold'
                                            style={{backgroundColor: '#DAF9EF'}}
                                          >
                                            {task.owner
                                              ? (() => {
                                                  const nameParts = task.owner.split(' ')
                                                  if (nameParts.length > 1) {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[1]
                                                      .charAt(0)
                                                      .toUpperCase()}`
                                                  } else {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[0]
                                                      .charAt(1)
                                                      .toUpperCase()}`
                                                  }
                                                })()
                                              : ''}
                                          </span>
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                          <div className='text-dark fw-semibold text-hover-primary fs-6'>
                                            {task.owner}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <span>-</span>
                                      </>
                                    )}
                                  </div>
                                </td>

                                {/* <td className='fs-6 fw-semibold'>{task.duedate}</td> */}
                                <td className='text-end'>
                                  <div className='dropdown '>
                                    <button
                                      className='btn btn-secondary border-0 p-0 '
                                      type='button'
                                      id='dropdownMenu2'
                                      data-bs-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                    >
                                      <img
                                        src='/media/buttons/Options.svg'
                                        alt='Options'
                                        // onClick={toggleDropdown}
                                      />
                                    </button>
                                    <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                                      <li>
                                        <button
                                          className='dropdown-item'
                                          type='button'
                                          // onClick={() => handleMarkAsComplete(task.id)}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='#5E6274'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M5 12l5 5l10 -10' />
                                          </svg>{' '}
                                          Mark as complete
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className='dropdown-item'
                                          style={{color: '#F15363'}}
                                          type='button'
                                          onClick={() => handleRemoveTask(task.id)}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='#F15363'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M4 7l16 0' />
                                            <path d='M10 11l0 6' />
                                            <path d='M14 11l0 6' />
                                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                          </svg>{' '}
                                          Remove task
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
        {activeTab === 'notes' && (
          <div
            className='offcanvas-footer  border-top pt-4 pb-4  px-6'
            style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
          >
            <div className='d-flex align-items-center'>
              <input
                type='text'
                className='form-control me-2'
                placeholder='Leave a note'
                name='notes'
                value={noteInput}
                onChange={(e) => setNoteInput(e.target.value)}
              />
              {isAdding ? (
                <button
                  className='btn btn-sm fs-6'
                  // onClick={handleNotes}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  disabled
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '150px',
                    height: '40px',
                    borderRadius: '4px',
                    marginLeft: '4px',
                    transition: 'background-color 0.3s',
                  }}
                >
                  Adding...{' '}
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                </button>
              ) : (
                <button
                  className='btn btn-sm fs-6'
                  onClick={handleNotes}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  // disabled={activities.length === 0}
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '120px',
                    height: '40px',
                    borderRadius: '4px',
                    marginLeft: '4px',
                    transition: 'background-color 0.3s',
                  }}
                >
                  Add Note
                </button>
              )}
            </div>
          </div>
        )}
      </Drawer>
      {/* {isOpen && <div className='modal-backdrop fade show'></div>} */}
    </>
  )
}

export {ViewControl}
