/* eslint-disable react/jsx-no-target-blank */
import {useLocation} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'

const SidebarFooter = () => {
  const location = useLocation()
  const {currentUser} = useAuth()
  const isDashboard =
    location.pathname === '/dashboard' ||
    location.pathname.startsWith('/policy-library') ||
    location.pathname.startsWith('/vendor-risk') ||
    location.pathname.startsWith('/personnel-management') ||
    location.pathname.startsWith('/data-room') ||
    location.pathname.startsWith('/asset-management') ||
    location.pathname.startsWith('/risk-management') ||
    location.pathname.startsWith('/integrations') ||
    location.pathname.startsWith('/reports') ||
    location.pathname.startsWith('/tests') ||
    location.pathname.startsWith('/controls') ||
    location.pathname.startsWith('/frameworks')
  const isOnboarding =
    location.pathname.startsWith('/training') ||
    location.pathname === '/policies' ||
    location.pathname === '/backgroundCheck'

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6 ' id='kt_app_sidebar_footer'>
      {isDashboard && (
        <></>
        // <a
        //   href='#'
        //   target='_blank'
        //   className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        //   data-bs-trigger='hover'
        //   data-bs-dismiss-='click'
        //   title='Upgrade'
        //   data-bs-toggle='modal'
        //   data-bs-target='#kt_modal_upgrade_plan'
        // >
        //   <span className='btn-label'>Upgrade</span>
        //   <KTIcon iconName='triangle' className='btn-icon fs-2 m-0' />
        // </a>
      )}
      {isOnboarding && currentUser?.role ==='Admin' && (
        <a href='/dashboard'>
          <button
            className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100 '
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Exit'
          >
            <span className='btn-label'>Exit Onboarding</span>
          </button>
        </a>
      )}
    </div>
  )
}

export {SidebarFooter}
