import {useState} from 'react'
import {storage} from '../../../../../services/firebase'
import db from '../../../../../services/firebase'
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {addDoc, collection, updateDoc} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
const DataRoomToolbar = ({onHeaderAction, subFolderClicked, folderPath,folderId, fileFolderCreated}) => {
  const [isUploading, setIsUploading] = useState(false)
  const {currentUser} = useAuth()

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const f = e.target.files[0]
      if (f) {
        handleUpload(f)
      }
    }
  }
  const handleCustomUpload = () => {
    const hiddenFileInput: HTMLElement | null = document.getElementById('hiddenFileInput')
    hiddenFileInput?.click()
  }

  const handleUpload = async (file: File) => {
    console.log('u', file)
    if (file) {
      const storageRef = ref(storage, `${folderPath}/${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, file)
      setIsUploading(true)
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
          alert(error)
          console.error('Upload error:', error)
          setIsUploading(false)
        },
        async () => {
          // Get the download URL of the uploaded file
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
          const currentDate = new Date()
          const year = currentDate.getFullYear()
          const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Add 1 because month index starts from 0
          const day = String(currentDate.getDate()).padStart(2, '0')

          const formattedDate = `${year}-${month}-${day}`
          // Generate a unique document ID
          const tenantId = currentUser?.tenantId || '';
          const fileCollectionRef = collection(db, 'tenants', tenantId, 'dataroom')
          const newFile = {
            subFolder: `${folderPath}${file.name}`,
            name: file.name,
            fileSize: file.size,
            contentType: file.type,
            downloadUrl: downloadURL,
            uploadedBy: currentUser?.userName, // Replace with actual user information
            time_created: formattedDate,
            tenantId: currentUser?.tenantId,
            uid: currentUser?.uid,
            is_folder:false
          }
          const newDocRef = await addDoc(fileCollectionRef, newFile);

          await updateDoc(newDocRef, { fileId: newDocRef.id });

          console.log('Document added with ID: ', newDocRef.id)
          fileFolderCreated(true)
          setIsUploading(false)
        }
      )
    } else {
      console.error('Firestore write error:')
      alert('Please select a file first.')
    }
  }

  // Create a new folder

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}
      {/* begin::Export */}
      {!folderId ? (
        <button
          type='button'
          className='btn me-3'
          onClick={onHeaderAction}
          style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}
        >
          <KTIcon iconName='add-folder' className='fs-2 text-primary' />
          New Folder
        </button>
      ) : (
        <div></div>
      )}
      {/* end::Export */}
      {/* begin::Add user */}
      <input
        type='file'
        id='hiddenFileInput'
        onChange={handleChange}
        accept='.pdf'
        style={{display: 'none'}}
      />
      <button
        type='button'
        disabled={isUploading} // Disable the button while uploading
        className='btn btn-primary'
        onClick={handleCustomUpload}
        style={{
          backgroundColor: '#44DBAF',
          color: '#FFFFFF',
          cursor: isUploading ? 'not-allowed' : 'pointer',
        }}
      >
        {isUploading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  )
}
export {DataRoomToolbar}
