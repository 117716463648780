import {collection, doc, getDocs, getFirestore, onSnapshot} from 'firebase/firestore'
import {saveAs} from 'file-saver'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {useEffect, useState} from 'react'
import ExcelJS from 'exceljs'
const TestListToolbar = () => {
  const {currentUser} = useAuth()
  const [tests, setTests] = useState([] as any[])
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getTests()
  }, [activeFrameworks])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    if (activeFrameworks.length > 0) {
      const filteredTests = tests.filter((test) => {
        const testFrameworks = test.framework.split(',').map((fw) => fw.trim())

        return activeFrameworks.some((activeFramework) =>
          testFrameworks.some((testFramework) => testFramework === activeFramework)
        )
      })

      setFilteredItems(filteredTests)
    } else {
      setFilteredItems(tests)
    }
  }, [tests, activeFrameworks])

  const getTests = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'tests')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().framework,
          status: doc.data().status,
        }))

        setTests(testsData)
      })

      return unsubscribe
    } catch (error) {
      console.log('Error getting tests:', error)
    }
  }

  // const exportData = () => {
  //   if (filteredItems.length > 0) {
  //     const excludedFields = ['id', 'updated_at', 'created_at', 'notes', 'time_interval', 'files']
  //     const fields = Object.keys(filteredItems[0]).filter(
  //       (field) => !excludedFields.includes(field)
  //     )

  //     if (!fields.includes('timeframe') && fields.includes('timeFrame')) {
  //       fields.push('timeframe')
  //     }

  //     const csvContent = [fields.join(',')]

  //     filteredItems.forEach((row) => {
  //       const rowData = fields
  //         .map((field) => {
  //           if (Array.isArray(row[field])) {
  //             return `"${row[field].join('; ')}"`
  //           } else if (typeof row[field] === 'object' && row[field] !== null) {
  //             return `"${JSON.stringify(row[field]).replace(/"/g, '""')}"`
  //           } else {
  //             return `"${row[field] || ''}"`
  //           }
  //         })
  //         .join(',')
  //       csvContent.push(rowData)
  //     })

  //     const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
  //     saveAs(csvBlob, 'tests.csv')
  //   } else {
  //     console.log('No data to export')
  //   }
  // }

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  // const exportData = () => {
  //   if (filteredItems.length > 0) {
  //     const excludedFields = ['id', 'updated_at', 'created_at', 'notes', 'time_interval', 'files']
  //     let fields = Object.keys(filteredItems[0]).filter((field) => !excludedFields.includes(field))

  //     const additionalFields = ['timeframe', 'next_review_date']
  //     additionalFields.forEach((field) => {
  //       if (!fields.includes(field)) {
  //         fields.push(field)
  //       }
  //     })

  //     const csvContent = [fields.join(',')]

  //     filteredItems.forEach((row) => {
  //       const rowData = fields
  //         .map((field) => {
  //           let value = row[field] || row[field.toLowerCase()] || ''

  //           if (field === 'description') {
  //             value = stripHtmlTags(value)
  //           }

  //           if (field === 'next_review_date' && value) {
  //             value = value.split('T')[0]
  //           }

  //           if (Array.isArray(value)) {
  //             return `"${value.join('; ')}"`
  //           } else if (typeof value === 'object' && value !== null) {
  //             return `"${JSON.stringify(value).replace(/"/g, '""')}"`
  //           } else {
  //             return `"${value.toString().replace(/"/g, '""').replace(/\n/g, ' ')}"`
  //           }
  //         })
  //         .join(',')
  //       csvContent.push(rowData)
  //     })

  //     const csvBlob = new Blob([csvContent.join('\n')], {type: 'text/csv;charset=utf-8'})
  //     saveAs(csvBlob, 'tests.csv')
  //   } else {
  //     console.log('No data to export')
  //   }
  // }

  const exportData = async () => {
    if (filteredItems.length > 0) {
      const fields = [
        {key: 'name', header: 'Name', width: 40},
        {key: 'description', header: 'Description', width: 50},
        {key: 'category', header: 'Category', width: 20},
        {key: 'type', header: 'Type', width: 15},
        {key: 'framework', header: 'Framework', width: 30},
        {key: 'status', header: 'Status', width: 15},
        {key: 'owner', header: 'Owner', width: 20},
        {key: 'timeframe', header: 'Timeframe', width: 20},
        {key: 'next_review_date', header: 'Next Review Date', width: 20},
      ]

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Tests')

      // worksheet.mergeCells('A1:I1');
      // const headingRow = worksheet.getCell('A1');
      // headingRow.value = 'SecureSlate Tests';
      // headingRow.font = { size: 16, bold: true };
      // headingRow.alignment = { horizontal: 'center' };

      worksheet.columns = fields.map((field) => ({
        header: field.header,
        key: field.key,
        width: field.width,
      }))

      const headerRow = worksheet.getRow(1)
      headerRow.values = fields.map((field) => field.header)
      worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'A9A9A9'},
      }
      worksheet.getRow(1).height = 40

      const activeFrameworksMap = new Set(activeFrameworks.map((fw) => fw.toLowerCase()));

      filteredItems.forEach((row) => {
        row.height = 30
        const rowData = fields.reduce((acc, field) => {
          let value = row[field.key] || row[field.key.toLowerCase()] || ''

          if (field.key === 'description') {
            value = stripHtmlTags(value)
          }

          if (field.key === 'next_review_date' && value) {
            value = value.split('T')[0]
          }

          if (Array.isArray(value)) {
            acc[field.key] = value.join('; ')
          } else if (typeof value === 'object' && value !== null) {
            acc[field.key] = JSON.stringify(value).replace(/"/g, '""')
          } else {
            acc[field.key] = value.toString().replace(/"/g, '""').replace(/\n/g, ' ')
          }

          if (field.key === 'framework') {
            const frameworks = value.split(',').map((fw) => fw.trim().toLowerCase());
            acc[field.key] = frameworks
              .filter((fw) => activeFrameworksMap.has(fw))
              .map((fw) => fw.toUpperCase())
              .join(', ');
          }

          return acc
        }, {})

        const rowIndex = worksheet.addRow(rowData).number
   
        const statusCell = worksheet.getCell(`F${rowIndex}`)
        if (statusCell.value === 'Pass') {
          statusCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'a2e3d0'},
          }
        } else if (statusCell.value === 'Fail') {
          statusCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'f8aab2'},
          }
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})
      saveAs(excelBlob, 'tests.xlsx')
    } else {
      console.log('No data to export')
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
    </div>
  )
}

export {TestListToolbar}
