/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import db, {storage} from '../../../services/firebase'
import {useLocation} from 'react-router-dom'
import {ref, listAll, uploadBytes, deleteObject, updateMetadata} from 'firebase/storage'
import {useAuth} from '../../modules/auth'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import {Button, Form, Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {DataRoomLoading} from './components/loading/DataRoomLoading'

type Props = {
  className: string
  isNewFolder: Boolean
  onFolderCreatedOrCancel: Function
  onSubFolderClicked: Function
  folderPath: string
  fileFolderCreated: boolean
  searchTerm: string
}

type FileItemType = {
  id?: string
  name: string
  isFolder: boolean
  fileSize: number
  contentType: string | undefined
  timeCreated: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  additionalData?: any
  downloadUrl?: string
  folderName: string
  subFolder: string
  fName: string
  fname?: string
  is_folder: boolean
  time_created: string
  fileId: string
  folder_name: string
}

const Data_room: React.FC<Props> = ({
  className,
  isNewFolder,
  onFolderCreatedOrCancel,
  onSubFolderClicked,
  folderPath,
  fileFolderCreated,
  searchTerm,
}) => {
  //   console.log('folderPath', folderPath)
  const [showFolderCreationForm, setShowFolderCreationForm] = useState(isNewFolder)
  const [folderCreating, setFolderCreating] = useState(false)
  const [folderClicked, setFolderClicked] = useState(false)
  const [clickedFolderName, setFolderClickedName] = useState('')
  const [items, setItems] = useState<FileItemType[]>([])
  const [storageRef, setStorageRef] = useState(ref(storage, folderPath))
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const {currentUser} = useAuth()
  const [show, setShow] = useState(false)
  const [filteredItems, setFilteredItems] = useState<FileItemType[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [newFileName, setNewFileName] = useState('')
  const [data, setData] = useState<FileItemType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [shareLink, setShareLink] = useState('')
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const location = useLocation()
  const [show2, setShow2] = useState(false)
  const [checkboxMTF, setCheckboxMTF] = useState('')
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [editingItemId, setEditingItemId] = useState('')

  console.log('a', editingItemId)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getData()
    // onSubFolderClicked(true)
  }, [])

  const getData = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId!)
      const folderCollectionRef = collection(tenantRef, 'dataroom')

      const unsubscribe = onSnapshot(folderCollectionRef, (snapshot) => {
        const policies = snapshot.docs
          .filter((item) => {
            const data = item.data()

            return data.is_folder === true || data.is_folder === undefined
          })
          .map((item) => ({
            ...item.data(),
            id: item.id,
          })) as FileItemType[]

        setItems(policies)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting policies:', error)
    }
  }

  useEffect(() => {
    setFilteredItems(items)
  }, [items, searchLength])

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredItems(items)
      setSearchLength(false)
    } else {
      const filteredItems = data.filter(
        (user) => user.name && user.name.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }, [searchTerm])

  // Init copy link
  const initCopyLink = () => {
    // Select all copy link elements
    const elements = document.querySelectorAll('[data-kt-filemanger-table="copy_link"]')

    elements.forEach((el) => {
      // Define elements
      const button = el.querySelector('button')
      const generator = el.querySelector('[data-kt-filemanger-table="copy_link_generator"]')
      const result = el.querySelector('[data-kt-filemanger-table="copy_link_result"]')
      const input = el.querySelector('input')

      // Click action
      if (button && generator && result && input) {
        button.addEventListener('click', (e) => {
          e.preventDefault()

          // Reset toggle
          generator.classList.remove('d-none')
          result.classList.add('d-none')

          var linkTimeout
          clearTimeout(linkTimeout)
          linkTimeout = setTimeout(() => {
            generator.classList.add('d-none')
            result.classList.remove('d-none')
            input.select()
          }, 2000)
        })
      }
    })
  }

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  const toggleFolderCreationForm = () => {
    onFolderCreatedOrCancel()
    setShowFolderCreationForm(!showFolderCreationForm)
  }

  // const createFolder = async () => {
  //   const inputElement = document.querySelector('input[name="new_folder_name"]')

  //   // Check if the input element is found
  //   if (inputElement && inputElement instanceof HTMLInputElement) {
  //     const inputValue = inputElement.value
  //     if (!inputValue) {
  //       alert('Please enter a folder name.')
  //       return
  //     }

  //     // Create a reference to the new folder in Cloud Storage
  //     setFolderCreating(true)
  //     toggleFolderCreationForm()
  //     const folderRef = ref(storage, `/policy_files/${inputValue}/`)
  //     // Upload the placeholder file with custom metadata, including tenantId
  //     const placeholderFileRef = ref(folderRef, 'placeholder.txt')
  //     await uploadBytes(placeholderFileRef, new Uint8Array(0), {
  //       customMetadata: {
  //         tenantId: currentUser?.tenantId || '', // Set tenantId to currentUser's tenantId or an empty string
  //       },
  //     })

  //     // Save folder information to Firestore
  //     const folderCollectionRef = collection(db, 'policy_files')
  //     const currentDate = new Date()
  //     const year = currentDate.getFullYear()
  //     const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Add 1 because month index starts from 0
  //     const day = String(currentDate.getDate()).padStart(2, '0')

  //     const formattedDate = `${year}-${month}-${day}`
  //     const newFolderRef = await addDoc(folderCollectionRef, {
  //       fName: inputValue,
  //       folderName: inputValue,
  //       tenantId: currentUser?.tenantId || '',
  //       uid: currentUser?.uid || '',
  //       timeCreated: formattedDate,
  //       isFolder: true,
  //       // subFolder: `policy_files/${inputValue}`,

  //       // Add any other folder-related information you need to save
  //     })
  //     const newFolderId = newFolderRef.id
  //     // setFileId(newFolderId)
  //     // const metadata = await getMetadata(placeholderFileRef)
  //     await updateMetadata(placeholderFileRef, {
  //       customMetadata: {
  //         folderId: newFolderId,
  //       },
  //     })
  //     setFolderCreating(false)
  //     toggleFolderCreationForm()

  //     // You can also add Firestore logic to keep track of folders
  //   }
  // }

  const createFolder = async () => {
    const inputElement = document.querySelector('input[name="new_folder_name"]')

    if (inputElement && inputElement instanceof HTMLInputElement) {
      const inputValue = inputElement.value
      if (!inputValue) {
        alert('Please enter a folder name.')
        return
      }

      setFolderCreating(true)
      toggleFolderCreationForm()
      const tenantId = currentUser?.tenantId || ''
      const folderRef = ref(storage, `/tenants/${tenantId}/dataroom/${inputValue}/`)
      const placeholderFileRef = ref(folderRef, 'placeholder.txt')
      await uploadBytes(placeholderFileRef, new Uint8Array(0), {
        customMetadata: {
          tenantId: currentUser?.tenantId || '',
        },
      })
      const tenantRef = doc(db, 'tenants', tenantId!)
      const folderCollectionRef = collection(tenantRef, 'dataroom')
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}`
      const newFolderRef = await addDoc(folderCollectionRef, {
        fName: inputValue,
        folder_name: inputValue,
        tenantId: currentUser?.tenantId || '',
        uid: currentUser?.uid || '',
        time_created: formattedDate,
        is_folder: true,
        subFolder: `/tenants/${tenantId}/dataroom/${inputValue}/`,
      })
      const newFolderId = newFolderRef.id
      await updateMetadata(placeholderFileRef, {
        customMetadata: {
          folderId: newFolderId,
        },
      })
      setFolderCreating(false)
      toggleFolderCreationForm()

      // You can also add Firestore logic to keep track of folders
    }
  }

  function componentDidMount() {
    const hostUrl = 'assets/'

    // Global Javascript Bundle
    const globalScript = document.createElement('script')
    globalScript.src = 'assets/plugins/global/plugins.bundle.js'
    document.body.appendChild(globalScript)

    // Scripts Bundle
    const scriptsScript = document.createElement('script')
    scriptsScript.src = 'assets/js/scripts.bundle.js'
    document.body.appendChild(scriptsScript)

    // Vendors Javascript
    const vendorsScript = document.createElement('script')
    vendorsScript.src = 'assets/plugins/custom/datatables/datatables.bundle.js'
    document.body.appendChild(vendorsScript)

    // Custom Javascript
    const customScripts = [
      'assets/js/custom/apps/file-manager/list.js',
      'assets/js/widgets.bundle.js',
      'assets/js/custom/widgets.js',
      'assets/js/custom/apps/chat/chat.js',
      'assets/js/custom/utilities/modals/upgrade-plan.js',
      'assets/js/custom/utilities/modals/create-app.js',
      'assets/js/custom/utilities/modals/users-search.js',
    ]

    customScripts.forEach((scriptUrl) => {
      const customScript = document.createElement('script')
      customScript.src = scriptUrl
      document.body.appendChild(customScript)
    })
  }

  // Helper function to format time as "DD Mon YYYY, HH:mm am/pm"
  const formatTime = (timeStr) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    } as Intl.DateTimeFormatOptions
    return new Date(timeStr).toLocaleDateString(undefined, options)
  }

  useEffect(() => {
    initCopyLink() // Initialize the copy link functionality
  }, [])

  useEffect(() => {
    // Update showFolderCreationForm when isNewFolder changes
    setShowFolderCreationForm(isNewFolder)
  }, [isNewFolder])

  const onFolderClicked = (item) => {
    setFolderClicked(true)
    setFolderClickedName(item.is_folder ? item.fName : item.name)
    onSubFolderClicked(item)
    setStorageRef(
      ref(
        storage,
        `/tenants/${currentUser?.tenantId}/dataroom/${
          item.is_folder ? item.folder_name : item.name
        }`
      )
    )
    showFilesInFolder(item)
  }

  function formatDate(isoDateString) {
    const date = new Date(isoDateString)

    const day = date.getDate()
    const month = date.toLocaleString('en-US', {month: 'long'})
    const year = date.getFullYear()

    const formattedDate = `${day} ${month}, ${year}`

    return formattedDate
  }

  // const showFilesInFolder = async (item) => {
  //   try {
  //     const folderPath = `/tenants/${currentUser?.tenantId}/dataroom/${
  //       item.is_folder ? item.folder_name : item.name
  //     }`
  //     const folderRef = ref(storage, folderPath)
  //     const folderSnapshot = await listAll(folderRef)

  //     const files: FileItemType[] = []

  //     for (const fileRef of folderSnapshot.items) {
  //       const metadata = await getMetadata(fileRef)
  //       console.log("m",metadata)
  //       const file = {
  //         // id: fileRef.name,
  //         name: metadata.name,
  //         isFolder: false,
  //         fileSize: metadata.size,
  //         contentType: metadata.contentType,
  //         time_created: metadata.timeCreated,
  //         downloadUrl: await getDownloadURL(fileRef),
  //         tenantId: currentUser?.tenantId ?? '',
  //         subFolder: folderPath,
  //         fName: metadata.name,
  //         is_folder: false,
  //         folderName: '',
  //         folder_name: '',
  //         timeCreated: metadata.timeCreated,
  //       }
  //       files.push(file)
  //     }

  //     setItems(files)
  //   } catch (error) {
  //     console.error('Error fetching files from folder:', error)
  //   }
  // }

  // const showFilesInFolder = async (item) => {
  //   try {
  //     // Construct the folder path based on the clicked folder
  //     const folderPath = `/tenants/${currentUser?.tenantId}/dataroom/${item.folder_name}/`;

  //     // Filter to include both files and folders inside the selected folder
  //     const matchingItems = data.filter((file) => {
  //       if (file.is_folder) {
  //         // For folders, match if `subFolder` starts with `folderPath` and is not equal to `folderPath`
  //         return file.subFolder.startsWith(folderPath) && file.subFolder !== folderPath;
  //       } else {
  //         // For files, match if `subFolder` starts with `folderPath`
  //         return file.subFolder.startsWith(folderPath);
  //       }
  //     });

  //     console.log(matchingItems);

  //     // Update the UI with the filtered list of folders and files
  //     setItems(
  //       matchingItems.map((file) => ({
  //         ...file,
  //         is_folder: file.is_folder || false, // Ensure the `is_folder` flag is handled correctly
  //       }))
  //     );
  //   } catch (error) {
  //     console.error('Error fetching files from folder:', error);
  //   }
  // };

  const showFilesInFolder = async (item) => {
    try {
      // Construct the folder path based on the clicked folder
      const folderPath = `/tenants/${currentUser?.tenantId}/dataroom/${
        item.is_folder ? item.folder_name : item.name
      }`
      const files: FileItemType[] = []
      // Reference to the Firestore collection
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId!)
      const filesCollection = collection(tenantRef, 'dataroom')

      // Query to fetch files and folders inside the selected folder
      const q = query(
        filesCollection,
        where('subFolder', '>=', folderPath),
        where('subFolder', '<', folderPath + '\uf8ff')
        // where('is_folder', '==', false)
      )

      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((doc) => {
        const data = doc.data()
        console.log('dd', data)
        if (data.is_folder === false) {
          // Exclude folders here
          const file = {
            name: data.name,
            isFolder: data.is_folder,
            fileSize: data.fileSize,
            contentType: data.contentType,
            time_created: data.time_created,
            downloadUrl: data.downloadUrl,
            tenantId: data.tenantId,
            subFolder: data.subFolder,
            fName: data.fName,
            folderName: data.folderName,
            folder_name: data.folder_name,
            timeCreated: data.timeCreated,
            is_folder: data.is_folder,
            fileId: data.fileId,
          }
          files.push(file)
        }
      })

      setItems(files) // Update the UI with the list of files and folders
    } catch (error) {
      console.error('Error fetching files from Firestore:', error)
    }
  }

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSelectAllChecked(isChecked)

    // Update the isChecked property for each item in the items array
    const updatedItems = items.map((item) => ({
      ...item,
      isChecked: isChecked,
    }))

    setSelectedItems(isChecked ? updatedItems.map((_, index) => index) : [])
    setItems(updatedItems)
  }

  const handleCheckboxChange = (index: number) => {
    const updatedItems: FileItemType[] = items.map((item, i) => ({
      ...item,
      isChecked: i === index ? !item.isChecked : item.isChecked,
    }))

    setSelectedItems(updatedItems.filter((item) => item.isChecked).map((_, index) => index))
    setSelectAllChecked(updatedItems.every((item) => item.isChecked))
    setItems(updatedItems)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }

  const handleDeleteFile = async (fileId, fileName) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${fileName} ?`,
        text: `Are you sure you want to remove ${fileName} from the dataroom? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const fileDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', fileId)
        await deleteDoc(fileDocRef)

        setItems((prevFiles) => prevFiles.filter((file) => file.id !== fileId))

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${fileName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the file.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }

  const deleteFolder = async (folderId, folderName) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${folderName} ?`,
        text: `Are you sure you want to remove ${folderName} from the dataroom? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const folderRef = ref(storage, `/tenants/${tenantId}/dataroom/${folderName}`)

        // List all items (files and subfolders) in the folder
        const folderItems = await listAll(folderRef)

        // Delete all files in the folder from Firebase Storage
        await Promise.all(
          folderItems.items.map(async (item) => {
            await deleteObject(item)
          })
        )

        // Create a reference to the folder document in Firestore
        const folderDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', folderId)
        // Delete the folder document from Firestore
        await deleteDoc(folderDocRef)

        // Update the state to reflect the deletion
        setItems((prevFiles) => prevFiles.filter((item) => item.id !== folderId))

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${folderName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the folder.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }

  const handleDownload = async (item) => {
    try {
      console.log('Downloading file:', item)
      // const item = items.find((item) => item.id === itemId) // Find the item corresponding to the ID
      // if (!item) {
      //   console.error('Item not found.')
      //   return
      // }
      console.log('Item:', item.downloadURL || item.downloadUrl)

      if (item.downloadURL || item.downloadUrl) {
        // Check if the file is not a folder
        if (item.is_folder !== true) {
          // let downloadUrl =
          //   item.additionalData && item.additionalData.downloadURL
          //     ? item.additionalData.downloadURL
          //     : item.downloadUrl
          // Fetch the file content
          const response = await fetch(item.downloadURL || item.downloadUrl)
          const fileContent = await response.blob()

          // Create a Blob with the file content
          const blob = new Blob([fileContent], {type: item.contentType})

          // Create a URL for the Blob
          const blobUrl = URL.createObjectURL(blob)

          // Create a hidden link
          const link = document.createElement('a')
          link.href = blobUrl
          link.download = item.name

          // Append the link to the document
          document.body.appendChild(link)

          // Programmatically trigger a click on the link
          link.click()

          // Remove the link and revoke the Blob URL from the document
          document.body.removeChild(link)
          URL.revokeObjectURL(blobUrl)
        } else {
          console.log('Skipping download for folder:', item.name)
        }
      } else {
        console.log('File does not have a download URL.')
      }
    } catch (error) {
      console.error('Error downloading file:', error)
      // Handle the error or show an error message to the user
    }
  }

  const renameFileInFirestore = async (fileId, newFileName, isFolder) => {
    console.log('Renaming file in Firestore:', fileId, newFileName)
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId!)
      const folderCollectionRef = collection(tenantRef, 'dataroom')
      const fileDocRef = doc(folderCollectionRef, fileId)
      if (isFolder) {
        await updateDoc(fileDocRef, {folder_name: newFileName, fName: newFileName})
        setEditingItemId('')
      } else {
        await updateDoc(fileDocRef, {name: newFileName})
        setEditingItemId('')
      }

      console.log('File renamed successfully in Firestore.')
    } catch (error) {
      console.error('Error renaming file in Firestore:', error)
      throw error
    }
    setShow(false)
  }

  const generateShareLink = (itemName) => {
    // Simulate asynchronous operation (e.g., fetching data from server)
    setTimeout(() => {
      // Update state to show generated link and hide loader
      setIsLoading(false)
      setShareLink(`https://path/to/file/or/folder/${itemName}`)
    }, 2000) // Simulating 2 seconds delay
  }

  // Handle share link generation
  const handleGenerateLink = (itemName) => {
    setIsLoading(true) // Show loader
    generateShareLink(itemName)
    console.log('Generating share link for:', itemName)
  }

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const handleCheckboxMTFChange = (index, folderName) => {
    setCheckboxMTF(index)
    setFolderClickedName(folderName)
  }

  const handleMovetofolder = (item) => {
    const docRef = doc(db, 'policy_files', item.id)
    const updateFile = {
      ...item,
      subFolder: `policy_files/${clickedFolderName}`,
    }
    updateDoc(docRef, updateFile)
    handleClose2()
  }

  console.log('ff', filteredItems)
  return (
    <>
      <Helmet>
        <title>Secureslate | Data Room</title>
        {/*begin::Global Javascript Bundle(mandatory for all pages)*/}
        <script type='text/jsx' src='/assets/plugins/global/plugins.bundle.js'></script>
        <script type='text/jsx' src='/assets/js/scripts.bundle.js'></script>
        {/*begin::Vendors Javascript(used for this page only)*/}
        <script
          type='text/jsx'
          src='/assets/plugins/custom/datatables/datatables.bundle.js'
        ></script>
        {/*end::Vendors Javascript*/}
        {/*begin::Custom Javascript(used for this page only)*/}
        <script type='text/jsx' src='/assets/js/custom/apps/file-manager/list.js'></script>
        <script type='text/jsx' src='/assets/js/widgets.bundle.js'></script>
        <script type='text/jsx' src='/assets/js/custom/widgets.js'></script>
        <script type='text/jsx' src='/assets/js/custom/apps/chat/chat.js'></script>
        <script type='text/jsx' src='/assets/js/custom/utilities/modals/upgrade-plan.js'></script>
        <script type='text/jsx' src='/assets/js/custom/utilities/modals/create-app.js'></script>
        <script type='text/jsx' src='/assets/js/custom/utilities/modals/users-search.js'></script>
      </Helmet>
      {/* begin::Header */}
      {/* end::Header */}

      {/* begin:: Card Body */}
      <div className='card-body'>
        {/*begin::Table header*/}
        <div className='d-flex flex-stack'>
          {/*begin::Folder path*/}
          <div className='badge badge-lg badge-light-primary'>
            <div className='d-flex align-items-center flex-wrap'>
              <i className='ki-duotone ki-abstract-32 fs-2 text-primary me-3'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <Link to='/dashboard'>Secureslate</Link>
              <i className='ki-duotone ki-right fs-2 text-primary mx-1'></i>
              <Link
                to='/data-room'
                onClick={() => {
                  setFolderClicked(false)
                  onSubFolderClicked(false)
                  getData()
                }}
              >
                Dataroom
              </Link>

              {folderClicked ? (
                <>
                  <i className='ki-duotone ki-right fs-2 text-primary mx-1'></i> {clickedFolderName}
                </>
              ) : null}
            </div>
          </div>
          {/*end::Folder path*/}
          {/*begin::Folder Stats*/}
          <div className='badge badge-lg badge-primary'>
            <span id='kt_file_manager_items_counter'>{items.length} items</span>
          </div>
          {/*end::Folder Stats*/}
        </div>
        {/*end::Table header*/}
        {/*begin::Table*/}
        <table
          id='kt_file_manager_list'
          data-kt-filemanager-table='folders'
          className='table align-middle table-row-dashed fs-6 gy-5'
        >
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check='true'
                    data-kt-check-target='#kt_file_manager_list .form-check-input'
                    value='0'
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                </div>
              </th>
              <th className='min-w-250px'>Name</th>
              <th className='min-w-10px'>Size</th>
              <th className='min-w-125px'>Last Modified</th>
              <th className='w-125px'></th>
            </tr>
          </thead>
          <tbody className='fw-semibold text-gray-600'>
            {/* New Folder section */}
            {showFolderCreationForm && (
              <tr id='kt_file_manager_new_folder_row' data-kt-filemanager-template='upload'>
                <td></td>
                <td
                  id='kt_file_manager_add_folder_form'
                  className='fv-row fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container'
                >
                  <div className='d-flex align-items-center'>
                    <span id='kt_file_manager_folder_icon'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <input
                      type='text'
                      name='new_folder_name'
                      placeholder='Enter the folder name'
                      className='form-control mw-250px me-3'
                      defaultValue=''
                    />
                    <button
                      className='btn btn-icon btn-light-primary me-3'
                      id='kt_file_manager_add_folder'
                      onClick={createFolder}
                    >
                      <span className='indicator-label'>
                        <i
                          className={
                            folderCreating
                              ? 'ki-duotone ki-loading fs-2x'
                              : 'ki-duotone ki-check fs-1'
                          }
                        ></i>
                      </span>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </button>

                    <button
                      className='btn btn-icon btn-light-danger'
                      id='kt_file_manager_cancel_folder'
                      onClick={toggleFolderCreationForm}
                    >
                      <span className='indicator-label'>
                        <i className='ki-duotone ki-cross fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>{' '}
                      </span>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </button>
                  </div>
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
            {filteredItems && filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <tr key={item.id || item.fileId}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={index}
                        name={item.name}
                        checked={item?.isChecked || false}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                  </td>

                  <td>
                    {editingItemId === (item.id || item.fileId) ? (
                      <div className='d-flex align-items-center'>
                        <input
                          type='text'
                          value={newFileName}
                          placeholder={item.is_folder ? item.folder_name : item.name}
                          onChange={(e) => setNewFileName(e.target.value)}
                          className='form-control mw-250px me-3'
                        />
                        <button
                          className='btn btn-icon btn-light-primary me-3'
                          onClick={() => {
                            if (item.is_folder) {
                              renameFileInFirestore(item.id, newFileName, item.is_folder)
                            } else renameFileInFirestore(item.fileId, newFileName, item.is_folder)
                          }}
                        >
                          <i className='ki-duotone ki-check fs-1'></i>
                        </button>
                        <button
                          className='btn btn-icon btn-light-danger'
                          onClick={() => setEditingItemId('')}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M18 6l-12 12' />
                            <path d='M6 6l12 12' />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <span id='kt_file_manager_folder_icon'>
                          <i
                            className={`ki-duotone ki-${
                              item.is_folder ? 'folder' : ' files'
                            } fs-2x text-primary me-4`}
                          >
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </span>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary'
                          onClick={item.is_folder ? () => onFolderClicked(item) : (null as any)}
                        >
                          {item.is_folder ? item.fName : item.name}
                        </a>
                      </div>
                    )}
                  </td>
                  {/* <td>{item.isFolder ? '-' : `${item.fileSize?.toFixed(2)}/1028 KB`}</td> */}
                  <td>
                    {item.is_folder
                      ? '0.00 KB'
                      : `${(item.fileSize ? item.fileSize / 1028 : 0).toFixed(2)} KB`}
                  </td>

                  <td>
                    {' '}
                    {item.is_folder
                      ? formatDate(item.time_created)
                      : formatDate(item.time_created || item.timeCreated)}
                  </td>
                  <td>
                    <div className='d-flex justify-content-end'>
                      {/* <div className='ms-2' data-kt-filemanger-table='copy_link'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-light btn-active-light-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                          >
                            <i className='ki-duotone ki-fasten fs-5 m-0'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          </button>
                       
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px'
                            data-kt-menu='true'
                          >
                         
                            <div className='card card-flush'>
                              <div className='card-body p-5'>
                                {isLoading ? (
                                
                                  <div
                                    className='d-flex'
                                    data-kt-filemanger-table='copy_link_generator'
                                  >
                                  
                                    <div className='me-5' data-kt-indicator='on'>
                                      <span className='indicator-progress'>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    </div>
                                
                                    <div className='fs-6 text-dark'>Generating Share Link...</div>
                                  </div>
                                ) : (
                                  // Generated link
                                  <div
                                    className='d-flex flex-column text-start'
                                    data-kt-filemanger-table='copy_link_result'
                                  >
                                    <div className='d-flex mb-3'>
                                      <i className='ki-duotone ki-check fs-2 text-success me-3'></i>
                                      <div className='fs-6 text-dark'>Share Link Generated</div>
                                    </div>
                                    <input
                                      type='text'
                                      className='form-control form-control-sm'
                                      value={shareLink}
                                      readOnly
                                    />
                                    <div className='text-muted fw-normal mt-2 fs-8 px-3'>
                                      Read only.
                                      <a
                                        href='../../demo1/dist/apps/file-manager/settings/.html'
                                        className='ms-2'
                                      >
                                        Change permissions
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <button
                                className='btn btn-primary'
                                onClick={() => handleGenerateLink(item.name)}
                              >
                                Generate Share Link
                              </button>
                            </div>
                        
                          </div>
                        
                        </div> */}

                      <div className='dropdown mx-2'>
                        <button
                          className='btn btn-secondary border-0 p-0 m-2'
                          type='button'
                          id='dropdownMenu2'
                          data-bs-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <img
                            src='/media/buttons/Options.svg'
                            alt='Options'
                            onClick={toggleDropdown}
                          />
                        </button>
                        <ul className='dropdown-menu ' aria-labelledby='dropdownMenu2'>
                          <li>
                            <button
                              className='dropdown-item text-muted text-hover-primary'
                              type='button'
                              onClick={() => handleDownload(item)}
                              disabled={item.is_folder}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                                <path d='M7 11l5 5l5 -5' />
                                <path d='M12 4l0 12' />
                              </svg>{' '}
                              Download File
                            </button>
                          </li>

                          <li>
                            <button
                              className='dropdown-item text-muted text-hover-primary'
                              type='button'
                              onClick={() => {
                                if (item.is_folder) {
                                  setEditingItemId(item.id || '')
                                } else {
                                  setEditingItemId(item.fileId || '')
                                }
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                <path d='M16 5l3 3' />
                              </svg>{' '}
                              Rename
                            </button>
                          </li>

                          {/* <li>
                              <button
                                className='dropdown-item text-muted'
                                type='button'
                                onClick={handleShow2}
                              >
                                <div className='d-flex align-items-center text-muted fw-bold'>
                                  <i className='ki-duotone ki-some-files fs-2 me-2'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                  <span className=''>Move to Folder</span>
                                </div>
                              </button>
                            </li> */}

                          <li>
                            <button
                              className='dropdown-item '
                              type='button'
                              key={item.id}
                              style={{color: '#F15363'}}
                              onClick={() => {
                                if (item.is_folder) {
                                  deleteFolder(item.id, item.folder_name)
                                } else {
                                  handleDeleteFile(item.fileId, item.name)
                                }
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#F15363'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M4 7l16 0' />
                                <path d='M10 11l0 6' />
                                <path d='M14 11l0 6' />
                                <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                              </svg>{' '}
                              Remove
                            </button>
                          </li>
                        </ul>
                      </div>

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Rename File</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Control
                            type='text'
                            placeholder={item.is_folder ? item.folder_name : item.name}
                            onChange={(e) => setNewFileName(e.target.value)}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant='secondary' onClick={handleClose}>
                            Close
                          </Button>

                          <Button
                            variant='primary'
                            onClick={() => {
                              if (item.is_folder) {
                                renameFileInFirestore(item.id, newFileName, item.is_folder)
                              } else renameFileInFirestore(item.fileId, newFileName, item.is_folder)
                            }}
                          >
                            {!isLoading ? 'Renaming...' : 'Save Changes'}
                            {/* Save Changes */}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {/* <Dropdown.Item
                                href='#'
                                data-kt-filemanager-table-filter='move_row'
                                // data-bs-toggle='modal'
                                data-bs-target='#kt_modal_move_to_folder'
                                onClick={handleShow2}
                              >
                                <div className='d-flex align-items-center text-muted fw-bold'>
                                  <i className='ki-duotone ki-some-files fs-2 me-2'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                  <span className=''>Move to Folder</span>
                                </div>
                              </Dropdown.Item> */}
                      <Modal show={show2} onHide={handleClose2}>
                        <Modal.Header closeButton>
                          <Modal.Title>Move to Folder</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {data
                            .filter(
                              (item) =>
                                item.tenantId === currentUser?.tenantId &&
                                item.uid === currentUser?.uid
                            )
                            .map(
                              (item) =>
                                item.isFolder && (
                                  <>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center mb-5 fs-5 fw-bold '>
                                      <input
                                        className='form-check-input me-2'
                                        type='checkbox'
                                        value={index}
                                        name={item.name}
                                        // checked={item?.isChecked || false}
                                        onChange={() =>
                                          handleCheckboxMTFChange(item.id, item.folderName)
                                        }
                                        // onChange={()=>}
                                      />
                                      <span id='kt_file_manager_folder_icon' className='me-2'>
                                        <i
                                          className={`ki-duotone ki-${
                                            item.isFolder ? 'folder' : 'files'
                                          } fs-2x text-primary `}
                                        >
                                          <span className='path1'></span>
                                          <span className='path2'></span>
                                        </i>
                                      </span>
                                      <a
                                        href='#'
                                        className='text-gray-800 text-hover-primary '
                                        onClick={
                                          item.isFolder
                                            ? () => onFolderClicked(item)
                                            : (null as any)
                                        }
                                      >
                                        <span className='text-muted'>
                                          {' '}
                                          {item.isFolder ? item.fName : item.name}
                                        </span>
                                      </a>
                                    </div>
                                    <div className='separator separator-dotted  my-5'></div>
                                  </>
                                )
                            )}
                        </Modal.Body>
                        <Modal.Footer className='d-flex justify-content-center p-2'>
                          <Button variant='primary' onClick={() => handleMovetofolder(item)}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              // <tbody>
              <tr style={{height: '100%'}}>
                {searchLength ? (
                  <td colSpan={10} className='text-center '>
                    <div className='m-20'>
                      <img src='/media/buttons/emptystate.svg' alt='' />
                      <div className='m-5 fs-5'>
                        <p style={{fontWeight: 'bold'}}>No Data Found</p>
                      </div>
                      <p className=''>
                        We couldn't find any data that match your search. Maybe try a different
                        search!
                      </p>
                    </div>
                  </td>
                ) : (
                  <td colSpan={10}>
                    <div
                      className='d-flex justify-content-center align-items-center text-center'
                      style={{height: '100%'}}
                    >
                      {!showEmptyState ? (
                        <div
                          className='d-flex justify-content-center align-items-center text-center'
                          style={{height: '500px'}}
                        >
                          <DataRoomLoading />
                        </div>
                      ) : (
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No datas Added</p>
                          </div>
                          <p>All the Data within your organization are required to address here.</p>
                        </div>
                      )}
                    </div>
                  </td>
                )}
              </tr>
              // </tbody>
            )}
          </tbody>
        </table>
        {/*end::Table*/}
      </div>
    </>
  )
}

export {Data_room}
