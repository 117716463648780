import React, {useEffect, useState} from 'react'
import {OnDashPageTitle} from '../cores/OnDashPageData'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageChangeEvent, Viewer, Worker} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'
import {pageNavigationPlugin} from '@react-pdf-viewer/page-navigation'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import {getDownloadURL, listAll, ref} from 'firebase/storage'
import db, {storage} from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {Link, useNavigate} from 'react-router-dom'
import {collection, doc, getDoc, setDoc} from 'firebase/firestore'
interface CustomFile {
  id?: string
  name: string
  // base64String:string
  downloadURL: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  SAT?: CustomFile[]
}

const SAT = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const [allChecked, setAllChecked] = useState(false)
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)
  const [fileData, setFileData] = useState<CustomFile[]>([])
  const [selectedPDFUrl, setSelectedPDFUrl] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const [completed, setCompleted] = useState(false)
  const navigate = useNavigate()
  const [lastPage, setLastPage] = useState(false)
  const [trainingsData, setTrainingsData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (fileData.length > 0) {
      setSelectedPDFUrl(fileData[selectedFileIndex].downloadURL)
    }
  }, [fileData, selectedFileIndex])

  useEffect(() => {
    const fetchDataFromFolder = async () => {
      const tenantId = currentUser?.tenantId || ''
      const folderPath = `/SAT_training_pdf/` // Specify the path to your folder in Firebase Storage

      try {
        const folderRef = ref(storage, folderPath)
        const fileList = await listAll(folderRef)
        console.log('File List:', fileList.items[0])

        const filesPromise = fileList.items.map(async (file) => {
          const downloadURL = await getDownloadURL(file) // Get the download URL for each file
          return {name: file.name, downloadURL} // Create an object with file name and download URL
        })

        // Wait for all promises to resolve
        const filesData = await Promise.all(filesPromise)

        setFileData(filesData)
      } catch (error) {
        console.error('Error fetching data from folder:', error)
      }
    }

    fetchDataFromFolder()
  }, [])

  // useEffect(() => {
  //   const fetchTrainingData = async () => {
  //     try {
  //       const tenantId = currentUser?.tenantId || ''
  //       if (!tenantId) {
  //         return
  //       }

  //       const tenantRef = doc(db, 'tenants', tenantId)
  //       const onboardingCollectionRef = collection(tenantRef, 'onboarding')
  //       const policyDocRef = doc(onboardingCollectionRef, 'training')

  //       // Retrieve the existing training document
  //       const policyDoc = await getDoc(policyDocRef)
  //       const policyData = policyDoc.data() || {}

  //       // Update the trainingsData state with the retrieved data
  //       setTrainingsData(policyData.SecurityTraining || [])
  //       setCompleted(policyData.SecurityTraining[0].isChecked)
  //     } catch (error) {
  //       console.error('Error fetching training data:', error)
  //     }
  //   }

  //   fetchTrainingData()
  // }, [currentUser])

  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const userId = currentUser?.uid

        if (!tenantId || !userId) {
          return
        }

        const userTrainingDocRef = doc(
          db,
          'tenants',
          tenantId,
          'onboarding',
          userId,
          'training',
          'SAT'
        )

        const trainingDoc = await getDoc(userTrainingDocRef)
        const trainingData = trainingDoc.data() || {}

        const SATData = {
          downloadURL: trainingData.download_url || '',
          isChecked: trainingData.is_checked || false,
          name: trainingData.name || '',
        }

        setTrainingsData([SATData])
        setCompleted(SATData.isChecked)
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()
  }, [currentUser])

  const handleNameClick = (pdfUrl: string, index) => {
    setSelectedPDFUrl(pdfUrl)
    setSelectedFileIndex(index)
  }

  const handleNextChapterClick = () => {
    if (selectedFileIndex < fileData.length - 1) {
      const nextFileIndex = selectedFileIndex + 1
      console.log('Next file index:', nextFileIndex)
      setSelectedFileIndex(nextFileIndex)
      const nextFile = fileData[nextFileIndex]
      if (nextFile && nextFile.downloadURL) {
        setSelectedPDFUrl(nextFile.downloadURL)
      } else {
        console.error('Invalid file data:', nextFile)
      }
    }
  }

  const handlePreviousChapterClick = () => {
    if (selectedFileIndex > 0) {
      const prevFileIndex = selectedFileIndex - 1
      setSelectedFileIndex(prevFileIndex)
      const prevFile = fileData[prevFileIndex]
      if (prevFile && prevFile.downloadURL) {
        setSelectedPDFUrl(prevFile.downloadURL)
      } else {
        console.error('Invalid file data:', prevFile)
      }
    }
  }
  // const handleCheckboxChange = async (index: number) => {
  //   const SecurityTraining = fileData.map((file, idx) => {
  //     if (idx === index) {
  //       return {...file, isChecked: !file.isChecked}
  //     }
  //     return file
  //   })
  //   setFileData(SecurityTraining)

  //   const allChecked = SecurityTraining.every((file) => file.isChecked)
  //   setAllChecked(allChecked)

  //   const tenantId = currentUser?.tenantId || ''

  //   if (!tenantId) {
  //     setIsLoading(false)
  //     return
  //   }

  //   const tenantRef = doc(db, 'tenants', tenantId)
  //   const policyDocRef = doc(tenantRef, 'onboarding', 'training')

  //   try {
  //     // Retrieve the existing policy document
  //     const policyDoc = await getDoc(policyDocRef)
  //     const policyData = policyDoc.data() || {}

  //     // Update the policy data
  //     const updatedPolicyData = {
  //       ...policyData,
  //       SecurityTraining,
  //     }
  //     setIsLoading(true)
  //     // Set or update the policy document
  //     await setDoc(policyDocRef, updatedPolicyData)
  //     setCompleted(true)
  //     setIsLoading(false)

  //     setTimeout(() => {
  //       navigate('/training/PCISecure')
  //     }, 1000)
  //   } catch (error) {
  //     console.error('Error updating isChecked in Firestore:', error)
  //     setIsLoading(false)
  //   }
  // }

  const handleCheckboxChange = async (index: number) => {
    const selectedFile = fileData[index]
    const updatedIsChecked = !selectedFile.isChecked

    setFileData(
      fileData.map((file, idx) => (idx === index ? {...file, isChecked: updatedIsChecked} : file))
    )

    const allChecked = fileData.every((file, idx) =>
      idx === index ? updatedIsChecked : file.isChecked
    )
    setAllChecked(allChecked)

    const tenantId = currentUser?.tenantId || ''
    const userId = currentUser?.uid

    if (!tenantId || !userId) {
      setIsLoading(false)
      return
    }

    const userTrainingDocRef = doc(db, 'tenants', tenantId, 'onboarding', userId, 'training', 'SAT')

    try {
      setIsLoading(true)
      // Update fields directly under SAT in the specified order
      await setDoc(
        userTrainingDocRef,
        {
          [`download_url`]: selectedFile.downloadURL,
          [`is_checked`]: updatedIsChecked,
          [`name`]: selectedFile.name,
        },
        {merge: true}
      )

      setCompleted(true)
      setIsLoading(false)

      setTimeout(() => {
        navigate('/training/PCISecure')
      }, 1000)
    } catch (error) {
      console.error('Error updating isChecked in Firestore:', error)
      setIsLoading(false)
    }
  }

  const handlePageChange = (e: PageChangeEvent) => {
    // Get the current page number
    const currentPage = e.currentPage + 1
    // Get the total number of pages
    const totalPages = e.doc.numPages
    console.log(currentPage, totalPages)

    // Check if the current page number equals the total number of pages
    if (currentPage === totalPages) {
      console.log('Last page reached')
      console.log(currentPage, totalPages)
      setLastPage(true)
      // Perform actions or display UI indicators for last page reached
    } else {
      console.log('Not last page')
      setLastPage(false)
    }
  }

  return (
    <>
      <div className='d-flex align-items-center mb-2 mt-0'>
        <Link to='/training'>
          <img src={toAbsoluteUrl(`/media/logos/leftButton.svg`)} alt='back' className='p-2' />
        </Link>
        <Link to='/training' style={{textDecoration: 'none', color: 'inherit'}}>
          <label style={{cursor: 'pointer', paddingLeft: '0px'}}>
            <strong>Back To Training</strong>
          </label>
        </Link>
      </div>
      <OnDashPageTitle
        icon='secu.svg'
        title='Security Awareness Training'
        desc='Safeguard your digital workspace.'
      />

      <div className='card'>
        <div className='row '>
          <div className='col-md-12'>
            <div className='row m-0 '>
              {/* Left Column (Recent Posts) */}
              <div className='col-md-3 border-end h-100 ' style={{minHeight: '500px'}}>
                <h2 className='text-dark mb-1 mt-3 ml-4 p-4'>Contents</h2>

                <hr />
                {fileData.map((file, index) => (
                  <div
                    className={`d-flex align-items-start mb-2 p-3 ${
                      selectedPDFUrl === file.downloadURL ? 'bg-light cursor-pointer' : ''
                    }`}
                    key={index}
                    onClick={() => handleNameClick(file.downloadURL, index)}
                  >
                    {/* {filteredData && filteredData.length > 0 ? (
                      <input
                        type='checkbox'
                        className='mt-2 form-check-input p-3'
                        // checked={filteredData[0]?.SAT?.some((file) => file.isChecked)}
                        checked={completed || false}
                        onClick={() => handleCheckboxChange(index)}
                        disabled
                      />
                    ) : ( */}
                    <input
                      type='checkbox'
                      className='mt-2 form-check-input p-3'
                      checked={completed || file.isChecked}
                      onClick={() => handleCheckboxChange(index)}
                      disabled={completed}
                    />
                    {/* )} */}

                    <div className='ms-4 cursor-pointer'>
                      <div className='text-dark fw-bold text-hover-primary fs-5 mt-2'>
                        {file.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* {fileData.map((file, index) => ( */}
              <div className='col-md-9 px-0'>
                <div className='mb-0'>
                  <div
                    className='mb-0'
                    // style={{marginRight: '-12px', marginLeft: '-13px', marginTop: '-13px'}}
                  >
                    <div className='overlay '>
                      {selectedPDFUrl ? (
                        <div className='container-fluid'>
                          <div className='row'>
                            <div className='col p-0' style={{height: 600}}>
                              <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                                <Viewer
                                  fileUrl={selectedPDFUrl}
                                  plugins={[
                                    defaultLayoutPluginInstance,
                                    pageNavigationPluginInstance,
                                  ]}
                                  onPageChange={handlePageChange}
                                  // onLoad={() => handlePageChange()}
                                />
                                {/* {currentPage === totalPages && <button>Last Page Reached!</button>} */}
                              </Worker>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='container-fluid'>
                            <div className='row'>
                              <div className='col ' style={{height: 600, marginTop: '20px'}}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                  }}
                                >
                                  <div className='spinner-border text-primary' role='status'>
                                    <span className='visually-hidden'>Loading...</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className='border p-4 ml-3 '>
                  {completed ? (
                    <button
                      type='button'
                      className='btn me-3 btn-sm fs-6'
                      disabled
                      style={{backgroundColor: '#1CBB8D', color: '#fff', height: '40px'}}
                    >
                      Completed
                    </button>
                  ) : lastPage ? (
                    <button
                      type='button'
                      className='btn me-3 btn-sm fs-6'
                      style={{backgroundColor: '#1CBB8D', color: '#fff', height: '40px'}}
                      onClick={() => handleCheckboxChange(selectedFileIndex)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M5 12l5 5l10 -10' />
                          </svg>
                          Mark as Complete
                        </>
                      )}
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn me-3 btn-sm fs-6'
                      style={{backgroundColor: '#A2E3D0', color: '#fff', height: '40px'}}
                      onClick={() => handleCheckboxChange(selectedFileIndex)}
                      disabled
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M5 12l5 5l10 -10' />
                      </svg>
                      Mark as Complete
                    </button>
                  )}
                  <span className='p-1 fs-6 text-muted'>
                    {' '}
                    Please note that you are required to read all the training material in order to
                    complete the training.{' '}
                  </span>
                </div>
                {/* {fileData && (
                  <div className='d-flex justify-content-between p-4'>
                    {selectedFileIndex > 0 && (
                      <img
                        src={toAbsoluteUrl('/media/buttons/PreviousChapter.svg')}
                        alt=''
                        className='p-1 py-3'
                        onClick={handlePreviousChapterClick}
                      />
                    )}
                    {selectedFileIndex < fileData.length - 1 && (
                      <img
                        src={toAbsoluteUrl('/media/buttons/NextChapter.svg')}
                        alt=''
                        className='p-1'
                        onClick={handleNextChapterClick}
                      />
                    )}
                  </div>
                )} */}
                {/* <div className='col-md-12 d-flex justify-content-end  p-4'>
                  {(allChecked || completed) && (
                    <a href='/training/HIPAA'>
                      <button
                        className='btn btn-primary btn-sm '
                        // onClick={() => (window.location.href = '/training/PCI')}
                      >
                        Go to Another Training
                      </button>
                    </a>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SAT
