import React, {useEffect, useState} from 'react'
import {PersonnelPageTitle} from '../../../../_metronic/layout/core/PersonnelPageData'
import {useAuth} from '../../../modules/auth'
import db from '../../../../services/firebase'
import {PageLink} from '../../../../_metronic/layout/core'
import {KTCard, KTIcon} from '../../../../_metronic/helpers'
import {AddPersonnelGroupModal} from '../components/AddPersonnelGroup'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import Swal from 'sweetalert2'
import {Button, Form} from 'react-bootstrap'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface UserData {
  uid: string
  id: string
  fullName: string
  role: string
  avatarUrl: string
  tenantId: string
  policiesAccepted?: boolean
  groupName: string
  checklist: string
  createdOn: string
}

interface Group {
  id: string
  group: string
  // Add any other properties of the group object here
}
const PersonnelGroup = () => {
  const [users, setUsers] = useState<UserData[]>([])
  const [totalPersonnel, setTotalPersonnel] = useState(0)
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<UserData[]>([])
  const [newName, setNewName] = useState('')
  const [currentId, setCurrentId] = useState(null)
  const [show, setShow] = useState(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [checklists, setCheckists] = useState<any>([])
  const [groupCounts, setGroupCounts] = useState({} as {[key: string]: number})
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [totalgroup, setTotalgroup] = useState(0)
  const [editingId, setEditingId] = useState<string | null>(null)
  const [folderName, setFolderName] = useState<string>('')

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getPersonnelGroup()
  }, [])
  // console.log(users)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser?.uid) {
          const policyManagementCollection = collection(db, 'PersonnelGroupManagement')
          const q = query(policyManagementCollection, where('uid', '==', currentUser?.uid))
          const snapshot = await getDocs(q)
          const data = snapshot.docs.map((doc) => doc.data())
          // console.log('d', data)
          // setIsCheckedValues(data.map((item) => item.isChecked))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [currentUser?.uid])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'personnel_checklist')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setCheckists(risksData)
    })

    return unsubscribe

    // fetchGroups()
  }, [])

  useEffect(() => {
    setFilteredItems(users)
  }, [users])

  useEffect(() => {
    if (!currentUser?.tenantId) {
      return // Exit if there's no tenantId
    }

    const fetchGroups = async () => {
      const tenantId = currentUser.tenantId // Using the tenantId from currentUser
      const tenantRef = doc(db, 'tenants', tenantId!)
      const policyRef = collection(tenantRef, 'employees')

      // Listen for real-time updates
      const unsubscribe = onSnapshot(policyRef, (snapshot) => {
        const groupsData = snapshot.docs.map((doc) => ({
          ...(doc.data() as Group),
          id: doc.id,
        }))

        // Count the groups with the same checklist name
        const groupCounts = groupsData.reduce((counts, group) => {
          const checklistName = group.group || '[Default]' // Handle missing checklist property
          counts[checklistName] = (counts[checklistName] || 0) + 1
          return counts
        }, {})

        setGroupCounts(groupCounts)
      })

      // Cleanup subscription on unmount
      return () => unsubscribe()
    }

    fetchGroups()
  }, [currentUser?.tenantId])

  const getPersonnelGroup = () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'personnel_group')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...(doc.data() as UserData),
          id: doc.id,
        }))
        setUsers(risksData)
        setTotalPersonnel(risksData.length)
        setTotalgroup(risksData.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  const handleChange = async (userId: string, e: React.ChangeEvent<HTMLSelectElement>) => {
    const newChecklist = e.target.value

    const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
    const tenantRef = doc(db, 'tenants', tenantId)

    const assetRef = doc(tenantRef, 'personnel_group', userId)
    await updateDoc(assetRef, {checklist: newChecklist})

    // await personnelGroupServices.updatePersonnel(userId, {
    //   checklist: newChecklist,
    // })
  }

  const usersWithSameTenantId = users.filter((user) => user.tenantId === currentUser?.tenantId)

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(users)
      setSearchLength(false)
    } else {
      const filteredItems = users.filter(
        (user) => user.groupName && user.groupName.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const handleOpen = (id) => {
    setCurrentId(id)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }
  // console.log(users)
  const handleDeleteClick = async (item) => {
    try {
      if (totalgroup <= 1) {
        throw new Error('Cannot delete the last group. At least one group must remain.')
      }

      const result = await Swal.fire({
        icon: 'warning',
        title: `Delete ${item?.groupName} ?`,
        text: `Are you sure you want to delete the group '${item?.groupName}'? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // User confirmed, proceed with the deletion
        // await personnelGroupServices.deletePersonnel(item.id)
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'personnel_group')
        const docRef = doc(policyRef, item.id)
        await deleteDoc(docRef)

        const currentDate = new Date() // No need to import the Firestore module
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1 // Note: The month is zero-based
        const day = currentDate.getDate()

        const todaysDate = month + '/' + day + '/' + year

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `Group '${item?.groupName}' has been deleted by ${currentUser?.userName}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          action: 'Group deleted',
          target: 'Employees',
          date: todaysDate,
        })
        // getPolicy()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${item?.groupName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: (error as Error).message || 'An error occurred while deleting the policy.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }

  const handleRenameGroup = async () => {
    if (newName === null || newName === '') {
      // User clicked "Cancel" or entered an empty name, do nothing
      return
    }
    const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
    const tenantRef = doc(db, 'tenants', tenantId)

    const assetRef = doc(tenantRef, 'personnel_group', currentId!)
    await updateDoc(assetRef, {groupName: newName})

    // const updatedPersonnel = {groupName: newName}
    // await personnelGroupServices.updatePersonnel(currentId, updatedPersonnel)
    setNewName('')
    setShow(false)
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)))
  }

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked)
    if (e.target.checked) {
      setCheckedItems(filteredItems.map((item) => item.id))
    } else {
      setCheckedItems([])
    }
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleCheckedRemoveClick = () => {
    checkedItems.forEach(async (id) => {
      try {
        if (totalgroup <= 1) {
          throw new Error('Cannot delete the last group. At least one group must remain.')
        }

        // Get the group document
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'personnel_group')
        const groupDoc = await getDoc(doc(policyRef, id))

        // Check if the group name is not "Default"
        if (groupDoc.exists() && groupDoc.data().name !== 'Default') {
          const result = await Swal.fire({
            icon: 'warning',
            title: `Remove all checked groups ?`,
            text: `Are you sure you want to remove checked this group? This action cannot be undone.`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Remove',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })

          if (result.isConfirmed) {
            // User confirmed, proceed with the deletion
            const docRef = doc(policyRef, id)
            await deleteDoc(docRef)
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: ` Groups has been removed.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            })
          }
        } else {
          console.log(`Skipping deletion for group with ID ${id}`)
        }
      } catch (error) {
        console.error('Error deleting policy:', error)
        // Handle the error or show an error message to the user
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: (error as Error).message || 'An error occurred while deleting the group.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      } finally {
        setDropdownVisible(false)
      }
    })
  }

  const handleRename = async (id: string) => {
    if (!folderName.trim()) {
      // Do nothing if the new name is empty
      return
    }
    // Update the document in the database (assuming you have Firebase setup)
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const assetRef = doc(tenantRef, 'personnel_group', id)
    await updateDoc(assetRef, {groupName: folderName})

    // Reset states
    setFolderName('')
    setEditingId(null)
  }

  const handleEditClick = (id: string, currentName: string) => {
    setEditingId(id)
    setFolderName(currentName)
  }

  const handleCancel = () => {
    setFolderName('')
    setEditingId(null)
  }

  return (
    <>
      <PersonnelPageTitle
        icon='Icon.svg'
        title='Employees'
        // desc={totalgroup.toString() + ' Groups'}
        desc='Manage your employees to ensure compliance and simplify audits'
        breadcrumbs={usersBreadcrumbs}
      >
        Personnel Management
      </PersonnelPageTitle>
      <KTCard>
        <div>
          <div className='card-header border-0 pt-6 h-200'>
            <div className='card-title'>
              {/* begin::Search */}
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control  w-250px ps-14'
                  placeholder='Search Group..'
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>

              {/* end::Search */}
            </div>
            <div className='card-toolbar'>
              {/* begin::Group actions */}
              {checkedItems.length > 0 ? (
                <>
                  {checkedItems.length > 0 ? (
                    <div className=' m-1 border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                      <span>
                        <p className='pt-4'>{checkedItems.length} Selected</p>
                      </span>
                    </div>
                  ) : null}

                  <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                    <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                  </div>

                  {/* <div
                    className='m-2'
                    style={{cursor: 'pointer'}}
                    onClick={handleCheckedRemoveClick}
                  >
                    <img src='/media/buttons/offboard.svg' alt='Offboard Employee' />
                  </div> */}
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={handleCheckedRemoveClick}
                  >
                    Remove Group
                  </button>
                </>
              ) : (
                <AddPersonnelGroupModal />
              )}

              {/* end::Group actions */}
            </div>
          </div>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive ' style={{height: '55vh', overflowY: 'auto'}}>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 '>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='selectAll'
                          data-kt-check='true'
                          data-kt-check-target='.widget-9-check'
                          onChange={handleSelectAllChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-350px'>GROUP</th>
                    <th className='min-w-100px'>NO. OF EMPLOYEES</th>
                    <th className='min-w-100px'>CREATED ON</th>
                    <th className='min-w-100px'>CREATED BY</th>
                    <th className='min-w-200px'>CHECKLIST</th>
                    <th className='min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}

                {filteredItems && filteredItems.length > 0 ? (
                  filteredItems.map((item) => (
                    <tbody>
                      <tr>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value={item.id}
                              checked={selectAll || checkedItems.includes(item.id)}
                              onChange={handleCheckChange}
                            />
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center fw-bold'>
                            <div className='symbol symbol-20px me-15'>
                              <div className=' fs-7'>
                                {editingId === item.id ? (
                                  <>
                                    <div className='d-flex align-items-center'>
                                      <Form.Control
                                        type='text'
                                        id='kt_file_manager_rename_input'
                                        name='rename_folder_name'
                                        placeholder='Enter the new folder name'
                                        className='mw-250px me-3'
                                        style={{width: '100%', height: '40px'}}
                                        value={folderName}
                                        onChange={(e) => setFolderName(e.target.value)}
                                      />
                                      <Button
                                        className='btn btn-icon btn-sm btn-light-primary me-3'
                                        onClick={() => handleRename(item.id)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='24'
                                          height='24'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='currentColor'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-3'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M5 12l5 5l10 -10' />
                                        </svg>
                                      </Button>
                                      <Button
                                        className='btn btn-icon btn-sm btn-light-danger'
                                        onClick={handleCancel}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='24'
                                          height='24'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='currentColor'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-x mx-3'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M18 6l-12 12' />
                                          <path d='M6 6l12 12' />
                                        </svg>
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <div className='d-flex align-items-center fw-bold'>
                                    <div className='symbol symbol-20px me-15'>
                                      <div className='fs-7'>
                                        <span>{item.groupName}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center justify-content-center fw-bold'>
                            <div className='symbol symbol-20px me-15'>
                              <div className=' fs-7 '>
                                <div>{groupCounts[item.groupName] || '-'}</div>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center fw-bold'>
                            <div className='symbol symbol-20px me-15' style={{paddingLeft: '10%'}}>
                              <div className=' fs-7'>
                                <div>{item.createdOn}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center fw-bold'>
                            <div className='symbol symbol-20px me-15'>
                              <div className=' fs-7'>
                                <div>{currentUser?.userName}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <select
                            className='form-select form-select-sm'
                            data-control='select2'
                            data-placeholder='Default Checklist'
                            style={{width: '100%', height: '40px'}}
                            defaultValue='Default Checklist'
                            value={item.checklist}
                            onChange={(e) => handleChange(item.id, e)}
                          >
                            {checklists
                              .filter((user) => user.tenantId === currentUser?.tenantId)
                              .map((checklist: any) => (
                                <option key={checklist.id} value={checklist.Checklist_name}>
                                  {checklist.checklist_name}
                                </option>
                              ))}
                          </select>
                        </td>

                        <td className=''>
                          <div className='dropdown mx-2'>
                            <button
                              className='btn btn-secondary border-0 p-0 m-2'
                              type='button'
                              id='dropdownMenu2'
                              data-bs-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <img
                                src='/media/buttons/Options.svg'
                                alt='Options'
                                onClick={toggleDropdown}
                              />
                            </button>

                            <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                              <li>
                                <button
                                  className='dropdown-item text-muted text-hover-primary'
                                  type='button'
                                  onClick={() => handleEditClick(item.id, item.groupName)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-2'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                    <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                    <path d='M16 5l3 3' />
                                  </svg>
                                  Rename Group
                                </button>
                              </li>

                              <li>
                                <button
                                  className='dropdown-item'
                                  type='button'
                                  style={{color: '#F15363'}}
                                  onClick={(e) => handleDeleteClick(item)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M4 7l16 0' />
                                    <path d='M10 11l0 6' />
                                    <path d='M14 11l0 6' />
                                    <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                    <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                  </svg>{' '}
                                  Delete group
                                </button>
                              </li>
                            </ul>
                          </div>
                          {/* <PolicyListToolbar policyDataId={policyData.id} /> */}
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tbody>
                    <tr style={{height: '100%'}}>
                      {searchLength ? (
                        <td colSpan={6} className='text-center'>
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No personnel Found</p>
                            </div>
                            <p className=''>
                              We couldn't find any personnel group that match your search. Maybe try
                              a different search!
                            </p>
                          </div>
                        </td>
                      ) : (
                        <td colSpan={6} className='text-center'>
                          {showEmptyState && (
                            <div className='m-20'>
                              <img src='/media/buttons/emptystate.svg' alt='' />
                              <div className='m-5 fs-5'>
                                <p style={{fontWeight: 'bold'}}>No personnel Added</p>
                              </div>
                              <p>
                                All the personnel groups within your organization are required to
                                address here.
                              </p>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  </tbody>
                )}

                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </KTCard>
    </>
  )
}

export default PersonnelGroup
