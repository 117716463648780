import {useEffect, useState} from 'react'
import React from 'react'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import {addDoc, collection, doc, onSnapshot, serverTimestamp, updateDoc} from 'firebase/firestore'
import db, {storage} from '../../../../services/firebase'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {ToastContainer, toast} from 'react-toastify'

export interface addAsset {
  msg: string
  message: string
  checked?: boolean | undefined
}

type Props = {
  isOpen: boolean
  onClose: () => void
  id: string
}

const UpdateVendor: React.FC<Props> = ({isOpen, onClose, id}) => {
  const [vendor_name, setVendorName] = useState('')
  const [owner, setVendorOwner] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [website_url, setWebsiteUrl] = useState('')
  const [policy_url, setPolicyUrl] = useState('')
  const [terms_url, setTermsUrl] = useState('')
  const [authentication_type, setAuthenticationType] = useState('')
  const [two_factor, setTwoFactor] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [message, setMessage] = useState({error: false, msg: ''})
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [file, setFile] = useState<File[]>([])
  const [owners, setOwners] = useState<any[]>([])
  const [category, setCategory] = useState('')
  const [uploadedFile, setUploadedFile] = useState<{name: string; size: number} | null>(null)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [risk_level, setRiskLevel] = useState('')
  const [reliance, setReliance] = useState('')
  const [logos, setLogos] = useState<{url: string; name: string; size: number}[]>([])
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})

  useEffect(() => {
    getVendor()
  }, [id])

  console.log(logos)

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }

    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  const getVendor = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' 
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'vendors')
      const riskDocRef = doc(PolicyRef, id)

      const unsubscribe = onSnapshot(riskDocRef, (doc) => {
        if (doc.exists()) {
          const vendorData = doc.data()
          setVendorName(vendorData.vendor_name)
          setWebsiteUrl(vendorData.website_url)
          setCategory(vendorData.category)
          setPolicyUrl(vendorData.policy_url)
          setTermsUrl(vendorData.terms_url)
          setVendorOwner(vendorData.owner)
          setAuthenticationType(vendorData.authentication_type)
          setTwoFactor(vendorData.two_factor)
          setSelectedCheckboxes(vendorData.impact_assessment)
          setReliance(vendorData.reliance)
          setRiskLevel(vendorData.risk_level)
          setLogos(vendorData.logo)
        } else {
          console.log('Risk document does not exist')
        }
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting risk:', error)
    }
  }

  const currentDate = new Date() // No need to import the Firestore module
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1 // Note: The month is zero-based
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!vendor_name.trim()) {
      errors.vendor_name = 'Vendor name is required'
    }

    if (!website_url.trim()) {
      errors.website_url = 'Vendor website url is required'
    }

    if (!policy_url.trim()) {
      errors.policy_url = 'Policy Url is required'
    }

    if (!category.trim()) {
      errors.category = 'Vendor category is required'
    }
    if (!terms_url.trim()) {
      errors.terms_url = 'Term Url is required'
    }

    if (!owner.trim()) {
      errors.vendor_owner = 'Owner is required'
    }

    if (!authentication_type.trim()) {
      errors.authentication_type = 'Authentication type is required'
    }

    // if (!selectedCheckboxes.trim()) {
    //   errors.treatment = 'Treatment plan is required'
    // }

    if (!reliance.trim()) {
      errors.reliance = 'Vendor reliance is required'
    }

    if (!risk_level.trim()) {
      errors.riskLevel = 'Vendor risk level is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      let uploadedLogoUrl = ''
      if (file.length > 0) {
        const fileName = `${Date.now()}_${file[0].name}`
        const fileRef = ref(storage, `tenants/${currentUser?.tenantId}/vendorsLogo/${fileName}`)

        // Upload file
        await uploadBytes(fileRef, file[0])

        uploadedLogoUrl = await getDownloadURL(fileRef)
        if (uploadedFile) {
          const newLogos = [
            {url: uploadedLogoUrl, name: uploadedFile.name, size: uploadedFile.size},
          ]
          setLogos(newLogos)
        }
      }

      const newVendor = {
        vendor_name,
        category,
        owner,
        website_url,
        logo: uploadedLogoUrl
          ? [{url: uploadedLogoUrl, name: file[0].name, size: file[0].size}]
          : logos,
        policy_url,
        terms_url,
        authentication_type,
        two_factor,
        impact_assessment: selectedCheckboxes,
        risk_level,
        reliance,

        uid: currentUser?.uid,
        tenantId: currentUser?.tenantId,
      }

      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const vendorsRef = doc(tenantRef, 'vendors', id)

        // Add new vendor document
        await updateDoc(vendorsRef, {
          ...newVendor,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })

        // Add activity log
        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} updated vendor '${vendor_name}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          action: 'Vendor updated',
          target: 'Vendor Risk',
          username: currentUser?.userName,
          date: todaysDate,
          email: currentUser?.email,
        })

        toast.success('Vendor updated successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
        setMessage({error: false, msg: 'Vendor updated successfully'})
        setShowAlert(true)
        setTimeout(() => {
          onClose()
          setIsLoading(false)
          setShowAlert(false)
        }, 1000)
      }
    } catch (err: any) {
      setMessage({error: true, msg: err.message})
      setShowAlert(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpload = (acceptedFiles: File[]) => {
    const imageFile = acceptedFiles.find(
      (file) =>
        file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml'
    )
    if (imageFile) {
      setFile([imageFile])
      setLogos([{url: '', name: imageFile.name, size: imageFile.size}])
      setUploadedFile({
        name: imageFile.name,
        size: imageFile.size,
      })
    } else {
      // Handle error: no valid image file found
      setMessage({error: true, msg: 'Please upload a valid image file (jpeg, png, or svg).'})
      setShowAlert(true)
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (checked) {
      setSelectedCheckboxes((prev) => [...prev, name]);
    } else {
      setSelectedCheckboxes((prev) => prev.filter((item) => item !== name));
    }
  };

  const handleRemoveFile = () => {
    setLogos([])
  }

  const handleRiskLevel = (e: {target: {value: any}}) => {
    setRiskLevel(e.target.value)
  }
  const handleRelianceChange = (e: {target: {value: any}}) => {
    setReliance(e.target.value)
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className='border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Edit Vendor
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
              style={{
                transition: 'all 0.2s ease',
              }}
              onMouseEnter={(e) => {
                if (e.currentTarget) {
                  e.currentTarget.style.backgroundColor = '#fff'
                  const svgElement = e.currentTarget.querySelector('svg')
                  if (svgElement) {
                    svgElement.style.stroke = '#1CBB8D'
                  }
                }
              }}
              onMouseLeave={(e) => {
                if (e.currentTarget) {
                  e.currentTarget.style.backgroundColor = '#fff'
                  const svgElement = e.currentTarget.querySelector('svg')
                  if (svgElement) {
                    svgElement.style.stroke = '#5A5A5A'
                  }
                }
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                {showAlert && (
                  <div style={{marginTop: 0, marginLeft: 0, marginRight: 0}}>
                    {message?.msg && (
                      <div
                        className={
                          message?.error
                            ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                            : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                        }
                      >
                        {message?.error ? (
                          <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        ) : (
                          <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        )}

                        <div
                          className={
                            message?.error
                              ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                              : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                          }
                        >
                          {message?.error ? (
                            <h5 className='mb-1'>Error!!</h5>
                          ) : (
                            <h5 className='mb-1'>Sucess!!</h5>
                          )}
                          <span>{message?.msg}</span>
                        </div>
                        <button
                          type='button'
                          className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                          onClick={() => setShowAlert(false)}
                        >
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className='mh-50px'
                              >
                                <rect
                                  opacity='0.5'
                                  x='6'
                                  y='17.3137'
                                  width='16'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 6 17.3137)'
                                  fill='black'
                                ></rect>
                                <rect
                                  x='7.41422'
                                  y='6'
                                  width='16'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 7.41422 6)'
                                  fill='black'
                                ></rect>
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <div className=''>
                  <div className='fw-bold fs-6 mb-4'>Main Information</div>
                  <div className='form-group mb-4'>
                    <label className='fw-semibold fs-6 my-1 required'>Vendor name</label>
                    <input
                      type='text'
                      className='form-control'
                      value={vendor_name}
                      placeholder='Enter Vendor name'
                      onChange={(e) => {
                        setVendorName(e.target.value)
                        setFormErrors((prev) => ({...prev, vendor_name: ''}))
                      }}
                    />
                    {formErrors.vendor_name && (
                      <span className='text-danger'>{formErrors.vendor_name}</span>
                    )}
                  </div>
                  <div className='form-group mb-4'>
                    <label className=' fw-semibold fs-6 my-1 required'>Website</label>
                    <input
                      type='url'
                      className='form-control'
                      value={website_url}
                      placeholder='Enter website URL'
                      onChange={(e) => {
                        setWebsiteUrl(e.target.value)
                        setFormErrors((prev) => ({...prev, website_url: ''}))
                      }}
                    />
                    {formErrors.website_url && (
                      <span className='text-danger'>{formErrors.website_url}</span>
                    )}
                  </div>
                  {/* <div className='form-group mb-4'>
                    <label className='fw-semibold fs-6 my-1 required'>Logo</label>
                    {logos && logos.length > 0 ? (
                      <div className={`card mb-4 mb-xl-8 mt-4`}>
                        <div
                          className='card-header border-0 pt-6 mb-5'
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img
                              src='/media/icon/file2.svg'
                              alt=''
                              className='img-fluid'
                              style={{width: '50px', height: '50px', marginRight: '10px'}}
                            />
                            <div className='d-flex justify-content-start flex-column fw-bold fs-6'>
                              {logos[0].name}
                              <span className='fw-semibold d-block fs-6' style={{color: '#5A5A5A'}}>
                                Size: {(logos[0].size / 1024).toFixed(2)} KB
                              </span>
                            </div>
                          </div>
                          <div>
                            {' '}
                            <span className='mx-1 fs-7'>Uploaded</span>{' '}
                            <span className='btn btn-icon btn-sm btn-active-light-primary'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 24 24'
                                fill='#5A5A5A'
                                className='icon icon-tabler icons-tabler-filled icon-tabler-circle-x'
                                onClick={handleRemoveFile}
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z' />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Dropzone
                        onDrop={handleUpload}
                        minSize={1024} // 1 KB
                        maxSize={5 * 1024 * 1024} // 5 MB
                        accept={{
                          'image/jpeg': ['.jpg', '.jpeg'],
                          'image/png': ['.png'],
                          'image/svg+xml': ['.svg'],
                        }}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div
                            {...getRootProps({
                              className: 'dropzone',
                              style: {
                                backgroundColor: '#fff',
                                padding: '20px',
                                border: '2px dashed #ccc',
                                borderRadius: '4px',
                                textAlign: 'center',
                                cursor: 'pointer',
                              },
                            })}
                          >
                            <input {...getInputProps()} />
                            <img
                              src='/media/icon/uploadFile.svg'
                              alt=''
                              loading='lazy'
                              className='mb-4 mt-4'
                            />
                            <p className='fw-bold fs-6'>
                              Drag and drop or <a href='#'>select</a> a file
                            </p>
                            <p className='fs-6'>
                              Upload up to 5 MB, 250 X 250. Supported formats: jpeg, png or svg.
                            </p>
                          </div>
                        )}
                      </Dropzone>
                    )}
                  
                  </div> */}

                  <div className='form-group mb-4'>
                    <label className='fw-semibold my-1 fs-6 required'>Category</label>

                    <select
                      name='vategory'
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Select Category'
                      onChange={(e) => {
                        setCategory(e.target.value)
                        setFormErrors((prev) => ({...prev, category: ''}))
                      }}
                      value={category}
                    >
                      <option selected>Select category</option>

                      <option value='Cloud Providers'>Cloud Services</option>
                      <option value='Business Suite'>Business Suite</option>
                      <option value='Background Checks'>Background Checks</option>
                      <option value='Device Management'>Device Management</option>
                      <option value='Developer Tools'>Developer Tools</option>
                      <option value='Endpoint Security'>Endpoint Security</option>
                      <option value='Human Resources'>Human Resources</option>
                      <option value='Password Management'>Password Management</option>
                      <option value='Single Sign-On'>Single Sign On</option>
                      <option value='Project Manager'>Project Manager</option>
                      <option value='Vulnerability Management'>Vulnerability Management</option>
                      <option value='Other'>Other</option>
                    </select>
                    {formErrors.category && (
                      <span className='text-danger'>{formErrors.category}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className=' fw-semibold fs-6 my-1 required'>Pivacy policy URL</label>
                    <input
                      type='url'
                      className='form-control'
                      value={policy_url}
                      placeholder='Enter pivacy policy URL'
                      onChange={(e) => {
                        setPolicyUrl(e.target.value)
                        setFormErrors((prev) => ({...prev, policy_url: ''}))
                      }}
                    />
                    {formErrors.policy_url && (
                      <span className='text-danger'>{formErrors.policy_url}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className=' fw-semibold fs-6 my-1 required'>
                      Terms & conditions URL
                    </label>
                    <input
                      type='url'
                      className='form-control'
                      value={terms_url}
                      placeholder='Enter terms & conditions URL'
                      onChange={(e) => {
                        setTermsUrl(e.target.value)
                        setFormErrors((prev) => ({...prev, terms_url: ''}))
                      }}
                    />
                    {formErrors.terms_url && (
                      <span className='text-danger'>{formErrors.terms_url}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className='fw-semibold my-1 fs-6 required'>Owner</label>

                    <select
                      name='vendorOwner'
                      className='form-select '
                      data-control='select2'
                      data-placeholder='Select Owner'
                      onChange={(e) => {
                        setVendorOwner(e.target.value)
                        setFormErrors((prev) => ({...prev, owner: ''}))
                      }}
                      value={owner}
                    >
                      <option selected value=''>
                        Select owner
                      </option>
                      {owners
                        .filter((user) => user.role === 'Admin')
                        .map((owner) => (
                          <option key={owner.id} value={owner.full_name}>
                            {owner.full_name}
                          </option>
                        ))}
                    </select>
                    {formErrors.vendor_owner && (
                      <span className='text-danger'>{formErrors.vendor_owner}</span>
                    )}
                  </div>

                  <div className='form-group mb-4'>
                    <label className=' fw-semibold fs-6 my-1 required'>Authentication type</label>
                    <label className='form-check form-check-custom py-2'>
                      <input
                        className='form-check-input'
                        type='radio'
                        // checked={true}
                        value='password'
                        name='authenticationType'
                        checked={authentication_type === 'password'}
                        onChange={(e) => {
                          setAuthenticationType(e.target.value)
                          setFormErrors((prev) => ({...prev, authentication_type: ''}))
                        }}
                        style={formErrors.authentication_type ? {border: '1px solid red'} : {}}
                      />

                      <span style={{marginLeft: 10, marginRight: 20}} className='fs-6'>
                        {' '}
                        Password
                      </span>
                    </label>
                    <label className='form-check form-check-custom py-2'>
                      <input
                        className='form-check-input'
                        type='radio'
                        // checked={false}
                        value='Single sign-in'
                        name='authenticationType'
                        checked={authentication_type === 'Single sign-in'}
                        onChange={(e) => {
                          setAuthenticationType(e.target.value)
                          setFormErrors((prev) => ({...prev, authentication_type: ''}))
                        }}
                        style={formErrors.authentication_type ? {border: '1px solid red'} : {}}
                      />
                      <span style={{marginLeft: 10, marginRight: 20}} className='fs-6'>
                        Single sign-in
                      </span>
                    </label>
                    {formErrors.authenticationType && (
                      <span className='text-danger'>{formErrors.authenticationType}</span>
                    )}
                  </div>

                  <div className='form-group'>
                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='twoFactorEnable'
                        checked={two_factor}
                        onChange={(e) => setTwoFactor(e.target.checked)}
                      />
                      <label className='fw-semibold mx-4 fs-6'>
                        Two factor authentication enabled
                      </label>
                    </div>
                  </div>

                  <div className='separator my-8'></div>
                  <div className='form-group mb-4'>
                    <h4 className='fw-bold'>Impact Assessment</h4>
                    <span className='fs-6 required'>
                      What type of data does system store, process or interface with?
                    </span>
                    <div>
                      <label className='fw-semibold fs-6 mt-3'>Publicly available data</label>

                      <div className='checkbox-list'>
                        <label className='form-check form-check-custom form-check-solid mx-2 my-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='Generic, informational, or marketing material'
                            style={{width: 15, height: 15, marginRight: 10}}
                            checked={selectedCheckboxes?.includes('Generic, informational, or marketing material') || false}
                            onChange={handleCheckboxChange}
                          />
                          Generic, informational, or marketing material
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Data that is openly available to the public without any
                                  restrictions, such as company brochures, marketing materials,
                                  press releases, etc.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>
                      </div>
                      <label className='fw-semibold fs-6'>Confidential company information</label>

                      <div className='checkbox-list'>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='Employment records'
                            style={{width: 15, height: 15, marginRight: 10}}
                            checked={selectedCheckboxes?.includes('Employment records')|| false}
                            onChange={handleCheckboxChange}
                          />
                          <span></span>
                          Employment records{' '}
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Data related to employees, such as personal details, employment
                                  history, payroll information, performance reviews, and other
                                  HR-related data.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='Corporative proprietary information'
                            style={{width: 15, height: 15, marginRight: 10}}
                            checked={selectedCheckboxes?.includes(
                              'Corporative proprietary information'
                            )|| false}
                            onChange={handleCheckboxChange}
                          />
                          <span></span>
                          Corporative proprietary information{' '}
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Sensitive business information that is proprietary to the company,
                                  such as internal project details, financial records, strategic
                                  plans, and intellectual property.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>
                      </div>

                      <label className=' fw-semibold fs-6'>Customer information</label>

                      <div className='checkbox-list'>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='Public customer information'
                            style={{width: 15, height: 15, marginRight: 10}}
                            checked={selectedCheckboxes?.includes('Public customer information')|| false}
                            onChange={handleCheckboxChange}
                          />
                          <span></span>
                          Public customer information{' '}
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Data about customers that is publicly available, such as customer
                                  testimonials, public profiles, or information shared openly by the
                                  customers themselves.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='Non-public customer information'
                            style={{width: 15, height: 15, marginRight: 10}}
                            checked={selectedCheckboxes?.includes('Non-public customer information')|| false}
                            onChange={handleCheckboxChange}
                          />
                          <span></span>
                          Non-public customer information{' '}
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Data about customers that is not publicly accessible such as
                                  personal contact information, purchase history, account details,
                                  and any other confidential customer data.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>
                      </div>

                      <label className='fw-semibold fs-6'>Other information</label>

                      <div className='checkbox-list'>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='Card holder information'
                            style={{width: 15, height: 15, marginRight: 10}}
                            checked={selectedCheckboxes?.includes('Card holder information')|| false}
                            onChange={handleCheckboxChange}
                          />
                          <span></span>
                          Card holder information{' '}
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Data of EU residents that must be protected according to the
                                  General Data Protection Regulation (GDPR). It covers any
                                  information that can be used to identify an individual, such as
                                  names, addresses, email addresses, and IP addresses.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>
                        <label className='form-check form-check-custom form-check-solid m-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='Confidential patient information'
                            style={{width: 15, height: 15, marginRight: 10}}
                            checked={selectedCheckboxes?.includes(
                              'Confidential patient information'
                            )|| false}
                            onChange={handleCheckboxChange}
                          />
                          <span></span>
                          Confidential patient information{' '}
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='tooltip-top'>
                                <span>
                                  Includes medical records, health insurance information, and other
                                  health-related data.
                                </span>
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#5A5A5A'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                              <path d='M12 9h.01' />
                              <path d='M11 12h1v4h1' />
                            </svg>
                          </OverlayTrigger>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className=' fw-bold fs-6 mt-4 '>
                    <span className='required'>Operational Reliance </span>
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            <strong>Operational Reliance</strong>
                            <ul>
                              <li>
                                <strong>Low:</strong> Non-critical services. Business functions
                                without significant impact if disrupted.
                              </li>
                              <li>
                                <strong>Medium:</strong> Important services. Disruptions cause some
                                delays but manageable.
                              </li>
                              <li>
                                <strong>High:</strong> Crucial services. Disruptions significantly
                                impact business operations.
                              </li>
                            </ul>
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </div>
                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      {/* <label className='text-muted'>Operational Reliance</label> */}
                      <label className='form-check form-check-custom form-check-solid py-2'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Low'
                          name='reliance'
                          checked={reliance === 'Low'}
                          onChange={(e) => {
                            setReliance(e.target.value)
                            setFormErrors((prev) => ({...prev, reliance: ''}))
                          }}
                          style={formErrors.reliance ? {border: '1px solid red'} : {}}
                        />

                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>
                      </label>
                      <label className='form-check form-check-custom form-check-solid py-2'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Medium'
                          name='reliance'
                          checked={reliance === 'Medium'}
                          onChange={(e) => {
                            setReliance(e.target.value)
                            setFormErrors((prev) => ({...prev, reliance: ''}))
                          }}
                          style={formErrors.reliance ? {border: '1px solid red'} : {}}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>
                      </label>
                      <label className='form-check form-check-custom form-check-solid pt-2'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='High'
                          name='reliance'
                          checked={reliance === 'High'}
                          onChange={(e) => {
                            setReliance(e.target.value)
                            setFormErrors((prev) => ({...prev, reliance: ''}))
                          }}
                          style={formErrors.reliance ? {border: '1px solid red'} : {}}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                    {formErrors.reliance && (
                      <span className='text-danger'>{formErrors.reliance}</span>
                    )}
                  </div>

                  <div className=' fw-bold fs-6 mt-4 '>
                    <span className='required'>Risk Level </span>
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          <span>
                            Risk Level
                            <ul>
                              <li>
                                <strong>Low:</strong> Minor impact and low likelihood. Manageable
                                consequences.
                              </li>
                              <li>
                                <strong>Medium:</strong> Moderate impact and reasonable likelihood.
                                Some disruptions.
                              </li>
                              <li>
                                <strong>High:</strong> Significant impact and high likelihood.
                                Severe consequences.
                              </li>
                            </ul>
                          </span>
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5A5A5A'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                        <path d='M12 9h.01' />
                        <path d='M11 12h1v4h1' />
                      </svg>
                    </OverlayTrigger>
                  </div>

                  <div className='col-9 col-form-label'>
                    <div className='checkbox-list'>
                      {/* <label>Risk Level</label> */}
                      <label className='form-check form-check-custom form-check-solid py-2'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Low'
                          name='riskLevel'
                          checked={risk_level === 'Low'}
                          onChange={(e) => {
                            setRiskLevel(e.target.value)
                            setFormErrors((prev) => ({...prev, risk_level: ''}))
                          }}
                          style={formErrors.risk_level ? {border: '1px solid red'} : {}}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}> Low</span>
                      </label>
                      <label className='form-check form-check-custom form-check-solid py-2'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Medium'
                          name='riskLevel'
                          checked={risk_level === 'Medium'}
                          onChange={(e) => {
                            setRiskLevel(e.target.value)
                            setFormErrors((prev) => ({...prev, risk_level: ''}))
                          }}
                          style={formErrors.risk_level ? {border: '1px solid red'} : {}}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>Medium</span>
                      </label>
                      <label className='form-check form-check-custom form-check-solid py-2'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='High'
                          name='riskLevel'
                          checked={risk_level === 'High'}
                          onChange={(e) => {
                            setRiskLevel(e.target.value)
                            setFormErrors((prev) => ({...prev, risk_level: ''}))
                          }}
                          style={formErrors.risk_level ? {border: '1px solid red'} : {}}
                        />
                        <span style={{marginLeft: 10, marginRight: 20}}>High</span>
                      </label>
                    </div>
                    {formErrors.riskLevel && (
                      <span className='text-danger'>{formErrors.riskLevel}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top pt-4 pb-8'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button type='button' className='btn btn-light btn-sm mx-2 ' onClick={onClose}>
                Cancel
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  value='Submit'
                  className='btn btn-sm fs-6 '
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '160px',
                    height: '40px',
                    borderRadius: '4px',

                    transition: 'background-color 0.3s',
                  }}
                  disabled
                >
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                  Edit vendor
                </button>
              ) : (
                <button
                  className='btn btn-sm fs-6'
                  // onClick={handleSubmit}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  // disabled={activities.length === 0}
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '120px',
                    height: '40px',
                    borderRadius: '4px',

                    transition: 'background-color 0.3s',
                  }}
                >
                  Edit vendor
                </button>
              )}
            </div>
          </form>
        </div>
      </Drawer>
      <ToastContainer />
    </>
  )
}

export {UpdateVendor}
