import React, {FC, useEffect, useState} from 'react'
import {PageLink} from '../../../_metronic/layout/core'
// import {VendorRisk} from './VendorRisk'
import {PersonnelListHeader} from './components/header/PersonnelListHeader'
import {KTCard} from '../../../_metronic/helpers'
import {PersonnelManagement} from './PersonnelManagement'
import db from '../../../services/firebase'
import { useAuth } from '../../modules/auth'
import { PersonnelPageTitle } from '../../../_metronic/layout/core/PersonnelPageData'
import { collection, doc, onSnapshot } from 'firebase/firestore'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PersonnelManagementWrapper: FC = () => {
  const[totalPersonnel,setTotalPersonnel]= useState(0);
  const {currentUser} = useAuth()

  

  useEffect(() => {
   
    const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')
    
      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTotalPersonnel(risksData.length)
      })

      return unsubscribe // Clean up the subscription when the component unmounts
  }, []);


  return (
    <>
      <PersonnelPageTitle
        icon='Icon.svg'
        title='Employees'
        // desc={totalPersonnel.toString()+ ' Employees'}
        desc='Manage your employees to ensure compliance and simplify audits'
        breadcrumbs={usersBreadcrumbs}
      >
        Personnel Management
      </PersonnelPageTitle>
      <KTCard>
        <PersonnelListHeader />
        <PersonnelManagement className='mb-5 mb-xl-8' allPoliciesChecked={false} />
        {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
      </KTCard>
    </>
  )
}

export default PersonnelManagementWrapper
