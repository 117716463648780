/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import policyServices from '../services/policy.services'
import {format, parse, parseISO} from 'date-fns'
import {useAuth} from '../../../modules/auth'
import {useLocation, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import db from '../../../../services/firebase'
import {Helmet} from 'react-helmet'
import {PolicyListLoading} from '../components/loading/PolicyListLoading'
import {collection, deleteDoc, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import PolicyDrawer from '../components/PolicyDrawer'
import AssignOwner from '../components/AssignOwner'
import {PolicyListToolbar} from '../components/header/PolicyListToolbar'

type Props = {
  className: string
  getPolicyID?: any
  tabIndex?: any
}
type FileItemType = {
  id: string
  policy: string
  storage: string
  frameworks: any
  created_at: any
  owner: string
  fileUrl: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  reviewDate?: string
  updated_at?: string
  status?: string
  last_approved?: string
  author: string
  review_date: string
}
const PolicyLibraryTable: React.FC<Props> = ({getPolicyID}) => {
  const [policy, setPolicy] = useState<FileItemType[]>([])
  const [activeTab, setActiveTab] = useState('policy-library')
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const location = useLocation()
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredPolicies, setFilteredPolicies] = useState<FileItemType[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [policyId, setPolicyId] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<string[]>([])
  const searchParams = new URLSearchParams(location.search)
  const policy_id = searchParams.get('policies')
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 2000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    setFilteredPolicies(policy)
  }, [policy])

  useEffect(() => {
    getPolicy()
  }, [activeFrameworks])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
        console.log(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    if (policy_id) {
      setIsModalOpen(true)
      setPolicyId(policy_id || '')
    }
  }, [policy_id])

  // useEffect(() => {
  //   if (policy_name) {
  //     setSearchTerm(policy_name)
  //     const filteredItems = policy.filter((item) =>
  //       item.policy.toLowerCase().includes(policy_name.toLowerCase())
  //     )
  //     setFilteredPolicies(filteredItems)
  //     setSearchLength(filteredItems.length === 0)
  //   }
  // }, [policy_name, policy])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredPolicies(policy)
      setSearchLength(false)
    } else {
      const filteredItems = policy.filter((user) => user.policy.toLowerCase().includes(searchTerm))
      setFilteredPolicies(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }
  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('PolicyTemplates')) {
      setActiveTab('PolicyTemplates')
    } else {
      setActiveTab('policy-library')
    }

    getPolicy() // Fetch fresh data when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const getPolicy = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'policies')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const policyData = snapshot.docs.map((doc) => ({
          ...(doc.data() as FileItemType),
          id: doc.id,
          framework: doc.data().frameworks,
        }))

        const filteredPolicies = policyData.filter((policy) => {
          if (policy.author === 'Custom policy') return true

          if (!policy.framework || !Array.isArray(policy.framework)) return false

          return policy.framework.some((policyFramework) =>
            activeFrameworks.includes(policyFramework)
          )
        })

        setPolicy(filteredPolicies)
        setShowEmptyState(false)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
      setShowEmptyState(false)
    }
  }

  const formatTimestamp2 = (timestamp) => {
    if (timestamp) {
      let date
      if (typeof timestamp === 'string') {
        // Handle ISO string format
        date = parseISO(timestamp)
      } else if (timestamp.seconds) {
        // Handle Firestore timestamp
        date = new Date(timestamp.seconds * 1000)
      } else {
        // Handle regular Date object
        date = new Date(timestamp)
      }

      if (isNaN(date.getTime())) {
        return 'Invalid Timestamp'
      }

      return format(date, 'd MMMM, yyyy')
    }
    return '--'
  }

  const formatTimestamp = (dateInput) => {
    if (typeof dateInput === 'string') {
      // Handle ISO 8601 date string (e.g., "2024-07-31")
      try {
        const date = parse(dateInput, 'yyyy-MM-dd', new Date())
        return format(date, 'd MMMM, yyyy')
      } catch (error) {
        console.error('Error parsing date string:', error)
        return 'Invalid Date'
      }
    } else if (dateInput && dateInput.seconds) {
      // Handle Firestore timestamp
      return format(new Date(dateInput.seconds * 1000), 'd MMMM, yyyy')
    } else if (dateInput instanceof Date) {
      // Handle JavaScript Date object
      return format(dateInput, 'd MMMM, yyyy')
    } else {
      return 'Invalid Date'
    }
  }

  const toggleDropdown = () => {
    // console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }
  const handleViewClick = (policyData) => {
    // Handle the update action
    const fileUrl = policyData.fileUrl

    // Create a hidden link
    const link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'
    link.download = `${policyData.policy}.pdf`

    // Append the link to the document
    document.body.appendChild(link)

    // Programmatically trigger a click on the link
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)

    setDropdownVisible(false)
  }

  const handleDownloadClick = async (policyData: FileItemType) => {
    try {
      // Assuming policyData.fileUrl contains the URL to the policy file
      const fileUrl = policyData.fileUrl

      // Fetch the file content
      const response = await fetch(fileUrl)
      const fileContent = await response.blob()

      // Create a Blob with the file content
      const blob = new Blob([fileContent], {type: 'application/octet-stream'})

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob)

      // Create a hidden link
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = `${policyData.policy}.docx`

      // Append the link to the document
      document.body.appendChild(link)

      // Programmatically trigger a click on the link
      link.click()

      // Remove the link and revoke the Blob URL from the document
      document.body.removeChild(link)
      URL.revokeObjectURL(blobUrl)

      setDropdownVisible(false)
    } catch (error) {
      console.error('Error downloading policy file:', error)
    }
  }

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSelectAllChecked(isChecked)

    // Update the isChecked property for each item in the items array
    const updatedItems = policy.map((item) => ({
      ...item,
      isChecked: isChecked,
    }))

    setSelectedItems(isChecked ? updatedItems.map((item) => item.id) : [])
    setPolicy(updatedItems)
    setIsChecked(isChecked)
  }

  const handleCheckboxChange = async (id: string) => {
    try {
      const updatedItems: FileItemType[] = policy.map((item) => {
        if (item.id === id) {
          return {...item, isChecked: !item.isChecked}
        }
        return item
      })

      setPolicy(updatedItems)

      const newSelectedItems = updatedItems.filter((item) => item.isChecked).map((item) => item.id)
      setSelectedPolicyIds(newSelectedItems)
      setSelectedItems(newSelectedItems)
      setSelectAllChecked(updatedItems.every((item) => item.isChecked))
      setIsChecked(newSelectedItems.length > 0)
    } catch (error) {
      console.error('Error updating database:', error)
    }
  }

  const handleDeleteClick = async (policyData) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${policyData?.policy}`,
        text: `Are you sure you want to remove the '${policyData?.policy}' policy from the organaization? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'policies')
        // User confirmed, proceed with the deletion
        const docRef = doc(policyRef, policyData.id)

        // Delete the document
        await deleteDoc(docRef)

        const testsCollectionRef = collection(tenantRef, 'tests')
        const unsubscribe = onSnapshot(testsCollectionRef, async (snapshot) => {
          const testsData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            policies: doc.data().policies,
          }))
          console.log(testsData)
          const filteredTests = testsData.filter((test) => test?.policies === policyData.policy)
          console.log(filteredTests)

          if (filteredTests.length > 0) {
            for (const test of filteredTests) {
              const testDocRef = doc(tenantRef, 'tests', test.id)

              console.log('testid', test.id)

              try {
                await updateDoc(testDocRef, {
                  status: 'Fail',
                })
                console.log(`Updated test ${test.id} to status 'Fail'`)
              } catch (error) {
                console.error(`Error updating test ${test.id}:`, error)
              }
            }
          }
        })

        getPolicy()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `Policy "${policyData?.policy}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the policy.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }
  const handleDeselectClick = async () => {
    try {
      // Update the local state to deselect all selected items
      setIsChecked(false)
      setSelectAllChecked(false)

      // Clear the selected items array
      setSelectedItems([])
      // Update the isChecked property for each item in the items array
      const updatedItems = policy.map((item) => ({
        ...item,
        isChecked: false,
      }))

      // Update the local state
      setPolicy(updatedItems)

      // Update the database
      // await updateDatabase(updatedItems)
    } catch (error) {
      console.error('Error updating database after deselect:', error)
      // Handle the error or show an error message to the user
    }
  }
  const handleCheckedRemoveClick = async () => {
    try {
      // Filter out the checked items
      const checkedItems = policy.filter((item) => item.isChecked)

      if (checkedItems.length === 0) {
        // No items are checked, show a message or handle accordingly
        console.log('No items selected for removal')
        return
      }

      // Display a confirmation dialog
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Remove Selected Items',
        text: 'Are you sure you want to remove the selected items? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // User confirmed, proceed with the removal
        await Promise.all(
          checkedItems.map(async (policyData) => {
            // Update policy in the database or call your remove function
            await policyServices.deletePolicy(policyData.id)
          })
        )

        // Fetch updated policies
        getPolicy()

        // Display success message
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Selected items have been removed.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error removing policies:', error)

      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // Hide the dropdown or perform any other necessary cleanup
      setDropdownVisible(false)
      setIsChecked(false)
    }
  }

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    setPolicyId(id)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseModal2 = () => {
    setIsModalOpen2(false)
  }

  const handleAssignOwnerClick = () => {
    const selectedPolicyIds = policy
      .filter((item) => item.isChecked)
      .map((item) => item.id) as string[]
    setSelectedPolicyIds(selectedPolicyIds)
    setIsModalOpen2(true)
  }

  const handleApplyFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value
    if (status === 'All') {
      // handleResetFilter()
    } else {
      const filtered = policy.filter((data) => data.status && data.status.includes(status))
      setFilteredPolicies(filtered)
      // setIsFiltered(true)
      // setShow(false)
      // setTotalFilterSelected(1)
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Policy Library</title>
      </Helmet>

      <div className='card-header border-0 pt-6 h-100'>
        {/* <PolicyListSearchComponent policyData={policy}/> */}
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search policy'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          {/* end::Search */}
        </div>
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {isChecked ? (
              <>
                {selectedItems.length > 0 ? (
                  <div className='  border-end px-4 text-center' style={{color: '#A1A5B7'}}>
                    <span>
                      {/* <p className='pt-4'>{selectedItems.length} selected </p> */}
                      <p className='pt-5 fw-semibold fs-6'>{selectedItems.length} Selected</p>
                    </span>
                  </div>
                ) : null}

                <button
                  type='button'
                  onClick={handleDeselectClick}
                  className='btn btn-sm fs-6 m-2 border fw-bold'
                  style={{backgroundColor: '#fff', color: '#5A5A5A', height: '40px'}}
                >
                  Deselect
                </button>
                <button
                  type='button'
                  className='btn btn-sm fs-6 m-2 fw-bold'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onClick={handleAssignOwnerClick}
                >
                  Assign owner
                </button>
              </>
            ) : (
              <>
                <div className='d-flex align-items-center justify-content-end '>
                  <span className='fs-6 fw-semibold mx-4'>Status:</span>
                  <select
                    id='status'
                    className='form-select me-4'
                    aria-label='Select Status'
                    value={selectedStatus}
                    style={{width: '70%', height: '40px'}}
                    onChange={(e) => {
                      setSelectedStatus(e.target.value)
                      // if (e.target.value === 'All') {
                      //   handleResetFilter()
                      // } else {
                      handleApplyFilter(e)
                      // }
                    }}
                  >
                    <option value='' selected>
                      Select status
                    </option>
                    {/* <option value=''>All</option> */}
                    <option value='Approved'>Approved</option>
                    <option value='Needs approval'>Needs approval</option>
                    <option value='Needs review'>Needs review</option>
                    <option value='Incomplete'>Incomplete</option>
                  </select>
                </div>

                <PolicyListToolbar />
              </>
            )}
            {/* end::Add user */}
          </div>
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
      <div className='card-body py-3 '>
        {/* begin::Table container */}
        <div className='table-responsive' style={{minHeight: '55vh', overflowY: 'auto'}}>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold border-bottom border-dotted' style={{color: '#5A5A5A'}}>
                <th className='w-0px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check='true'
                      data-kt-check-target='#kt_file_manager_list .form-check-input'
                      value='0'
                      id='select-all'
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>
                <th className='ps-4 min-w-300px rounded-start'>NAME</th>
                <th className='min-w-100px'>FRAMEWORKS</th>
                <th className='min-w-100px'>STATUS</th>
                <th className='min-w-100px'>LAST APPROVED</th>
                <th className='min-w-100px'>NEXT REVIEW</th>
                <th className='min-w-100px'>OWNER</th>
                <th className='min-w-20px text-end rounded-end'></th>
              </tr>
            </thead>
            <AssignOwner
              isOpen={isModalOpen2}
              onClose={handleCloseModal2}
              ids={selectedPolicyIds}
            />
            <PolicyDrawer isOpen={isModalOpen} onClose={handleCloseModal} id={policyId} />
            {/* end::Table head */}
            {/* begin::Table body */}
            {filteredPolicies && filteredPolicies.length > 0 ? (
              filteredPolicies
                .slice() // Create a copy of the array to avoid modifying the original array
                .sort((a, b) => {
                  const policyA = a.policy || ''
                  const policyB = b.policy || ''
                  return policyA.localeCompare(policyB)
                })

                .map((policyData, index) => {
                  return (
                    <tbody key={policyData.id}>
                      <tr key={policyData.id} className='border-bottom border-dotted'>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value={policyData?.id}
                              // name={policyData.name}
                              checked={policyData.isChecked || false}
                              onChange={() => handleCheckboxChange(policyData?.id)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-2'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-file-text'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                                <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
                                <path d='M9 9l1 0' />
                                <path d='M9 13l6 0' />
                                <path d='M9 17l6 0' />
                              </svg>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary mb-0 mt-1 fs-6'
                                onClick={() => handleOpenModal(policyData.id)}
                              >
                                {policyData.policy}
                              </a>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {policyData.storage}
                              </span>
                            </div>
                          </div>
                        </td>

                        {/* <td>
                          <div className='d-flex align-items-center mt-1'>
                            {Array.isArray(policyData.frameworks)
                              ? policyData.frameworks.map((framework, index) => (
                                  <span
                                    className='badge px-3 rounded-pill fs-7 me-1'
                                    style={{border: '2px solid #E1E1E1'}}
                                  >
                                    {framework}
                                  </span>
                                ))
                              : policyData.frameworks &&
                              Array.isArray(policyData.frameworks) && // Check if policyData.frameworks is an array
                              policyData.frameworks
                                .filter((framework) => activeFrameworks.includes(framework)) // Keep only frameworks that match activeFrameworks
                                .map((framework, index) => (
                                  <span
                                    key={index}
                                    className='badge px-3 rounded-pill fs-7 me-1'
                                    style={{ border: '2px solid #E1E1E1' }}
                                  >
                                    {framework}
                                  </span>
                                ))
                              
                                
                                }
                          </div>
                        </td> */}

                        <td>
                          <div className='d-flex align-items-center mt-1'>
                            {Array.isArray(policyData.frameworks) &&
                              policyData.frameworks
                                .filter((framework) => activeFrameworks.includes(framework))
                                .map((framework, index) => (
                                  <span
                                    key={index}
                                    className='badge px-3 rounded-pill fs-7 me-1'
                                    style={{border: '2px solid #E1E1E1'}}
                                  >
                                    {framework}
                                  </span>
                                ))}
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='clearfix bshadow0 pbs fw-semibold'>
                              {/* {policyData?.status === 'Incomplete' ? (
                                <>
                                  <span
                                    className='badge px-3 rounded-pill'
                                    style={{border: '2px solid #E1E1E1'}}
                                  >
                                    <span className='ml-5 fs-7 mx-1'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#EE3043'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                                        <path d='M9 8l6 8' />
                                        <path d='M15 8l-6 8' />
                                      </svg>
                                    </span>
                                    <span className='fs-7'>Incomplete</span>
                                  </span>
                                </>
                              ) : policyData?.status === 'Needs approval' ? (
                                <>
                                  <span
                                    className='badge px-3 rounded-pill'
                                    style={{border: '2px solid #E1E1E1'}}
                                  >
                                    <span className='ml-5 fs-7 mx-1'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#FFA800'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-file-check mb-1'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                                        <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
                                        <path d='M9 15l2 2l4 -4' />
                                      </svg>
                                    </span>
                                    <span className='fs-7'>Needs Approval</span>
                                  </span>
                                </>
                              ) : policyData?.status === 'Needs review' ? (
                                <>
                                  <span
                                    className='badge px-3 rounded-pill'
                                    style={{border: '2px solid #E1E1E1'}}
                                  >
                                    <span className='ml-5 fs-7 mx-1'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#0075FF'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-calendar mb-1'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z' />
                                        <path d='M16 3v4' />
                                        <path d='M8 3v4' />
                                        <path d='M4 11h16' />
                                        <path d='M11 15h1' />
                                        <path d='M12 15v3' />
                                      </svg>
                                    </span>
                                    <span className='fs-7'>Needs review</span>
                                  </span>
                                </>
                              ) : (
                                policyData?.status === 'Approved' && (
                                  <>
                                    <span
                                      className='badge px-3 rounded-pill'
                                      style={{border: '2px solid #E1E1E1'}}
                                    >
                                      <span className='ml-5 fs-7 mx-1'>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='16'
                                          height='16'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='#1CBB8D'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                          <path d='M9 12l2 2l4 -4' />
                                        </svg>
                                      </span>
                                      <span className='fs-7'>Approved</span>
                                    </span>
                                  </>
                                )
                              )} */}
                              <span
                                className={`badge fs-7 ${
                                  policyData?.status === 'Incomplete'
                                    ? 'badge-danger'
                                    : policyData?.status === 'Needs approval'
                                    ? 'badge-warning'
                                    : policyData?.status === 'Needs review'
                                    ? 'badge-info'
                                    : 'badge-primary'
                                }`}
                                style={{
                                  color:
                                    policyData?.status === 'Incomplete'
                                      ? '#EE3043'
                                      : policyData?.status === 'Needs approval'
                                      ? '#FFA800'
                                      : policyData?.status === 'Needs review'
                                      ? '#007bff'
                                      : '#1CBB8D',
                                  background:
                                    policyData?.status === 'Incomplete'
                                      ? '#FFF5F8'
                                      : policyData?.status === 'Needs approval'
                                      ? '#FFF8DD'
                                      : policyData?.status === 'Needs review'
                                      ? '#E0F0FF'
                                      : '#E8F8F4',
                                }}
                              >
                                {policyData?.status}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='fw-semibold  d-block fs-6'>
                            {policyData?.last_approved
                              ? formatTimestamp2(policyData?.last_approved)
                              : '--'}
                          </span>
                        </td>
                        <td>
                          <span className='fw-semibold d-block fs-6'>
                            {!policyData?.review_date
                              ? '--'
                              : formatTimestamp(policyData?.review_date)}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            {policyData.owner !== '' ? (
                              <>
                                <div className='symbol symbol-35px me-4'>
                                  <span
                                    className={` fs-6 fw-semibold text-success ${
                                      policyData.owner ? 'symbol-label bg-light-primary' : ''
                                    } `}
                                  >
                                    {policyData.owner
                                      ? (() => {
                                          const nameParts = policyData.owner.split(' ')
                                          if (nameParts.length > 1) {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[1]
                                              .charAt(0)
                                              .toUpperCase()}`
                                          } else {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[0]
                                              .charAt(1)
                                              .toUpperCase()}`
                                          }
                                        })()
                                      : ''}
                                  </span>
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                    {policyData.owner}
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </td>

                        <td className='text-end'>
                          <div className='dropdown mx-2'>
                            <button
                              className='btn btn-secondary border-0 p-0 m-2'
                              type='button'
                              id='dropdownMenu2'
                              data-bs-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <img
                                src='/media/buttons/Options.svg'
                                alt='Options'
                                onClick={toggleDropdown}
                              />
                            </button>
                            <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                              <li>
                                <button
                                  className='dropdown-item text-muted text-hover-primary'
                                  type='button'
                                  // onClick={() => handleViewClick(policyData)}
                                  onClick={() => handleOpenModal(policyData.id)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                    <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                  </svg>{' '}
                                  View Details
                                </button>
                              </li>
                              <li>
                                <button
                                  className='dropdown-item text-muted text-hover-primary'
                                  type='button'
                                  onClick={(e) => handleDownloadClick(policyData)}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                                    <path d='M7 11l5 5l5 -5' />
                                    <path d='M12 4l0 12' />
                                  </svg>{' '}
                                  Download
                                </button>
                              </li>
                              {policyData.author === 'Custom policy' && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    type='button'
                                    style={{color: '#F15363'}}
                                    onClick={(e) => handleDeleteClick(policyData)}
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='18'
                                      height='18'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      stroke='#F15363'
                                      strokeWidth='2'
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                    >
                                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                      <path d='M4 7l16 0' />
                                      <path d='M10 11l0 6' />
                                      <path d='M14 11l0 6' />
                                      <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                      <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                    </svg>{' '}
                                    Remove
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <div></div>
                    </tbody>
                  )
                })
            ) : (
              <tbody>
                {searchLength === true ? (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <div className='m-20'>
                        <img src='/media/buttons/emptystate.svg' alt='' />
                        <div className='m-5 fs-5'>
                          <p style={{fontWeight: 'bold'}}>No polices Found</p>
                        </div>
                        <p className=''>
                          We couldn't find any policies that match your search. Maybe try a
                          different search!
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      {!showEmptyState ? (
                        <PolicyListLoading />
                      ) : (
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No polices Added</p>
                          </div>
                          <p>
                            Add the polices that all employees within your organization are required
                            to adhere to.
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            )}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </>
  )
}

export {PolicyLibraryTable}
