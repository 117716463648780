/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import 'react-circular-progressbar/dist/styles.css'

type Props = {
  className: string
}

const Reports: React.FC<Props> = ({className}) => {
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)

  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77'

      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true

      script.onload = () => {
        setIsCrispLoaded(true)
        const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
        if (wasChatOpen) {
          window.$crisp.push(['do', 'chat:show'])
          window.$crisp.push(['do', 'chat:open'])
          setIsChatOpen(true)
        } else {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
        }
      }

      document.head.appendChild(script)
    } else {
      setIsCrispLoaded(true)
      const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
      if (wasChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        setIsChatOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isCrispLoaded) {
      if (isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
      } else {
        window.$crisp.push(['do', 'chat:hide'])
      }
    }
  }, [isChatOpen, isCrispLoaded])

  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
          localStorage.setItem('isChatOpen', 'false')
        },
      ])
    }
  }, [isCrispLoaded])

  const handleCrispChat = () => {
    // setIsDropdownVisible(false);
    console.log('clicked')
    if (isCrispLoaded) {
      if (!isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        window.$crisp.push(['do', 'session:reset'])
        setIsChatOpen(false)
        localStorage.setItem('isChatOpen', 'false')
      }
    } else {
      console.log('Crisp is not loaded yet. Please wait a moment and try again.')
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Reports</title>
      </Helmet>
      <div className='row gx-8 gy-1 '>
        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column mb-0`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <div className='btn p-4' style={{background: '#e8f8f4'}}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#1CBB8D'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-bolt'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11' />
                    </svg>
                  </div>
                </div>
              </div>

              <div className='mt-4 mb-12'>
                <h2 className=''>Trend over time</h2>
                <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  {/* View the evolution of open issues over time with a nice graph. */}
                  Generate a graph depicting the trend of open issues over time to visualize their
                  evolution.
                </p>
                {/* <div className='row align-items-center p-0 my-4'>
               
                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this report
                  </div>
                </div> */}
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100`}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column mb-0`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <div className='btn p-4' style={{background: '#e8f8f4'}}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#1CBB8D'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-activity'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3 12h4l3 8l4 -16l3 8h4' />
                    </svg>
                  </div>
                </div>
              </div>

              <div className='mt-4 mb-12'>
                <h2 className=''>Activity log</h2>
                <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  Use the activity log to find out who or what caused an action inside of
                  SecureSlate.
                </p>
                {/* <div className='row align-items-center p-0 my-4'>
               

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    &nbsp;
                  </div>
                </div> */}
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='/reports/activity-logs'
                  className={`btn w-100`}
                  style={{backgroundColor: '#1CBB8D', color: '#fff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  // onClick={() => handleCrispChat()}
                >
                  View report
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column mb-0`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <div className='btn p-4' style={{background: '#e8f8f4'}}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#1CBB8D'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-scale'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M7 20l10 0' />
                      <path d='M6 6l6 -1l6 1' />
                      <path d='M12 3l0 17' />
                      <path d='M9 12l-3 -6l-3 6a3 3 0 0 0 6 0' />
                      <path d='M21 12l-3 -6l-3 6a3 3 0 0 0 6 0' />
                    </svg>
                  </div>
                </div>
              </div>

              <div className='mt-4 mb-12'>
                <h2 className=''>Licenses & SBOM</h2>
                <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  SecureSlate identifies the potential risks related to open-source licenses.
                  Additionally, export all liscense data to an SBOM or CSV.
                </p>
                {/* <div className='row align-items-center p-0 my-4'>
                

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this report
                  </div>
                </div> */}
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100`}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <div className='btn p-4' style={{background: '#e8f8f4'}}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#1CBB8D'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </div>
                </div>
              </div>

              <div className='mt-4 mb-12'>
                <h2 className=''>Malware monitor</h2>
                <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  SecureSlate checks your 3rd party dependencies for possible software supply chain
                  attacks. Get insights
                </p>
                {/* <div className='row align-items-center p-0 my-4'>
             

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this report
                  </div>
                </div> */}
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100`}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <div className='btn p-4' style={{background: '#e8f8f4'}}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#1CBB8D'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-clock'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                      <path d='M12 7v5l3 3' />
                    </svg>
                  </div>
                </div>
              </div>

              <div className='mt-4 mb-12'>
                <h2 className=''>Runtimes & Frameworks</h2>
                <p className='fs-6 p-0 text-wrap ' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  Get an overview of your important servers, language runtimes & frameworks.
                </p>
                {/* <div className='row align-items-center p-0 my-4'>
               

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this report
                  </div>
                </div> */}
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100 `}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <div className='btn p-4' style={{background: '#e8f8f4'}}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#1CBB8D'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-clock-shield'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M21 12a9 9 0 1 0 -8.98 9' />
                      <path d='M12 7v5l1 1' />
                      <path d='M22 16c0 4 -2.5 6 -3.5 6s-3.5 -2 -3.5 -6c1 0 2.5 -.5 3.5 -1.5c1 1 2.5 1.5 3.5 1.5z' />
                    </svg>
                  </div>
                </div>
              </div>

              <div className='mt-4 mb-12'>
                <h2 className=''>Issues outside of SLA</h2>
                <p className='fs-6 p-0 text-wrap ' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  See which issues are currently outside of their configured SLA settings.
                </p>
                {/* <div className='row align-items-center p-0 my-4'>
                

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this report
                  </div>
                </div> */}
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100 `}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='col-xl-4'>
          <div className={`card ${className}`}>
            <div className='card-body d-flex justify-content-center text-center flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart'>
                  <img src='/media/logos/ISO 27001.svg' alt='' />
                </div>
              </div>

              <div className='pt-5'>
                <h3 className='text-center fs-6 pb-5 '>ISO 27001 Readiness Assessment</h3>

                <a
                  href='#'
                  className={`btn w-100 py-3`}
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className='col-xl-4'>
          <div className={`card ${className}`}>
            <div className='card-body d-flex justify-content-center text-center flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart'>
                  <img src='/media/logos/HIPAA.svg'alt='' />
                </div>
              </div>

              <div className='pt-5'>
                <h3 className='text-center fs-6 pb-5 '>HIPAA Readiness Assessment</h3>

                <a
                  href='#'
                  className={`btn w-100 py-3`}
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-4'>
          <div className={`card ${className}`}>
            <div className='card-body d-flex justify-content-center text-center flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart'>
                  <img src='/media/logos/GDPR.svg' alt='' />
                </div>
              </div>

              <div className='pt-5'>
                <h3 className='text-center fs-6 pb-5 '>GDPR Readiness Assessment</h3>

                <a
                  href='#'
                  className={`btn w-100 py-3`}
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export {Reports}
