/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {registerWithEmailAndPassword} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {signInWithPopup, updateProfile} from 'firebase/auth'
import {updateDoc} from '@firebase/firestore'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {AuthModel, UserModel} from '../core/_models'
import {v4 as uuid} from 'uuid'
import {useLocation} from 'react-router-dom'

import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
  where,
  query,
} from '@firebase/firestore'
import db, {auth1, googleProvider} from '../../../../services/firebase'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  activationCode: '',
}

const InvitationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})
const generateUniqueTenantId = () => {
  return uuid()
}

interface ParseData {
  tenant_id?: string | null
  employee_id?: string | null
  fullName?: string
  email?: string
}

export function Invitation() {
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, googleSignIn} = useAuth()
  const [parseData, setParseData] = useState<ParseData | null>(null)
  const navigate = useNavigate()
  // Type guard for FirebaseAuthError
  function isFirebaseAuthError(error: unknown): error is {code: string} {
    return typeof error === 'object' && error !== null && 'code' in error
  }

  useEffect(() => {
    function decodeBase64ToJson(base64String) {
      const jsonString = atob(base64String)
      return JSON.parse(jsonString)
    }
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')
    if (token) {
      const parsedToken = decodeBase64ToJson(token)
      setParseData(parsedToken)
      console.log(parsedToken)
    }
  }, [location.search])

  useEffect(() => {
    if (parseData) {
      formik.setValues({
        firstname: parseData.fullName?.split(' ')[0] || '',
        lastname: parseData.fullName?.split(' ')[1] || '',
        email: parseData.email || '',
        password: '',
        changepassword: '',
        acceptTerms: false,
        activationCode: '',
      })
    }
  }, [parseData])

  const formik = useFormik({
    initialValues,
    validationSchema: InvitationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const db = getFirestore()
        const authCredential = await registerWithEmailAndPassword(
          values.email,
          values.password,
          values.firstname,
          values.lastname,
          values.changepassword,
          '',
          ''
        )
        // Generate a unique tenant ID
        let tenantId =
          parseData?.tenant_id != null && parseData?.tenant_id !== undefined
            ? parseData?.tenant_id
            : generateUniqueTenantId()
        const activationCode = ''
        // authCredential.user will contain user information
        // Update user profile with first name and last name
        await updateProfile(authCredential.user, {
          displayName: `${values.firstname} ${values.lastname}`,
        })

        // Fetch user details by token (if needed)
        if (!authCredential.user || !authCredential.user.uid) {
          throw new Error('User is not authenticated')
        }

        const authModel: AuthModel = {
          api_token: await authCredential.user.getIdToken(),
          email: authCredential.user.email,
          uid: authCredential.user.uid,
          userName: `${values.firstname} ${values.lastname}`,
          password: values.password,
          first_name: values.firstname,
          last_name: values.lastname,
          tenantId: tenantId,
          activationCode: '',
        }
        const userModel: UserModel = {
          // map properties from authModel to userModel
          uid: authModel.uid || '', // provide a default value if uid is undefined
          userName: authModel.userName || '',
          api_token: authModel.api_token || '',
          password: authModel.password || '',
          email: authModel.email || '',
          first_name: authModel.first_name || '',
          last_name: authModel.last_name || '', // provide a default value if uid is undefined
          tenantId: tenantId || '',
        }

        if (parseData?.employee_id && parseData?.tenant_id) {
          const employeeDocRef = doc(
            db,
            'tenants',
            parseData.tenant_id,
            'employees',
            parseData.employee_id
          )
          await updateDoc(employeeDocRef, {
            uid:
              authCredential.user.uid !== '' ? authCredential.user.uid || '' : authModel.uid || '',
          })
          console.log(
            `Updated employee ${parseData.employee_id} with UID ${authCredential.user.uid}`
          )
        } else {
          console.warn(
            'Employee ID or Tenant ID not found in invitation data. Unable to update employee document.'
          )
        }

        // const db = getFirestore()
        await setDoc(doc(db, 'users', authCredential.user.uid), userModel)

        // saveAuth(authModel)
        // setCurrentUser(userModel)
        // toast.success('Account created successfully', {
        //   position: toast.POSITION.TOP_CENTER,
        //   className: 'bg-white',
        //   style: {
        //     borderRadius: '8px',
        //     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        //     border: '1px solid #e0e0e0',
        //     color: 'black',
        //   },
        //   progressStyle: {
        //     background: '#1CBB8D',
        //   },
        //   icon: ({theme, type}) => (
        //     <svg
        //       width='26'
        //       height='26'
        //       viewBox='0 0 24 24'
        //       fill='none'
        //       xmlns='http://www.w3.org/2000/svg'
        //       style={{color: '#1CBB8D'}}
        //     >
        //       <path
        //         d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
        //         fill='currentColor'
        //       />
        //     </svg>
        //   ),
        //   autoClose: 3000,
        // })
        navigate('/login', { state: { accountCreated: true } });
      } catch (error) {
        console.error(error) // Log error
        let errorMessage = 'The Invitation details are incorrect' // Default error message
        saveAuth(undefined) // Clear auth state
        setStatus(errorMessage) // Set status message
        setSubmitting(false) // Set submitting state to false
        setLoading(false)
      }
    },
  })

  const handleGoogleSignUp = async () => {
    // e.preventDefault();
    console.log('clicking')
    setLoading(false)

    try {
      const result = await signInWithPopup(auth1, googleProvider)
      // const result = await googleSignIn()
      const user = result.user
      // You can customize the data you want to store in Firestore
      const userData = {
        apiToken: user.getIdToken(),
        uid: user.uid,
        email: user.email,
        userName: user.displayName,
        tenantId: user.tenantId,
        // activationCode:user.activationCode
      }

      const usersCollection = collection(db, 'users') // 'users' is the Firestore collection name
      const emailQuery = query(usersCollection, where('email', '==', user.email))
      const emailQuerySnapshot = await getDocs(emailQuery)
      if (!emailQuerySnapshot.empty) {
        // console.log('Email is already in use.')
        setErrorMessage('Email is already in use.')
        // You can handle this case by displaying a message to the user or taking other actions.
        return
      }
      await addDoc(usersCollection, userData)
      setCurrentUser(userData)

      console.log('Successfully signed up with Google:', userData)
    } catch (error) {
      console.error('Google sign-up error:', error)
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    if (formik.status) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }, [formik.status])

  return (
    <>
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-5 mt-2'>Invitation</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>
          {' '}
          Safeguarding Your Data with Robust Security Solutions.
        </div>
      </div>
      {/* end::Heading */}

      {/* begin::Login options */}
      {/* <div className='justify-content-center  row g-3 mb-9'> */}

      {/* <div className='col-md-9'>
    
          <button
            onClick={handleGoogleSignUp}
            type='button'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-dark bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </button>
          {errorMessage && (
            <div className='error-message mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{errorMessage}</div>
            </div>
          )}

        </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div> */}
      {/* </div> */}
      {/* end::Login options */}

      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>First name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://getsecureslate.com/policy#terms'
              target='_blank'
              className='ms-1 link-primary fw-semibold'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-4'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='text-gray-500 text-center fw-semibold fs-6 mt-4'>
        Already have an account?{' '}
        <Link to='/auth/login'>
          {/* <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-2'
          >
            Cancel
          </button> */}
            Login
        </Link>
        </div>
      </div>
      {/* end::Form group */}
    </form>

    </>
  )
}
