import {collection, doc, onSnapshot} from 'firebase/firestore'
import {saveAs} from 'file-saver'
import ExcelJS from 'exceljs'
import {useEffect, useState} from 'react'
import AddManual from '../AddManual'
import AddCSV from '../AddCSV'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'

interface Control {
  control_title?: string
  control_description?: string
  business?: string
  risk?: string
  legal?: string
  contract?: string
  dateLastAssessed?: string
  whyNotApplicable?: string
  risk_scenario?: {riskSource: string}[]
  control_framework_mappings?: string
  author?: string
  control_category?: string
  control_uid: string
  control_evidence_status: string
  control_test?: string
  control_document?: string
  deactivation_control?: string
}

const ControlListToolbar = () => {
  // const {setItemIdForUpdate} = useListView()
  const [controls, setControls] = useState([] as any[])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const {currentUser} = useAuth()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isCSV, setIsCSV] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getControls()
  }, [activeFrameworks])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
        console.log(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
          author: doc.data().author,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (control.author === 'Custom Control') return true

          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })
        setControls(filteredControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleMannually = () => {
    setIsCSV(!isCSV)
  }

  const handleOpenModal2 = () => {
    setIsCSV(true)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  // const exportData = async () => {
  //   const filteredControls: Control[] = controls.filter((control) => {
  //     if (control.author === 'Custom Control') return true

  //     if (!control.control_framework_mappings) return false

  //     return activeFrameworks.some(
  //       (framework) =>
  //         control.control_framework_mappings.startsWith(framework) &&
  //         !control.control_framework_mappings.startsWith('ISO 27001:2013')
  //     )
  //   })

  //   if (filteredControls.length > 0) {
  //     const workbook = new ExcelJS.Workbook()
  //     const worksheet = workbook.addWorksheet('Controls')

  //     // Define header row
  //     worksheet.columns = [
  //       {header: 'Category', key: 'clause', width: 20},
  //       {header: 'Framework', key: 'sec', width: 30},
  //       {header: 'Control', key: 'controlObjective', width: 30},
  //       {header: 'Control Description', key: 'controlDescription', width: 50},
  //       {header: 'Platform', key: 'platform', width: 10},
  //       {header: 'Document', key: 'document', width: 10},
  //       {header: 'Status', key: 'status', width: 10},
  //       {header: 'Justification for exclusion', key: 'justification', width: 40},

  //     ]

  //     worksheet.getRow(1).font = {bold: true, color: {argb: 'FFFFFFFF'}}
  //     worksheet.getRow(1).fill = {
  //       type: 'pattern',
  //       pattern: 'solid',
  //       fgColor: {argb: 'A9A9A9'},
  //     }

  //     const exportData = filteredControls.map((row, index) => ({
  //       clause: row.control_category,
  //       sec: row.control_framework_mappings || '',
  //       controlObjective: row.control_title || '',
  //       controlDescription: stripHtmlTags(row.control_description) || '',
  //       platform: row.control_test || '',
  //       document: row.control_document || '',
  //       status: row.control_evidence_status || '',
  //       justification: row.deactivation_control || '',
  //     }))

  //     exportData.forEach((item) => {
  //       const row = worksheet.addRow(item)

  //       if (item.status === 'Deactivated') {
  //         row.getCell('G').fill = {
  //           type: 'pattern',
  //           pattern: 'solid',
  //           fgColor: {argb: 'FF0000'},
  //         }
  //       }

  //       if (item.status === 'OK') {
  //         row.getCell('G').fill = {
  //           type: 'pattern',
  //           pattern: 'solid',
  //           fgColor: {argb: '1CBB8D'},
  //         }
  //       }
  //     })

  //     const buffer = await workbook.xlsx.writeBuffer()
  //     const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})
  //     saveAs(excelBlob, 'controls.xlsx')
  //   }
  // }

  const exportData = async () => {
    for (const framework of activeFrameworks) {
      // Filter controls for the current framework
      const filteredControls: Control[] = controls.filter((control) => {
        if (control.author === 'Custom Control') return true

        if (!control.control_framework_mappings) return false

        return (
          control.control_framework_mappings.startsWith(framework) &&
          !control.control_framework_mappings.startsWith('ISO 27001:2013')
        )
      })

      if (filteredControls.length > 0) {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Controls')

        // Define header row
        worksheet.columns = [
          {header: 'Category', key: 'clause', width: 40},
          {header: 'Framework', key: 'sec', width: 40},
          {header: 'Author', key: 'author', width: 20},
          {header: 'Control', key: 'controlObjective', width: 30},
          {header: 'Control Description', key: 'controlDescription', width: 50},
          {header: 'Platform', key: 'platform', width: 15},
          {header: 'Document', key: 'document', width: 15},
          {header: 'Status', key: 'status', width: 15},
          {header: 'Justification for exclusion', key: 'justification', width: 40},
        ]

        worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
        worksheet.getRow(1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'A9A9A9'},
        }
        worksheet.getRow(1).height = 40

        const exportData = filteredControls.map((row) => ({
          clause: row.control_category,
          sec: row.control_framework_mappings || '',
          author: row.author || 'SecureSlate',
          controlObjective: row.control_title || '',
          controlDescription: stripHtmlTags(row.control_description) || '',
          platform: row.control_test || '',
          document: row.control_document || '',
          status: row.control_evidence_status || '',
          justification: row.deactivation_control || '',
        }))

        exportData.forEach((item) => {
          const row = worksheet.addRow(item)
          row.height = 30

          if (item.status === 'Deactivated') {
            row.eachCell((cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'f8aab2'},
              }
            })
          }

          if (item.status === 'OK') {
            row.getCell('H').fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'a2e3d0'},
            }
          }
        })

        const buffer = await workbook.xlsx.writeBuffer()
        const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})

        // Save the file with a name that includes the framework
        saveAs(excelBlob, `controls_${framework}.xlsx`)
      }
    }
  }

  const toggleDropdown = () => {
    console.log('clicked')
    setDropdownVisible(!isDropdownVisible)
  }

  console.log(isDropdownOpen)
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button> */}
      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3' onClick={exportData} >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button> */}
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}

      {/* <a href='/controls/addControls'>
        {' '}
        <button
          type='button'
          className='btn btn-primary'
          style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
        >
          <KTIcon iconName='plus' className='fs-2' />
          Add Control


        </button>
      </a> */}

      {/* <div>
        <div ref={ref}>
          <button
            type='button'
            className='btn me-3 fs-5'
            style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}
            onClick={handleClick}
          >
  
        
            <i className='ki-solid ki-filter text-primary fs-4 p-0 '>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            Filter <span className="badge border border-primary rounded-circle bg-primary text-white mx-1">3 </span>
          </button>
         
          
          
          <Overlay
            show={show}
            target={target}
            placement='bottom'
            container={ref}
            containerPadding={10}
          >
            <Popover id='popover-contained'>
              <Popover.Header as='h3' className='fw-bold fs-5'>
                Filter
              </Popover.Header>
              <Popover.Body className=''>
                <form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <label htmlFor='framework'>Framework</label>
                    <select id='framework' className='form-select form-control' aria-label='Select Framework'>
                      <option value='' disabled selected>
                        Select Framework
                      </option>
                      <option value='ISO 27001'>ISO 27001</option>
                      <option value='SOC 2'>SOC 2</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='status'>Status</label>
                    <select id='status' className='form-select' aria-label='Select Status'>
                      <option value='' disabled selected>
                        Select Status
                      </option>
                      <option value='Unhealthy'>Unhealthy</option>
                      <option value='OK'>OK</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='owner'>Owner</label>
                    <select id='owner' className='form-select' aria-label='Select Owner'>
                      <option value='' disabled selected>
                        Select Owner
                      </option>
                      <option value='react'>React</option>
                      <option value='angular'>Angular</option>
                      <option value='vue'>Vue.js</option>
                    </select>
                  </div>
                  <button
                    type='button'
                    className='btn mx-5 btn-sm '
                    style={{backgroundColor: '#f1fdf9', color: '#45dbaf'}}
                  >
                    Reset
                  </button>
                  <button type='submit' className='btn btn-primary btn-sm ml-5'>
                    Apply
                  </button>
                </form>
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>
      </div> */}

      <AddManual isOpen={isModalOpen} onClose={handleCloseModal} />
      <AddCSV isOpen={isCSV} onClose={handleMannually} />

      <div className='dropdown'>
        <button
          className='btn btn-sm fs-6 rotate'
          style={{background: '#1CBB8D', height: '40px', color: '#fff', width: '160px'}}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
          onClick={toggleDropdown}
          id='dropdownMenu2'
          data-bs-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          {/* <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-plus'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M12 5l0 14' />
            <path d='M5 12l14 0' />
          </svg> */}
          <span className='mx-2'>Add Controls</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down rotate-180'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M6 9l6 6l6 -6' />
          </svg>
        </button>
        <ul className='dropdown-menu mt-2' aria-labelledby='dropdownMenu2' style={{width: '160px'}}>
          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-pencil mb-1 mx-1 '
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4' />
                <path d='M13.5 6.5l4 4' />
              </svg>{' '}
              Add manually
            </button>
          </li>

          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal2}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-file-import mb-1 mx-1'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                <path d='M5 13v-8a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5.5m-9.5 -2h7m-3 -3l3 3l-3 3' />
              </svg>{' '}
              Import csv
            </button>
          </li>
        </ul>
      </div>

      {/* end::Add user */}
    </div>
  )
}

export {ControlListToolbar}
