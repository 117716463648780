import React, {useEffect, useRef, useState} from 'react'
import db, {storage} from '../../../../services/firebase'
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import {deleteObject, ref as storageRef} from 'firebase/storage'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import Dropzone from 'react-dropzone'
import {format, parseISO} from 'date-fns'
import {ToastContainer, toast} from 'react-toastify'

export interface policy {
  msg: string
  message: string
  fileUrl?: any
}

type FileDataType = {
  name: string
  url: string
  date: string
  lastModifiedDate?: Date
  size: number
}

interface ViewProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const PolicyDrawer: React.FC<ViewProps> = ({isOpen, onClose, id}) => {
  const {currentUser} = useAuth()
  const [owner, setOwner] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const drawerRef = useRef<HTMLDivElement>(null)
  const [owners, setOwners] = useState<any[]>([])
  const [policyTemplate, setPolicyTemplate] = useState<any[]>([])
  const [reviewDate, setReviewDate] = useState('')
  const [customfileUrl, setCustomfileUrl] = useState<any[]>([])
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [status, setStatus] = useState('')
  const statusRef = useRef(status)
  const [errorMessages, setErrorMessages] = useState<{[key: string]: string}>({})
  const [localFileInfo, setLocalFileInfo] = useState<FileDataType[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [policyName, setPolicyName] = useState('')
  const [date, setDate] = useState('')

  useEffect(() => {
    statusRef.current = status
  }, [status])

  useEffect(() => {
    const checkReviewDate = () => {
      const today = new Date()
      const review = new Date(reviewDate)

      if (review <= today) {
        setErrorMessages((prev) => ({...prev, [id]: 'Please select a new review date.'}))
      } else {
        setErrorMessages((prev) => {
          const newErrors = {...prev}
          delete newErrors[id]
          return newErrors
        })
      }
    }

    if (reviewDate) {
      checkReviewDate()
    } else {
      setErrorMessages((prev) => {
        const newErrors = {...prev}
        delete newErrors[id]
        return newErrors
      })
    }
  }, [reviewDate, id])

  useEffect(() => {
    const unsubscribe = getPolicy()
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [id])

  const getPolicy = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId || !id) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'policies')

      const unsubscribe = onSnapshot(doc(PolicyRef, id), (snapshot) => {
        const policyData = snapshot.data()
        if (policyData) {
          setPolicyTemplate([{...policyData, id: snapshot.id}])
          setOwner(policyData.owner || '')
          setReviewDate(policyData.review_date || '')
          setCustomfileUrl(policyData.customfile || '')
          setLocalFileInfo(policyData.customfile || '')
          determineAndUpdateStatus(policyData)
          setPolicyName(policyData.policy || '')
        }
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting policy:', error)
    }
  }

  const determineAndUpdateStatus = (policyData) => {
    const {owner, review_date, customfile, status} = policyData
    const today = new Date()
    const review = new Date(review_date)

    let newStatus = status

    if (review <= today) {
      newStatus = 'Needs review'
    } else if (owner && review_date && customfile && customfile.length > 0) {
      if (status !== 'Approved') {
        newStatus = 'Needs approval'
      }
    } else {
      newStatus = 'Incomplete'
    }

    if (newStatus !== status) {
      setStatus(newStatus)
      updatePolicy({status: newStatus})
    }
  }

  useEffect(() => {
    const oneYearFromNow = new Date()
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
    setDate(oneYearFromNow.toISOString().slice(0, 10))
  }, [])

  const updatePolicy = async (updateData) => {
    if (!currentUser?.tenantId || !id) return

    const tenantRef = doc(db, 'tenants', currentUser.tenantId)
    const policyRef = doc(collection(tenantRef, 'policies'), id)

    try {
      await runTransaction(db, async (transaction) => {
        const policyDoc = await transaction.get(policyRef)
        if (!policyDoc.exists()) {
          throw 'Document does not exist!'
        }

        transaction.update(policyRef, {
          ...updateData,
          updated_at: serverTimestamp(),
        })
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // const updateReviewDateIfComplete = (newFileInfo?: FileDataType[], newOwner?: string) => {
  //   const fileExists = newFileInfo ? newFileInfo.length > 0 : localFileInfo.length > 0;
  //   const ownerExists = newOwner || owner;

  //   if (fileExists && ownerExists) {
  //     const oneYearFromNow = new Date();
  //     oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  //     const newReviewDate = oneYearFromNow.toISOString().slice(0, 10);
  //     setReviewDate(newReviewDate);
  //     updatePolicy({ reviewDate: newReviewDate });
  //   }
  // };

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const handleUpload = async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return

    setIsUploading(true)
    setUploadProgress(1)

    const localFile = acceptedFiles[0]
    const newFileInfo: FileDataType = {
      name: localFile.name,
      size: localFile.size,
      date: new Date().toISOString(),
      url: URL.createObjectURL(localFile),
    }
    // setLocalFileInfo([newFileInfo])
    setLocalFileInfo((prevFiles) => [...prevFiles, newFileInfo])

    // Start upload process in the background
    const fileName = localFile.name
    const fileRef = ref(
      storage,
      `tenants/${currentUser?.tenantId}/policy/policies_documents/${fileName}`
    )
    const uploadTask = uploadBytesResumable(fileRef, localFile)

    const progressInterval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(progressInterval)
          return prevProgress
        }
        return prevProgress + 10
      })
    }, 200)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        if (progress === 100) {
          clearInterval(progressInterval)
          setUploadProgress(100)
        }
      },
      (error) => {
        console.error('Error uploading file:', error)
        setIsUploading(false)
        clearInterval(progressInterval)
        setUploadProgress(0)
        toast.error('Error uploading file. Please try again.')
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)

          updatePolicy({
            customfile: [
              ...customfileUrl,
              {
                name: fileName,
                url: downloadURL,
                date: new Date().toISOString(),
                size: localFile.size,
              },
            ],
            review_date: date,
          })
          // updateReviewDateIfComplete([newFileInfo]);
        } catch (error) {
          console.error('Error updating policy:', error)
          // toast.error('Error updating policy. Please try again.');
        } finally {
          setIsLoading(false)
          setUploadProgress(0)
        }
      }
    )
  }

  const handleApprove = async () => {
    try {
      setIsLoading(true)
      await updatePolicy({status: 'Approved', last_approved: new Date().toISOString()})
      setStatus('Approved')
      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${policyName} has been approved by ${currentUser?.userName}`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        // email: email,
        uid: currentUser?.uid,
        username: currentUser?.userName,
        action: 'Policy updated',
        target: 'Policies',
        email: currentUser?.email,
        date: todaysDate,
      })

      toast.success('Policy approved successfully!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    } catch (error) {
      console.error('Error approving policy:', error)
      toast.error('Error approving policy!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        // progressStyle: {
        //   background: '#1CBB8D',
        // },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#ee3043'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
    setIsLoading(false)
  }

  const handleRemoveFile = async (index: number, file, id) => {
    const fileToRemove = localFileInfo[index]
    const updatedFiles = localFileInfo.filter((_, i) => i !== index)
    setLocalFileInfo(updatedFiles)

    console.log(file.id, id)

    try {
      const tenantId = currentUser?.tenantId
      if (!tenantId || !id) {
        console.error('Missing tenantId or policy id')
        return
      }

      const policyRef = doc(db, 'tenants', tenantId, 'policies', id)
      await updateDoc(policyRef, {
        customfile: updatedFiles,
      })

      const fileRef = storageRef(
        storage,
        `tenants/${currentUser?.tenantId}/policy/policies_documents/${fileToRemove.name}`
      )
      await deleteObject(fileRef)

      console.log('File removed successfully from storage and database')
    } catch (error) {
      console.error('Error removing file:', error)
      setLocalFileInfo((prevFiles) => [...prevFiles, fileToRemove])
    }
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleDownloadClick2 = async (customfileUrl) => {
    try {
      const fileUrl = customfileUrl.url

      const response = await fetch(fileUrl)
      const fileContent = await response.blob()

      const blob = new Blob([fileContent], {type: 'application/octet-stream'})

      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = `${customfileUrl.name}.pdf`

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(blobUrl)

      setDropdownVisible(false)
    } catch (error) {
      console.error('Error downloading policy file:', error)
    }
  }

  const handleViewClick = (customfileUrl) => {
    const fileUrl = customfileUrl.url
    const fileName = customfileUrl.name
    const fileExtension = fileName.split('.').pop().toLowerCase()

    const link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'

    if (fileExtension === 'pdf') {
      link.click()
    } else if (fileExtension === 'docx') {
      // For DOCX files, try to open in Google Docs viewer
      const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
        fileUrl
      )}&embedded=true`
      window.open(googleDocsUrl, '_blank')
    } else {
      link.download = fileName
      link.click()
    }

    setDropdownVisible(false)
  }

  const handleOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newOwner = e.target.value
    setOwner(newOwner)
    updatePolicy({owner: newOwner})
    // updateReviewDateIfComplete(undefined, newOwner);
    const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
    const activitiesRef = collection(tenantRef, 'activities')
    addDoc(activitiesRef, {
      message: `Assigned the ${policyTemplate[0].policy} policy owner to ${newOwner} by ${currentUser?.userName}`,
      timestamp: serverTimestamp(),
      action: 'Policy updated',
      target: 'Policies',
      tenantId: currentUser?.tenantId,
      uid: currentUser?.uid,
      username: currentUser?.userName,
      email: currentUser?.email,
      date: todaysDate,
    })
  }

  const handleReviewDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newReviewDate = e.target.value
    setReviewDate(newReviewDate)
    updatePolicy({review_date: newReviewDate})
  }

  const formatFileDate = (dateString: string): string => {
    if (dateString) {
      try {
        // Parse the ISO date string
        const date = parseISO(dateString)

        // Format the date
        return format(date, 'd MMMM, yyyy')
      } catch (error) {
        console.error('Error parsing date:', error)
        return 'Invalid Date'
      }
    } else {
      return 'Invalid Date'
    }
  }

  function bytesToKB(bytes) {
    return (bytes / 1024).toFixed(2)
  }

  const handleDownloadClick = async (policyData) => {
    try {
      // Assuming policyData.fileUrl contains the URL to the policy file
      const fileUrl = policyData.fileUrl

      // Fetch the file content
      const response = await fetch(fileUrl)
      const fileContent = await response.blob()

      // Create a Blob with the file content
      const blob = new Blob([fileContent], {type: 'application/octet-stream'})

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob)

      // Create a hidden link
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = `${policyData.fileName ? policyData.fileName : policyData.policy}.docx`

      // Append the link to the document
      document.body.appendChild(link)

      // Programmatically trigger a click on the link
      link.click()

      // Remove the link and revoke the Blob URL from the document
      document.body.removeChild(link)
      URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.error('Error downloading policy file:', error)
    }
  }

  return (
    <>
      <ToastContainer />{' '}
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        {policyTemplate.map((temp) => (
          <>
            <div
              className='border-bottom border-gray-300'
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                padding: '14px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                paddingLeft: '15px',
                paddingRight: '12px',
              }}
            >
              <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
                {temp.policy}
              </div>

              <div style={{display: 'flex', gap: '16px'}}>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary mx-4'
                  aria-label='Close'
                  onClick={onClose}
                  style={{
                    transition: 'all 0.2s ease',
                  }}
                  onMouseEnter={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#1CBB8D'
                      }
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#5A5A5A'
                      }
                    }
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M18 6l-12 12' />
                    <path d='M6 6l12 12' />
                  </svg>
                </div>
              </div>
            </div>

            <div
              className=' scroll-y overflow-auto p-7'
              tabIndex={-1}
              style={{height: 'calc(100vh - 20px)'}}
            >
              <div className='card mb-8 p-4' style={{background: '#F6F6F6'}}>
                {temp.status === 'Incomplete' ? (
                  <>
                    <span
                      className='badge rounded-pill p-1 mt-2'
                      style={{border: '1px solid #C5C5C5', width: '110px'}}
                    >
                      <span className='mx-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#EE3043'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x mb-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                          <path d='M9 8l6 8' />
                          <path d='M15 8l-6 8' />
                        </svg>
                      </span>
                      <span className='fs-7'>Incomplete</span>
                    </span>
                    <div className='fs-6 fw-semibold my-4'>
                      Please upload your organization's {temp.policy} or you can also use the
                      template below.
                    </div>
                  </>
                ) : temp.status === 'Needs approval' ? (
                  <>
                    <span
                      className='badge rounded-pill p-1 mt-2'
                      style={{border: '1px solid #C5C5C5', width: '140px'}}
                    >
                      <span className='mx-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#FFA800'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-file-check mb-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                          <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
                          <path d='M9 15l2 2l4 -4' />
                        </svg>
                      </span>
                      <span className='fs-7'>Needs Approval</span>
                    </span>
                    <div className='fs-6 fw-semibold my-4'>
                      Owner approval is pending for this policy. <strong>Note:</strong> Please
                      assign owner if not assigned yet.
                    </div>
                    {currentUser?.userName?.toLowerCase() === owner.toLowerCase() &&
                      (isLoading ? (
                        <button
                          type='button'
                          // onClick={handleApprove}
                          className='btn btn-sm fs-6'
                          style={{
                            height: '40px',
                            width: '150px',
                            background: '#1CBB8D',
                            color: '#fff',
                          }}
                          disabled
                        >
                          Approving...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </button>
                      ) : (
                        <button
                          type='button'
                          onClick={handleApprove}
                          className='btn btn-sm fs-6'
                          style={{
                            height: '40px',
                            width: '100px',
                            background: '#1CBB8D',
                            color: '#fff',
                          }}
                          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                            (e.currentTarget.style.backgroundColor = '#148363')
                          }
                          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                            (e.currentTarget.style.backgroundColor = '#1CBB8D')
                          }
                        >
                          Approve
                        </button>
                      ))}
                  </>
                ) : temp.status === 'Needs review' ? (
                  <>
                    <span
                      className='badge rounded-pill p-1 mt-2'
                      style={{border: '1px solid #C5C5C5', width: '130px'}}
                    >
                      <span className='mx-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20.'
                          height='20'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#0075FF'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-calendar mb-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z' />
                          <path d='M16 3v4' />
                          <path d='M8 3v4' />
                          <path d='M4 11h16' />
                          <path d='M11 15h1' />
                          <path d='M12 15v3' />
                        </svg>
                      </span>
                      <span className='fs-7'>Needs review</span>
                    </span>
                    <div className='fs-6 fw-semibold my-4'>
                      Policy review overdue. Please upload updated policy, set next review date, and
                      re-approve to maintain compliance.
                    </div>
                  </>
                ) : (
                  temp.status === 'Approved' && (
                    <>
                      <span
                        className='badge rounded-pill p-1 mt-2'
                        style={{border: '1px solid #C5C5C5', width: '110px'}}
                      >
                        <span className='mx-2'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#1CBB8D'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check mb-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                            <path d='M9 12l2 2l4 -4' />
                          </svg>
                        </span>
                        <span className='fs-7'>Approved</span>
                      </span>
                      <div className='fs-6 fw-semibold my-4'>
                        This policy has been approved by the owner. Employees can now read and
                        accept this policy.
                      </div>
                    </>
                  )
                )}
              </div>

              <div className='mt-4'>
                <div className='fw-bold fs-6 '>Template</div>

                {temp.fileUrl.length === 0 && temp.fileSize === '' ? (
                  <div className='card mb-4 mb-xl-8 mt-2 p-4' style={{background: '#F6F6F6'}}>
                    <div className='mt-1'>
                      <span className='fw-bold fs-6'>Template unavailabe</span>
                    </div>

                    <div className='fs-6 mt-2 mb-2' style={{color: '#5A5A5A'}}>
                      Template is not provided for this policy <strong>[ {temp.policy} ] </strong>.
                    </div>
                  </div>
                ) : (
                  <div className={`card mb-4 mb-xl-8 mt-2`}>
                    <div
                      className='card-header border-0 pt-6 mb-5'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{display: 'flex', alignItems: 'center', flex: 1, minWidth: 0}}>
                        <img
                          src='/media/icon/file2.svg'
                          alt=''
                          className='img-fluid'
                          style={{width: '50px', height: '50px', marginRight: '10px'}}
                        />
                        <div
                          className='d-flex justify-content-start flex-column fw-bold fs-6'
                          style={{flexGrow: 1, minWidth: 0}}
                        >
                          <span style={{wordBreak: 'break-word', whiteSpace: 'normal'}}>
                            {temp.fileName ? temp.fileName : temp.policy}
                          </span>
                          <span className='fw-semibold d-block fs-6' style={{color: '#5A5A5A'}}>
                            Size: {temp.fileSize} KB
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <a
                          href='#'
                          className='text-dark fw-semibold text-hover-primary btn-active-light-primary btn btn-sm'
                          onClick={(e) => handleDownloadClick(temp)}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-cloud-download mx-2 mb-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4' />
                            <path d='M12 13l0 9' />
                            <path d='M9 19l3 3l3 -3' />
                          </svg>
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className='separator my-8'></div>

              <div className='form'>
                <div className='form-group mb-4'>
                  <div className='main-container'>
                    <div className='fw-bold fs-6 my-2'>Uploads</div>
                    {localFileInfo && localFileInfo.length > 0 && (
                      <>
                        {localFileInfo.map((file, index) => (
                          <React.Fragment key={index}>
                            <div className='fs-6 fw-semibold' style={{color: '#5A5A5A'}}>
                              {formatFileDate(file.date)}
                            </div>
                            <div className={`card mb-4 mb-xl-8 mt-4`}>
                              <div
                                className='card-header border-0 pt-6 mb-5'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  <img
                                    src='/media/icon/file2.svg'
                                    alt=''
                                    className='img-fluid'
                                    style={{width: '50px', height: '50px', marginRight: '10px'}}
                                  />
                                  <div className='d-flex justify-content-start flex-column fw-bold fs-6'>
                                    {file.name}
                                    <span
                                      className=' fw-semibold d-block fs-6'
                                      style={{color: '#5A5A5A'}}
                                    >
                                      Size: {bytesToKB(file.size)} KB
                                    </span>
                                  </div>
                                </div>

                                <div className='dropdown '>
                                  {uploadProgress > 0 && index === localFileInfo.length - 1 ? (
                                    <div>
                                      <span>{uploadProgress}%</span>
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </div>
                                  ) : (
                                    <>
                                      <button
                                        className='btn  border-0 p-0'
                                        type='button'
                                        id={`dropdownMenu${index}`}
                                        data-bs-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <img
                                          src='/media/buttons/Options.svg'
                                          alt='Options'
                                          onClick={toggleDropdown}
                                        />
                                      </button>
                                      <ul
                                        className='dropdown-menu'
                                        aria-labelledby={`dropdownMenu${index}`}
                                      >
                                        <li>
                                          <button
                                            className='dropdown-item text-muted text-hover-primary'
                                            type='button'
                                            onClick={() => handleViewClick(file)}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                              <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                            </svg>{' '}
                                            View
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className='dropdown-item text-muted text-hover-primary'
                                            type='button'
                                            onClick={(e) => handleDownloadClick2(file)}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                                              <path d='M7 11l5 5l5 -5' />
                                              <path d='M12 4l0 12' />
                                            </svg>{' '}
                                            Download
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className='dropdown-item'
                                            type='button'
                                            style={{color: '#F15363'}}
                                            onClick={() => handleRemoveFile(index, file, temp.id)}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='#F15363'
                                              strokeWidth='2'
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M4 7l16 0' />
                                              <path d='M10 11l0 6' />
                                              <path d='M14 11l0 6' />
                                              <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                              <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                            </svg>{' '}
                                            Remove
                                          </button>
                                        </li>
                                      </ul>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                    {/* ) : (
                      <Dropzone
                        onDrop={handleUpload}
                        minSize={64}
                        accept={{
                          'application/pdf': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            [],
                        }}
                        maxSize={5 * 1024 * 1024}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div
                            {...getRootProps({
                              className: 'dropzone',
                              style: {
                                backgroundColor: '#fff', // Change this to your desired color
                                padding: '20px',
                                border: '2px dashed #ccc',
                                borderRadius: '4px',
                                textAlign: 'center',
                                cursor: 'pointer',
                              },
                            })}
                          >
                            <input {...getInputProps()} />
                            <img
                              src='/media/icon/uploadFile.svg'
                              alt=''
                              loading='lazy'
                              className='mb-4 mt-4'
                            />
                            <p className='fw-bold fs-6'>
                              Drag and drop or <a href='#'>select</a> a files
                            </p>
                            <p className='fs-6'>Upload upto 5 MB. Supported formats: docx, pdf</p>
                          </div>
                        )}
                      </Dropzone>
                    )} */}

                    {(errorMessages[id] || localFileInfo.length === 0) && (
                      <Dropzone
                        onDrop={handleUpload}
                        minSize={64}
                        accept={{
                          'application/pdf': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            [],
                        }}
                        maxSize={5 * 1024 * 1024}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div
                            {...getRootProps({
                              className: 'dropzone',
                              style: {
                                backgroundColor: '#fff',
                                padding: '20px',
                                border: '2px dashed #ccc',
                                borderRadius: '4px',
                                textAlign: 'center',
                                cursor: 'pointer',
                              },
                            })}
                          >
                            <input {...getInputProps()} />
                            <img
                              src='/media/icon/uploadFile.svg'
                              alt=''
                              loading='lazy'
                              className='mb-4 mt-4'
                            />
                            <p className='fw-bold fs-6'>
                              Drag and drop or <a href='#'>select</a> a files
                            </p>
                            <p className='fs-6'>Upload upto 5 MB. Supported formats: docx, pdf</p>
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                </div>
                <div className='separator my-8'></div>

                <div className='form-group mb-4'>
                  <label className='text-dark fw-bold my-2 fs-6'>Owner</label>
                  <div className='fs-6 fw-semibold mb-2' style={{color: '#5A5A5A'}}>
                    Policy owner will be responsible for reviewing and approving documents.
                  </div>

                  <select
                    name='group'
                    className='form-select'
                    data-control='select2'
                    data-placeholder='Select Owner'
                    onChange={handleOwnerChange}
                    value={owner}
                  >
                    <option value='' selected>
                      Select Owner
                    </option>
                    {owners
                      .filter((user) => user.role === 'Admin')
                      .map((owner) => (
                        <option key={owner.id} value={owner.full_name}>
                          {owner.full_name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className='separator my-8'></div>

                <div className='form-group mb-15'>
                  <label className='fw-semibold mb-2 fs-6'>Next Review</label>

                  <div className='fs-6 fw-semibold mb-2' style={{color: '#5A5A5A'}}>
                    Please select a review date. Ideally, you are required to review your policies
                    anually.
                  </div>
                  <input
                    type='date'
                    className={`form-control ${errorMessages[id] ? 'border-danger' : ''}`}
                    value={reviewDate}
                    placeholder='Select date'
                    onChange={handleReviewDateChange}
                  />
                  <div className='fs-7' style={{color: '#828282'}}>
                    Suggested review date: {date} (Auto updates after file is uploaded)
                  </div>
                  {errorMessages[id] && (
                    <div className='text-danger mt-1 fs-6'>{errorMessages[id]}</div>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </Drawer>
    </>
  )
}

export default PolicyDrawer
